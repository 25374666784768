// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateListingSharedPageHeader__pageHeader___mR8T0{background-color:var(--primary500);padding:1.75rem 0}.CreateListingSharedPageHeader__pageHeader___mR8T0>div{align-items:center;display:flex;justify-content:space-between}.CreateListingSharedPageHeader__pageHeader___mR8T0 .CreateListingSharedPageHeader__category___tDcrK{color:var(--white70);letter-spacing:.0625em;text-transform:uppercase}.CreateListingSharedPageHeader__pageHeader___mR8T0 .CreateListingSharedPageHeader__actions___sshde{align-items:center;display:flex;gap:.5rem;justify-content:end;width:12rem}.CreateListingSharedPageHeader__pageHeader___mR8T0 .CreateListingSharedPageHeader__actions___sshde>div>button{height:36px;padding-inline:0}.CreateListingSharedPageHeader__pageMargin___MsYrJ{margin:0 auto;max-width:var(--pageContentMaxWidth);padding:0 1.5rem;width:100%}@media only screen and (min-width: 834px){.CreateListingSharedPageHeader__pageMargin___MsYrJ{padding:0 2.5rem}}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/CreateListingSharedPageHeader.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,mDACE,kCAAA,CACA,iBAAA,CAEA,uDACE,kBAAA,CACA,YAAA,CACA,6BAAA,CAGF,oGACE,oBAAA,CACA,sBAAA,CACA,wBAAA,CAGF,mGACE,kBAAA,CACA,YAAA,CACA,SAAA,CACA,mBAAA,CACA,WAAA,CAEA,8GACE,WAAA,CACA,gBAAA,CAKN,mDACE,aAAA,CACA,oCAAA,CACA,gBAAA,CACA,UAAA,CCJA,0CAAA,mDDOE,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "CreateListingSharedPageHeader__pageHeader___mR8T0",
	"category": "CreateListingSharedPageHeader__category___tDcrK",
	"actions": "CreateListingSharedPageHeader__actions___sshde",
	"pageMargin": "CreateListingSharedPageHeader__pageMargin___MsYrJ"
};
export default ___CSS_LOADER_EXPORT___;
