import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../../api/apiClient';

export interface FinancialSummaryForRestaurantAdmin {
  restaurantNetRevenue: number;
}

export const getFinancialSummary = async (
  restaurantId: string,
  month: string,
): Promise<ApiResponse<FinancialSummaryForRestaurantAdmin>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/financial-summary/restaurant-admin?month=${month}`,
  );

  return response.json();
};
