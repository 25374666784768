import cx from 'classnames';
import type { FormEvent } from 'react';
import { useState } from 'react';
import { Card } from '@components/card/Card';
import typography from '~styles/typography.scss';
import { requestPasswordReset } from './apiHelpers';
import styles from './styles.scss';

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isRequestSent, setIsRequestSent] = useState(false);

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await requestPasswordReset(email);
    setIsRequestSent(true);
  };

  return (
    <main className={styles.pageContainer}>
      <Card className={styles.card}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <h1 className={cx(typography.h4, styles.formHeader)}>
            Forgot Password
          </h1>
          <label
            className={cx(typography.label, styles.formLabel)}
            htmlFor="email"
          >
            Email
            <input
              className={cx(styles.input, typography.body)}
              id="email"
              name="email"
              onChange={(event) => setEmail(event.target.value)}
              type="email"
            />
          </label>
          <button
            className={cx(styles.submitButton, typography.body)}
            disabled={!email || isRequestSent}
            type="submit"
          >
            Submit
          </button>
          {isRequestSent && (
            <span className={cx(typography.body, styles.requestSentMessage)}>
              If the email you provided is valid, you should receive an email
              soon.
            </span>
          )}
        </form>
      </Card>
    </main>
  );
};
