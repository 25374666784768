import type { ApiResponse, ErrorResponse } from '@shared/types/apiHelpers';
import API from '../../api/apiClient';

export interface RestaurantEvent {
  description: string;
  endDate: string | null;
  id: string;
  name: string;
  repeat: string[];
  startDate: string;
}
export interface NewRestaurantEvent {
  description: string;
  endDate: string | null;
  name: string;
  repeat: string[];
  startDate: string;
}

export const getRestaurantEvents = async (
  restaurantId: string,
): Promise<ApiResponse<RestaurantEvent[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/restaurant-events`,
  );
  return response.json();
};

export const createRestaurantEvent = async (
  restaurantId: string,
  newRestaurantEvent: NewRestaurantEvent,
): Promise<Response | ErrorResponse> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/restaurant-events`,
    newRestaurantEvent,
  );
  if (response.ok) {
    return response;
  }

  return response.json();
};

export const deleteRestaurantEvent = async ({
  restaurantId,
  restaurantEventId,
}: {
  restaurantId: string | undefined;
  restaurantEventId: string | undefined;
}): Promise<ApiResponse<object>> => {
  const response = await API.delete(
    `/restaurants/${restaurantId}/restaurant-events/${restaurantEventId}`,
  );

  return response;
};

export const editRestaurantEvent = async (
  restaurantId: string,
  event: RestaurantEvent,
): Promise<Response | ErrorResponse> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/restaurant-events`,
    event,
  );
  if (response.ok) {
    return response;
  }

  return response.json();
};
