// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheet__container___hrJyn{display:flex;flex-direction:column;gap:16px}.ReservationGuestSheet__container___hrJyn section{background-color:var(--panelBlue);border-radius:8px;padding:1rem}.ReservationGuestSheet__floorplan___wB10Y{height:320px;width:100%;background-color:var(--panelBlue);border-radius:8px;padding:1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/ReservationGuestSheet.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,kDACE,iCAAA,CACA,iBAAA,CACA,YAAA,CAIJ,0CACE,YAAA,CACA,UAAA,CACA,iCAAA,CACA,iBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationGuestSheet__container___hrJyn",
	"floorplan": "ReservationGuestSheet__floorplan___wB10Y"
};
export default ___CSS_LOADER_EXPORT___;
