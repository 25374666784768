import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { isErrorResponse, isSuccessResponse } from '@shared/types/apiHelpers';
import { getUpcomingReservationsByListing } from 'restaurantAdmin/reservations/apiHelpers';
import type { Listing } from '../apiHelpers';
import { unpublishListing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';

export interface UnpublishListingConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  listing: Listing;
}

export const UnpublishListingConfirmationModal = ({
  closeModal,
  isOpen,
  listing,
}: UnpublishListingConfirmationModalProps) => {
  const [numOfUpcomingReservations, setNumOfUpcomingReservations] = useState(0);

  const navigate = useNavigate();
  const { subView, setSelectedDate, setSelectedListingId } =
    useListingsContext();

  useAbortEffect(
    async (signal) => {
      if (isOpen) {
        const response = await getUpcomingReservationsByListing(
          listing.restaurantId,
          listing.id,
          signal,
        );
        if (isSuccessResponse(response)) {
          setNumOfUpcomingReservations(response.length);
        }
      }
    },
    [isOpen],
  );

  const noUpcomingReservationMessage = `${listing.name} will be moved to Draft.`;
  const oneUpcomingReservationMessage = `Unpublishing ${listing.name} will affect 1 upcoming reservation. That reservation must be reconciled in Stranded Reservations.`;
  const multipleUpcomingReservationsMessage = `Unpublishing ${listing.name} will affect ${numOfUpcomingReservations} upcoming reservations. Those reservations must be reconciled in Stranded Reservations.`;

  let message;
  if (numOfUpcomingReservations === 0) {
    message = noUpcomingReservationMessage;
  } else if (numOfUpcomingReservations === 1) {
    message = oneUpcomingReservationMessage;
  } else {
    message = multipleUpcomingReservationsMessage;
  }

  const onConfirmUnpublishListing = () => {
    void (async () => {
      const response = await unpublishListing(listing.restaurantId, listing.id);
      if (isErrorResponse(response)) {
        errorToast({ message: response.message });
      } else {
        setSelectedListingId(listing.id);
        setSelectedDate(listing.startDate);
        navigate(listingsPagePath({ scope: ListingsPageScope.Draft, subView }));
      }
    })();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Unpublish Listing"
      subtitle={message}
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Unpublish Listing"
          onClick={onConfirmUnpublishListing}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
