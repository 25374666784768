import { type Control, useWatch } from 'react-hook-form';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { ControlledFormTabRadio } from '@components/formInputs/ControlledFormTabRadio';
import { type SelectNumberOption } from '@components/formSelect/FormSelect';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import { type ListingFloorPlanFormData } from '../types';
import styles from './SeatingTypeFieldSet.scss';

export const TURN_TIME_OPTIONS: SelectNumberOption[] = [
  {
    label: '30 mins',
    value: 30,
  },
  {
    label: '45 mins',
    value: 45,
  },
  {
    label: '60 mins',
    value: 60,
  },
  {
    label: '75 mins',
    value: 75,
  },
  {
    label: '90 mins',
    value: 90,
  },
  {
    label: '105 mins',
    value: 105,
  },
  {
    label: '120 mins',
    value: 120,
  },
  {
    label: '135 mins',
    value: 135,
  },
  {
    label: '150 mins',
    value: 150,
  },
  {
    label: '165 mins',
    value: 165,
  },
  {
    label: '180 mins',
    value: 180,
  },
  {
    label: '195 mins',
    value: 195,
  },
  {
    label: '210 mins',
    value: 210,
  },
  {
    label: '225 mins',
    value: 225,
  },
  {
    label: '240 mins',
    value: 240,
  },
];
export const INTERVAL_OPTIONS: SelectNumberOption[] = [
  {
    label: '15 mins',
    value: 15,
  },
  {
    label: '30 mins',
    value: 30,
  },
  {
    label: '45 mins',
    value: 45,
  },
  {
    label: '60 mins',
    value: 60,
  },
];

export interface SeatingTypeFieldSetProps {
  control: Control<ListingFloorPlanFormData>;
  isDisabledForEdit?: boolean;
}

export const SeatingTypeFieldSet = ({
  control,
  isDisabledForEdit = false,
}: SeatingTypeFieldSetProps) => {
  const { maxReservationGuests } = useRestaurant();

  const minimumGuests = useWatch({
    control,
    name: 'minimumGuests',
  });
  const maximumGuests = useWatch({
    control,
    name: 'maximumGuests',
  });
  const inventoryCount = useWatch({
    control,
    name: 'inventoryCount',
  });
  const isCommunal = useWatch({
    control,
    name: 'isCommunal',
  });

  const maxGuestRules = isCommunal
    ? {
        max: {
          message:
            maxReservationGuests < Number(inventoryCount)
              ? `The Max Guest count cannot exceed the configured setting of ${maxReservationGuests} guests`
              : `The Max Guest count cannot exceed the Number of Seats`,
          value: Math.min(maxReservationGuests, Number(inventoryCount)),
        },
        min: {
          message: 'Max Guest must be greater than or equal to Min Guest',
          value: minimumGuests,
        },
        required: true,
      }
    : {
        max: {
          message: `The Max Guest count cannot exceed the configured setting of ${maxReservationGuests} guests`,
          value: maxReservationGuests,
        },
        min: {
          message: 'Max Guest must be greater than or equal to Min Guest',
          value: minimumGuests,
        },
        required: true,
      };

  const seatingTypeSubText = isCommunal
    ? 'Communal seating allows guests to reserve a number of individual seats seats at a table.'
    : 'Table seating allows guests to reserve tables with a set number of seats.';

  const inventoryCountTooltip = isCommunal
    ? 'Dictates how many individual seats are available to be purchased for this listing. WARNING: The Number of Seats may dictate the maximum party size.'
    : 'Dictates how many reservations at a single time can be purchased for this listing. WARNING: A Number of Tables greater than the number of tables highlighted on the floorplan can yield to overbooking.';

  return (
    <fieldset className={styles.fieldset} data-testid="seating-type-fieldset">
      <h3 className={typography.t3} id="seating-type-label">
        seating type
      </h3>
      <ControlledFormTabRadio
        ariaLabeledBy="seating-type-label"
        control={control}
        name="isCommunal"
        options={['Table', 'Communal']}
        isDisabled={isDisabledForEdit}
      />
      <p className={typography.t2}>{seatingTypeSubText}</p>
      <ControlledFormInput
        control={control}
        type="number"
        label={isCommunal ? 'Number of Seats' : 'Number of Tables'}
        name="inventoryCount"
        tooltipText={inventoryCountTooltip}
        rules={{
          min: {
            message: `Must be at least 1`,
            value: 1,
          },
          required: true,
        }}
      />
      <ControlledFormInput
        control={control}
        type="number"
        label="Min Guest"
        name="minimumGuests"
        rules={{
          max: {
            message: `Min Guest must be less than or equal to Max Guest`,
            value: maximumGuests,
          },
          min: {
            message: `A reservation must seat at least 1 guest`,
            value: 1,
          },
          required: true,
        }}
      />
      <ControlledFormInput
        control={control}
        type="number"
        label="Max Guest"
        name="maximumGuests"
        rules={maxGuestRules}
      />
      <ControlledFormSelect
        control={control}
        label="Turn Time"
        name="turnTime"
        options={TURN_TIME_OPTIONS}
        tooltipText="An estimate of how long guests will usually sit at the table, plus the time it takes to clear and set the table for the next guest"
        rules={{
          required: true,
        }}
        disabled={isDisabledForEdit}
      />
      <ControlledFormSelect
        control={control}
        label="Interval"
        name="interval"
        options={INTERVAL_OPTIONS}
        tooltipText="How often an Availability is shown between its “start” and “end” times. For example, a Listing with a start time of 12pm, an end time of 2pm, and a 30 minute interval will generate Availabilities at 12pm, 12:30pm, 1pm, 1:30pm, and 2pm."
        rules={{
          required: true,
        }}
      />
    </fieldset>
  );
};
