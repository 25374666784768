import { MenuItem } from '@mui/base';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu as MuiMenu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import cx from 'classnames';
import { type MouseEvent, useRef } from 'react';
import styles from './Menu.scss';
import { type MenuProps } from './types';

declare module '@mui/base/Menu' {
  export interface MenuOwnProps {
    placement?: string;
  }
}

export const Menu = ({
  ariaLabelPrefix,
  className,
  menuButton,
  options = [],
}: MenuProps) => {
  const menuRef = useRef<HTMLElement>(null);
  const refCurrent = () => menuRef.current!;

  return (
    // The <div> element has a child <button> element that allows keyboard interaction
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onClick={(e: MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseDown={(e: MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Dropdown>
        <MenuButton
          aria-label={[ariaLabelPrefix, 'options'].join(' ')}
          className={styles.menuButton}
          ref={menuRef}
        >
          {menuButton}
        </MenuButton>
        <MuiMenu
          anchor={refCurrent}
          className={styles.menu}
          placement="left-start"
        >
          {options.map(({ disabled, label, onClick, optionClassName }) => (
            <MenuItem
              className={cx(styles.menuItem, optionClassName)}
              disabled={disabled}
              key={label}
              onClick={onClick}
            >
              {label}
            </MenuItem>
          ))}
        </MuiMenu>
      </Dropdown>
    </div>
  );
};
