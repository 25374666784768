import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import {
  OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH,
  OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH,
} from 'restaurantAdmin/paths';
import { FormNames } from './createListingUtils';

export interface IncompleteListingModalProps {
  incompleteForm?: string;
  isOpen: boolean;
  isSavingDraft?: boolean;
  onClose: () => void;
}

export const IncompleteListingModal = ({
  incompleteForm,
  isOpen,
  isSavingDraft,
  onClose,
}: IncompleteListingModalProps) => {
  const navigate = useNavigate();

  // TODO: Make all this matching smarter when the price points form is added
  const incompleteFormIsFloorPlan = incompleteForm === FormNames.FloorPlan;
  const incompleteFormDisplayName = incompleteFormIsFloorPlan
    ? 'Floor Plan'
    : 'Reservable Days';

  const CTA = `Go to ${incompleteFormDisplayName}`;
  const incompleteFormPath = incompleteFormIsFloorPlan
    ? OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH
    : OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH;
  const attemptedAction = isSavingDraft
    ? 'saving this listing as a draft'
    : 'publishing this listing';
  const completeMessage = `You must complete the ${incompleteFormDisplayName} form before ${attemptedAction}.`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      subtitle={completeMessage}
      title="Incomplete Listing"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={onClose}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label={CTA}
          onClick={() => {
            navigate(incompleteFormPath);
            onClose();
          }}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
