import type { TimeValues } from '@components/formInputs/ControlledFormTimeSelect';

export const parseTimeString = (timeString?: string): TimeValues => {
  if (!timeString) return [];

  const [hour, minute] = timeString.split(':');
  let twelveHourNumber = Number(hour);

  const meridiem = twelveHourNumber > 11 ? 'PM' : 'AM';

  if (twelveHourNumber === 0) twelveHourNumber = 12;
  else if (twelveHourNumber > 12) twelveHourNumber -= 12;

  return [`${String(twelveHourNumber).padStart(2, '0')}`, minute, meridiem];
};

export const parseTimeValues = (timeValues: TimeValues): string => {
  const [hour, minute, meridiem] = timeValues;
  let twentyFourHourNumber = Number(hour);

  if (meridiem === 'PM' && twentyFourHourNumber < 12)
    twentyFourHourNumber += 12;
  else if (meridiem === 'AM' && twentyFourHourNumber === 12)
    twentyFourHourNumber = 0;

  return `${String(twentyFourHourNumber).padStart(2, '0')}:${minute}:00`;
};

export const isCompleteTimeValue = (timeValues: TimeValues): boolean => {
  const [hour, minute, meridiem] = timeValues;
  return !!hour && !!minute && !!meridiem;
};
