// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WaitListForm__container___YQcFa{display:flex;flex-direction:column;padding:1.5rem;gap:1.5rem}.WaitListForm__header___cjGdb{display:flex;justify-content:space-between}.WaitListForm__closeButton___KEJEz{background-color:rgba(0,0,0,0);border:none;cursor:pointer;padding:0}.WaitListForm__closeButton___KEJEz .WaitListForm__icon___wpmOs{stroke:var(--white70);width:20px}.WaitListForm__form___JWve7{display:flex;flex-direction:column;gap:1.5rem}.WaitListForm__inputContainer___IJ_2u{display:flex;flex-direction:column;gap:1rem}.WaitListForm__waitListInput___uG33R{flex-direction:column;gap:.5rem;justify-content:space-between}.WaitListForm__waitListInput___uG33R>div{width:100%}.WaitListForm__waitListInput___uG33R>div>div{margin-left:0}.WaitListForm__waitListInput___uG33R input{width:unset}@media only screen and (min-width: 1280px){.WaitListForm__waitListInput___uG33R{flex-direction:row;gap:unset;justify-content:space-between}.WaitListForm__waitListInput___uG33R>div{width:unset}.WaitListForm__waitListInput___uG33R label{width:max-content}.WaitListForm__waitListInput___uG33R input,.WaitListForm__waitListInput___uG33R input~div{width:230px}}.WaitListForm__actionContainer___yISY1{display:flex;flex-direction:column;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/waitList/WaitListForm.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,UAAA,CAGF,8BACE,YAAA,CACA,6BAAA,CAGF,mCACE,8BAAA,CACA,WAAA,CACA,cAAA,CACA,SAAA,CAEA,+DACE,qBAAA,CACA,UAAA,CAIJ,4BACE,YAAA,CACA,qBAAA,CACA,UAAA,CAGF,sCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,qCACE,qBAAA,CACA,SAAA,CACA,6BAAA,CAEA,yCACE,UAAA,CAEA,6CACE,aAAA,CAIJ,2CACE,WAAA,CCpBF,2CDMF,qCAoBI,kBAAA,CACA,SAAA,CACA,6BAAA,CAEA,yCACE,WAAA,CAGF,2CACE,iBAAA,CAGF,0FAEE,WAAA,CAAA,CAON,uCACE,YAAA,CACA,qBAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WaitListForm__container___YQcFa",
	"header": "WaitListForm__header___cjGdb",
	"closeButton": "WaitListForm__closeButton___KEJEz",
	"icon": "WaitListForm__icon___wpmOs",
	"form": "WaitListForm__form___JWve7",
	"inputContainer": "WaitListForm__inputContainer___IJ_2u",
	"waitListInput": "WaitListForm__waitListInput___uG33R",
	"actionContainer": "WaitListForm__actionContainer___yISY1"
};
export default ___CSS_LOADER_EXPORT___;
