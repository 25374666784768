import cx from 'classnames';
import type {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ControlledFormEndlessDateInput.scss';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface ControlledFormEndlessDateInputProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  label: string;
  name: Name;
  rules?: UseControllerProps<T, Name>['rules'];
  defaultValue?: PathValue<T, Name>;
}

export const ControlledFormEndlessDateInput = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  label,
  name,
  rules,
  defaultValue,
}: ControlledFormEndlessDateInputProps<T, Name>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    defaultValue,
  });

  const handleOnClickEndless = () => {
    onChange('');
  };

  return (
    <div className={cx(styles.fieldContainer, className)}>
      <div className={styles.labelAndButtonContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <button
          onClick={handleOnClickEndless}
          type="button"
          className={typography.c3_20}
        >
          Endless
        </button>
      </div>
      <div className={styles.inputContainer}>
        <input
          inputMode="none"
          aria-errormessage={`${name}-error`}
          aria-invalid={!!error}
          autoComplete="off"
          className={cx(typography.t1, {
            [styles.inputError]: !!error,
          })}
          id={name}
          onChange={onChange}
          type={value ? 'date' : 'text'}
          onMouseDown={(e) => {
            e.currentTarget.type = 'date';
            if ('showPicker' in e.currentTarget) {
              e.currentTarget.showPicker();
            }
          }}
          onFocus={(e) => {
            e.target.type = 'date';
          }}
          onBlur={(e) => {
            if (!e.target.value) e.target.type = 'text';
          }}
          placeholder="Endless"
          value={value || ''}
        />
        <ValidationErrorMessage error={error} label={label} name={name} />
      </div>
    </div>
  );
};
