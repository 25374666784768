import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { IconButton } from '@components/iconButton/IconButton';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { toMonthDateAndYear, toWeekdayMonthDateAndYear } from '@utils/date';
import typography from '~styles/typography.scss';
import { ClosureCalendar } from '../../reservations/occupants/pageHeader/ClosureCalendar';
import styles from './DateSelector.scss';

export interface DateSelectorProps {
  handleOnClickToday: () => void;
  isToday: boolean;
  handleOnChangeCalendarDate: (date: Date) => void;
  selectedDate: Date;
  setNextDay: () => void;
  setPreviousDay: () => void;
}

export const DateSelector = ({
  handleOnClickToday,
  isToday,
  handleOnChangeCalendarDate,
  selectedDate,
  setNextDay,
  setPreviousDay,
}: DateSelectorProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const { ref: calendarRef } = useHandleClickOutside(() =>
    setShowCalendar(false),
  );

  return (
    <nav aria-label="Date" className={styles.main}>
      <Button
        className={cx(typography.h8, styles.buttonToday)}
        isDisabled={isToday}
        label="Today"
        onClick={handleOnClickToday}
        variant={ButtonVariants.Tertiary}
      />
      <IconButton
        ariaLabel="previous day"
        className={styles.button}
        iconName="arrowLeft"
        onClick={setPreviousDay}
      />
      <div>
        <button
          aria-label={`select date (current date is ${toMonthDateAndYear(
            selectedDate,
          )})`}
          className={cx(typography.h6, styles.dateSelect)}
          onClick={() => setShowCalendar(true)}
        >
          {toWeekdayMonthDateAndYear(selectedDate)}
        </button>
        {showCalendar && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={styles.calendarContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <ClosureCalendar
              onChange={(date) => {
                handleOnChangeCalendarDate(date);
                setShowCalendar(false);
              }}
              ref={calendarRef}
              selectedDate={selectedDate}
            />
          </div>
        )}
      </div>
      <IconButton
        ariaLabel="next day"
        className={cx(styles.button, styles.arrowRightIcon)}
        iconName="arrowLeft"
        onClick={setNextDay}
      />
    </nav>
  );
};
