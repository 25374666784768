import cx from 'classnames';
import { type KeyboardEvent, type MouseEvent, useRef } from 'react';
import type { Control, FieldValues, Path, PathValue } from 'react-hook-form';
import { useController } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ControlledFormTabRadio.scss';

export interface ControlledFormTabRadioProps<T extends FieldValues> {
  ariaLabeledBy?: string;
  control: Control<T>;
  name: Path<T>;
  options: [string, string];
  defaultValue?: PathValue<T, Path<T>> | undefined;
  isDisabled?: boolean;
}

const FOCUSABLE_TAB_INDEX_IN_PAGE_TAB_SEQUENCE = 0;
const FOCUSABLE_TAB_INDEX_OUTSIDE_PAGE_TAB_SEQUENCE = -1;

export const ControlledFormTabRadio = <T extends FieldValues>({
  ariaLabeledBy,
  control,
  name,
  options,
  defaultValue,
  isDisabled,
}: ControlledFormTabRadioProps<T>) => {
  const {
    field: { onChange, value: checked },
  } = useController({
    control,
    name,
    defaultValue,
  });

  const firstRadioButtonRef = useRef<HTMLDivElement | null>(null);
  const secondRadioButtonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    if (isDisabled) return;
    // ignore clicks on the currently selected radio button
    if (e.currentTarget.textContent === options[0] && !checked) return;
    if (e.currentTarget.textContent === options[1] && checked) return;

    if (checked) {
      onChange(false);
    } else {
      onChange(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (isDisabled) return;
    if (
      e.key === 'ArrowDown' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowLeft'
    ) {
      if (checked) {
        onChange(false);
        firstRadioButtonRef.current?.focus();
      } else {
        onChange(true);
        secondRadioButtonRef.current?.focus();
      }
    }
  };
  const radioClassName = cx(typography.t2, { [styles.isDisabled]: isDisabled });

  return (
    <div
      aria-labelledby={ariaLabeledBy}
      className={styles.radioContainer}
      role="radiogroup"
    >
      <div
        aria-checked={!checked}
        className={radioClassName}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={firstRadioButtonRef}
        role="radio"
        tabIndex={
          checked
            ? FOCUSABLE_TAB_INDEX_OUTSIDE_PAGE_TAB_SEQUENCE
            : FOCUSABLE_TAB_INDEX_IN_PAGE_TAB_SEQUENCE
        }
      >
        {options[0]}
      </div>
      <div
        aria-checked={!!checked}
        className={radioClassName}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={secondRadioButtonRef}
        role="radio"
        tabIndex={
          checked
            ? FOCUSABLE_TAB_INDEX_IN_PAGE_TAB_SEQUENCE
            : FOCUSABLE_TAB_INDEX_OUTSIDE_PAGE_TAB_SEQUENCE
        }
      >
        {options[1]}
      </div>
    </div>
  );
};
