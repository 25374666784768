// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DailyReservationsReportPage__container___k2ZoL{padding:.5rem;background-color:var(--white);color:var(--black);min-height:100vh}.DailyReservationsReportPage__container___k2ZoL p{margin:0}.DailyReservationsReportPage__container___k2ZoL h1{margin-bottom:.5rem}.DailyReservationsReportPage__container___k2ZoL h2{margin-bottom:1rem;padding-bottom:.5rem;border-bottom:var(--black) 1px solid}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__totals___hZ3vk{display:flex;flex-direction:row;gap:2rem;margin-block:2rem}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__reservationsList___m7jmn{margin-top:2rem}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__reservationsList___m7jmn>li>ul{display:flex;flex-direction:column;gap:1.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/report/DailyReservationsReportPage.scss"],"names":[],"mappings":"AAAA,gDACE,aAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA,CAEA,kDACE,QAAA,CAGF,mDACE,mBAAA,CAGF,mDACE,kBAAA,CACA,oBAAA,CACA,oCAAA,CAGF,6FACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CAGF,uGACE,eAAA,CAEA,6GACE,YAAA,CACA,qBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DailyReservationsReportPage__container___k2ZoL",
	"totals": "DailyReservationsReportPage__totals___hZ3vk",
	"reservationsList": "DailyReservationsReportPage__reservationsList___m7jmn"
};
export default ___CSS_LOADER_EXPORT___;
