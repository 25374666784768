// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantEventForm__form___JSVz1{background-color:var(--primary500);border-radius:8px;display:flex;flex-direction:column;gap:1.5rem;padding:3rem 2.5rem;width:47rem}.RestaurantEventForm__form___JSVz1 header{border-bottom:1px solid var(--hairline);padding-bottom:24px}.RestaurantEventForm__form___JSVz1 header h2{color:var(--white);margin:0}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__dateInputs___DDOBT{display:flex;gap:1rem;justify-content:space-between;width:100%}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__repeatInput___swI6F{row-gap:.25rem;width:fit-content}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__buttonContainer___VfJzz{display:flex;gap:1rem;justify-content:flex-end;margin-top:1.5rem}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__buttonContainer___VfJzz button{width:9.75rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/events/RestaurantEventForm.scss"],"names":[],"mappings":"AAAA,mCACE,kCAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,mBAAA,CACA,WAAA,CAEA,0CACE,uCAAA,CACA,mBAAA,CAEA,6CACE,kBAAA,CACA,QAAA,CAIJ,4EACE,YAAA,CACA,QAAA,CACA,6BAAA,CACA,UAAA,CAGF,6EACE,cAAA,CACA,iBAAA,CAGF,iFACE,YAAA,CACA,QAAA,CACA,wBAAA,CACA,iBAAA,CAEA,wFACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "RestaurantEventForm__form___JSVz1",
	"dateInputs": "RestaurantEventForm__dateInputs___DDOBT",
	"repeatInput": "RestaurantEventForm__repeatInput___swI6F",
	"buttonContainer": "RestaurantEventForm__buttonContainer___VfJzz"
};
export default ___CSS_LOADER_EXPORT___;
