// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton__button___g3Wpi{cursor:pointer;border:none;border-radius:50%;padding:4px;color:var(--white);background-color:var(--darkGrey300)}.IconButton__button___g3Wpi svg{stroke:#fff;width:24px;height:24px}.IconButton__button___g3Wpi:active{background-color:var(--accent300)}.IconButton__button___g3Wpi:disabled{opacity:.5;cursor:not-allowed}", "",{"version":3,"sources":["webpack://./src/shared/components/iconButton/IconButton.scss"],"names":[],"mappings":"AAAA,4BACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,mCAAA,CAEA,gCACE,WAAA,CACA,UAAA,CACA,WAAA,CAGF,mCACE,iCAAA,CAGF,qCACE,UAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "IconButton__button___g3Wpi"
};
export default ___CSS_LOADER_EXPORT___;
