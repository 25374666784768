import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageHeader } from 'restaurantAdmin/layout/PageHeader';
import { OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH } from 'restaurantAdmin/paths';
import { useListingsContext } from '../ListingsContext';
import styles from './ListingsPageHeader.scss';
import { listingsPagePath, ListingsPageSubView } from './listingsPagePath';

export const ListingsPageHeader = () => {
  const { clearSelectedListing, scope } = useListingsContext();

  const tabLinkClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tabLink]: true,
      [styles.isActive]: isActive,
    });

  return (
    <PageHeader category="Operations" title="Listings">
      <nav>
        <NavLink
          className={tabLinkClassNames}
          onClick={clearSelectedListing}
          to={listingsPagePath({
            scope,
            subView: ListingsPageSubView.FloorPlan,
          })}
        >
          Floor Plan
        </NavLink>
        <NavLink
          className={tabLinkClassNames}
          onClick={clearSelectedListing}
          to={listingsPagePath({
            scope,
            subView: ListingsPageSubView.Calendar,
          })}
        >
          Calendar
        </NavLink>
      </nav>
      <LinkStyledAsButton
        variant={ButtonVariants.Secondary}
        to={OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH}
        label="Create Listing"
      />
    </PageHeader>
  );
};
