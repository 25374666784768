import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import cx from 'classnames';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './NavigationMenuDropdown.scss';

interface MenuItemProps {
  path: string;
  label: string;
}

export interface NavigationMenuDropdownProps {
  menuLabel: string;
  menuItems: MenuItemProps[];
  icon: AnyIcon;
}

export const NavigationMenuDropdown = ({
  icon,
  menuLabel,
  menuItems,
}: NavigationMenuDropdownProps) => {
  const { pathname } = useLocation();
  const [open, setIsOpen] = useState(false);

  const menuRegex = new RegExp(`.*${menuLabel}.*`, 'i');
  const isSelected = menuRegex.test(pathname);

  const menuButtonStyles = cx({
    [styles.menuButton]: true,
    [styles.activeMenuButton]: isSelected,
  });

  const chevronStyles = cx({
    [styles.chevron]: true,
    [styles.chevronUp]: open,
  });

  return (
    <Dropdown
      onOpenChange={() => {
        setIsOpen(!open);
      }}
    >
      <MenuButton className={menuButtonStyles}>
        <Icon name={icon} ariaLabel={`${menuLabel} menu icon`} />
        <Icon name="chevron" className={chevronStyles} />
        <div className={typography.t2}>{menuLabel}</div>
      </MenuButton>
      <Menu
        className={styles.menu}
        slotProps={{ root: { keepMounted: false } }}
      >
        {menuItems.map(({ path, label }) => (
          <MenuItem className={styles.menuItem} key={label}>
            <NavLink className={cx(styles.menuLink, typography.t2)} to={path}>
              {label}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};
