import { type Control, useWatch } from 'react-hook-form';
import {
  ControlledFormTimeSelect,
  type TimeValues,
} from '@components/formInputs/ControlledFormTimeSelect';
import {
  isCompleteTimeValue,
  parseTimeValues,
} from '@components/formInputs/timeValuesUtils';
import typography from '~styles/typography.scss';
import { type ListingReservableDaysFormData } from '../types';
import styles from './ReservableTimesFieldSet.scss';

export interface ReservableTimesFieldSetProps {
  control: Control<ListingReservableDaysFormData>;
}

export const ReservableTimesFieldSet = ({
  control,
}: ReservableTimesFieldSetProps) => {
  const startTime = useWatch({
    control,
    name: 'startTime',
  });

  const isGreaterThanOrEqualToStartTime = (
    endTime: TimeValues,
  ): string | boolean =>
    startTime && parseTimeValues(endTime) >= parseTimeValues(startTime)
      ? true
      : 'End time must be the same as or after the Start Time';
  const isComplete = (timeValues: TimeValues): string | boolean =>
    isCompleteTimeValue(timeValues)
      ? true
      : 'Time must be completely filled in';

  return (
    <fieldset
      className={styles.fieldset}
      data-testid="reservable-times-fieldset"
    >
      <h3 className={typography.t3}>reservable times</h3>
      <ControlledFormTimeSelect
        control={control}
        label="Start Time"
        name="startTime"
        rules={{
          required: true,
          validate: { isComplete },
        }}
      />
      <ControlledFormTimeSelect
        control={control}
        label="End Time"
        name="endTime"
        rules={{
          required: true,
          validate: {
            isGreaterThanOrEqualToStartTime,
            isComplete,
          },
        }}
      />
    </fieldset>
  );
};
