import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@components/menu/Menu';
import { type MenuOptionProps } from '@components/menu/types';
import { useFeatureFlagContext } from '@shared/context/FeatureFlagContext';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import {
  OPERATIONS_LISTING_EDIT_PATH,
  OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH,
  OPERATIONS_LISTINGS_EDIT_FLOOR_PLAN_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_PRICING_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_RESERVABLE_DAYS_TERMINAL_PATH,
} from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import type { Listing } from '../apiHelpers';
import type { ListingLocationState } from '../utils/listingUtils';
import { DeleteListingConfirmationModal } from './DeleteListingConfirmationModal';
import styles from './ListingMenu.scss';
import { PublishListingConfirmationModal } from './PublishListingConfirmationModal';
import { UnpublishListingConfirmationModal } from './UnpublishListingConfirmationModal';

interface ListingMenuProps {
  listing: Listing;
}

export const ListingMenu = ({ listing }: ListingMenuProps) => {
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] =
    useState(false);
  const [isConfirmUnpublishModalOpen, setIsConfirmUnpublishModalOpen] =
    useState(false);
  const { isEnabled } = useFeatureFlagContext<RestaurantAdminFeatureFlag>();

  const navigate = useNavigate();

  const options: MenuOptionProps[] = [
    ...(listing.status === 'draft'
      ? [
          {
            label: 'Publish Listing',
            onClick: () => setIsConfirmPublishModalOpen(true),
          },
        ]
      : []),
    ...(listing.status === 'active'
      ? [
          {
            label: 'Unpublish Listing',
            onClick: () => setIsConfirmUnpublishModalOpen(true),
          },
        ]
      : []),
    {
      label: 'Duplicate Listing',
      onClick: () =>
        navigate(OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH, {
          state: { duplicateListingId: listing.id } as ListingLocationState,
        }),
    },
    {
      label: 'Edit Floor Plan',
      onClick: () =>
        navigate(
          `${OPERATIONS_LISTING_EDIT_PATH}/${listing.id}/${OPERATIONS_LISTINGS_EDIT_FLOOR_PLAN_TERMINAL_PATH}`,
        ),
    },
    {
      label: 'Edit Reservable Days',
      onClick: () =>
        navigate(
          `${OPERATIONS_LISTING_EDIT_PATH}/${listing.id}/${OPERATIONS_LISTINGS_EDIT_RESERVABLE_DAYS_TERMINAL_PATH}`,
        ),
    },
    ...(isEnabled('pricingManagerFlag')
      ? [
          {
            label: 'Edit Pricing',
            onClick: () =>
              navigate(
                `${OPERATIONS_LISTING_EDIT_PATH}/${listing.id}/${OPERATIONS_LISTINGS_EDIT_PRICING_TERMINAL_PATH}`,
              ),
          },
        ]
      : []),
    {
      label: 'Delete Listing',
      onClick: () => setIsConfirmDeleteModalOpen(true),
      optionClassName: styles.deleteListing,
    },
  ];

  const menuButton = (
    <div className={cx(typography.h8, styles.options)}>Options</div>
  );

  return (
    <>
      <Menu options={options} menuButton={menuButton} />
      <DeleteListingConfirmationModal
        closeModal={() => setIsConfirmDeleteModalOpen(false)}
        isOpen={isConfirmDeleteModalOpen}
        listing={listing}
      />
      <PublishListingConfirmationModal
        closeModal={() => setIsConfirmPublishModalOpen(false)}
        isOpen={isConfirmPublishModalOpen}
        listing={listing}
      />
      <UnpublishListingConfirmationModal
        closeModal={() => setIsConfirmUnpublishModalOpen(false)}
        isOpen={isConfirmUnpublishModalOpen}
        listing={listing}
      />
    </>
  );
};
