import { type RestaurantMetadata } from '../restaurants/apiHelpers';
import { useAuth } from './AuthContext';

/**
 * Returns the current restaurant.
 *
 * This is safe to call anywhere within `<IsAuthenticated />` since there does
 * not exist a "no restaurant selected" state.
 */
export const useRestaurant = (): RestaurantMetadata => {
  const { selectedRestaurant } = useAuth();
  if (!selectedRestaurant) {
    throw new Error('selectedRestaurant is null');
  }
  return selectedRestaurant;
};
