import { useEffect, useState } from 'react';
import {
  type Control,
  useFieldArray,
  type UseFormGetValues,
  type UseFormHandleSubmit,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  isCompleteTimeValue,
  parseTimeValues,
} from '@components/formInputs/timeValuesUtils';
import { Icon } from '@components/icon/Icon';
import type {
  ListingPricingFormData,
  ListingReservableDaysFormData,
} from '../types';
import { timeRangeOverlaps } from '../utils/timeRange';
import { DEFAULT_PRICE_POINT, PricePointField } from './PricePointField';
import styles from './PricePointsList.scss';

interface PricePointsListProps {
  pricingControl: Control<ListingPricingFormData>;
  pricingFormGetValues: UseFormGetValues<ListingPricingFormData>;
  pricingFormHandleSubmit: UseFormHandleSubmit<ListingPricingFormData>;
  pricingFormSetValue: UseFormSetValue<ListingPricingFormData>;
  reservableDaysFormValues: Pick<
    ListingReservableDaysFormData,
    'endTime' | 'repeat' | 'startTime'
  >;
}

export const PricePointsList = ({
  pricingControl,
  pricingFormGetValues,
  pricingFormHandleSubmit,
  pricingFormSetValue,
  reservableDaysFormValues,
}: PricePointsListProps) => {
  const {
    fields,
    append: addPricePoint,
    remove: removePricePoint,
  } = useFieldArray({
    control: pricingControl,
    name: 'pricePoints',
    // this is needed bc the library maintainer decided it was a good idea to use the key name 'id' for this as a default
    keyName: 'key',
  });

  const pricePoints = useWatch({
    control: pricingControl,
    name: 'pricePoints',
  });

  const [overlappingIndexes, setOverlappingIndexes] = useState<number[]>([]);

  const hasOverlappingDay = (days1: string[], days2: string[]) =>
    days1.some((a) => days2.includes(a));

  useEffect(() => {
    if (pricePoints?.length && pricePoints.length > 1) {
      const indexesWithOverlap = pricePoints.reduce<number[]>(
        (errorIndices, pricePoint, index) => {
          const hasOverlap = pricePoints.some(
            (p, i) =>
              index !== i &&
              isCompleteTimeValue(p.startTime) &&
              isCompleteTimeValue(p.endTime) &&
              isCompleteTimeValue(pricePoint.startTime) &&
              isCompleteTimeValue(pricePoint.endTime) &&
              hasOverlappingDay(p.activeDays, pricePoint.activeDays) &&
              timeRangeOverlaps(
                {
                  startTime: parseTimeValues(p.startTime),
                  endTime: parseTimeValues(p.endTime),
                },
                {
                  startTime: parseTimeValues(pricePoint.startTime),
                  endTime: parseTimeValues(pricePoint.endTime),
                },
              ),
          );
          if (hasOverlap) errorIndices.push(index);
          return errorIndices;
        },
        [],
      );
      setOverlappingIndexes(indexesWithOverlap);
    }
  }, [pricePoints]);

  const handleOnClickAddWeeklyPricePoint = pricingFormHandleSubmit(
    () => addPricePoint(DEFAULT_PRICE_POINT),
    () => addPricePoint(DEFAULT_PRICE_POINT),
  );

  return (
    <>
      {fields.map(({ key, ...pricePoint }, index) => (
        <PricePointField
          key={key}
          hasOverlap={overlappingIndexes.includes(index)}
          pricePointIndex={index}
          pricePoint={pricePoint}
          pricingControl={pricingControl}
          pricingFormGetValues={pricingFormGetValues}
          pricingFormHandleSubmit={pricingFormHandleSubmit}
          pricingFormSetValue={pricingFormSetValue}
          removePricePoint={removePricePoint}
          reservableDaysFormValues={reservableDaysFormValues}
        />
      ))}
      <button
        className={styles.addButton}
        type="button"
        onClick={handleOnClickAddWeeklyPricePoint}
      >
        Add Weekly Price Point
        <Icon name="plus" />
      </button>
    </>
  );
};
