// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BasePriceField__showContainer___WrKkx{align-items:center;display:flex;justify-content:space-between}.BasePriceField__showContainer___WrKkx .BasePriceField__editButtonContainer___tiIC5{display:flex;gap:.5rem;align-items:center}.BasePriceField__showContainer___WrKkx .BasePriceField__editButton___JzZN0{background-color:unset;padding:0}.BasePriceField__showContainer___WrKkx .BasePriceField__editButton___JzZN0 svg{fill:var(--white70);stroke:unset;width:1rem}.BasePriceField__editContainer___gucNv{border-radius:16px;border:1px solid var(--gold);display:flex;flex-direction:column;gap:1rem;padding:.75rem}.BasePriceField__editContainer___gucNv p{color:var(--white70);margin:0}.BasePriceField__editContainer___gucNv .BasePriceField__actions___ZpuNj{display:flex;gap:.5rem}.BasePriceField__editContainer___gucNv .BasePriceField__actions___ZpuNj button{flex:1 1 0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/BasePriceField.scss"],"names":[],"mappings":"AAAA,uCACE,kBAAA,CACA,YAAA,CACA,6BAAA,CAEA,oFACE,YAAA,CACA,SAAA,CACA,kBAAA,CAGF,2EACE,sBAAA,CACA,SAAA,CAEA,+EACE,mBAAA,CACA,YAAA,CACA,UAAA,CAKN,uCACE,kBAAA,CACA,4BAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA,CAEA,yCACE,oBAAA,CACA,QAAA,CAGF,wEACE,YAAA,CACA,SAAA,CAEA,+EACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showContainer": "BasePriceField__showContainer___WrKkx",
	"editButtonContainer": "BasePriceField__editButtonContainer___tiIC5",
	"editButton": "BasePriceField__editButton___JzZN0",
	"editContainer": "BasePriceField__editContainer___gucNv",
	"actions": "BasePriceField__actions___ZpuNj"
};
export default ___CSS_LOADER_EXPORT___;
