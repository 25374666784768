import cx from 'classnames';
import { Avatar } from '@components/avatar/Avatar';
import { toShortDateFormat } from '@utils/date';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { Status, StatusTag } from '../../components/statusTag/StatusTag';
import type { StrandedReservation } from './apiHelpers';
import styles from './StrandedList.scss';

interface StrandedListProps {
  onItemClick: (reservation: StrandedReservation) => void;
  selectedStrandedReservation: StrandedReservation | null;
  strandedReservations: StrandedReservation[];
}

export const StrandedList = ({
  onItemClick,
  selectedStrandedReservation,
  strandedReservations,
}: StrandedListProps) => {
  if (!strandedReservations.length) {
    return (
      <p className={styles.emptyState}>No stranded reservations present</p>
    );
  }
  return (
    <ul
      aria-label="all stranded reservations as of 15 minutes ago"
      className={styles.list}
    >
      {strandedReservations.map((reservation) => {
        const listItemId = `stranded-reservation-${reservation.id}`;
        return (
          <li
            key={reservation.id}
            className={cx(styles.card, {
              [styles.selected]:
                selectedStrandedReservation?.id === reservation.id,
            })}
            aria-labelledby={listItemId}
            onClick={() => onItemClick(reservation)}
          >
            <Avatar
              firstName={reservation.guest.firstName}
              lastName={reservation.guest.lastName}
            />
            <div className={styles.details}>
              <div className={styles.row}>
                <span className={typography.h8}>{`${toShortDateFormat(
                  reservation.date,
                  false,
                )} @ ${ISOTimeTo12HourTime(reservation.time)}`}</span>
                <span
                  className={typography.t3}
                >{`${reservation.guestCount} guests`}</span>
              </div>
              <div className={styles.row}>
                <span
                  id={listItemId}
                  className={cx(typography.t3, styles.fullName)}
                >{`${reservation.guest.firstName} ${reservation.guest.lastName}`}</span>
                <StatusTag status={Status.Stranded} />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
