import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { getUpcomingReservationsByListing } from 'restaurantAdmin/reservations/apiHelpers';
import type { Listing } from '../apiHelpers';
import { deleteListing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';

export interface DeleteListingConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  listing: Listing;
}

export const DeleteListingConfirmationModal = ({
  closeModal,
  isOpen,
  listing,
}: DeleteListingConfirmationModalProps) => {
  const [numOfUpcomingReservations, setNumOfUpcomingReservations] = useState(0);

  const { refreshListings, clearSelectedListing } = useListingsContext();

  useAbortEffect(
    async (signal) => {
      if (isOpen) {
        const response = await getUpcomingReservationsByListing(
          listing.restaurantId,
          listing.id,
          signal,
        );
        if (isSuccessResponse(response)) {
          setNumOfUpcomingReservations(response.length);
        }
      }
    },
    [isOpen],
  );

  const noUpcomingReservationMessage = `${listing.name} will be permanently deleted.`;
  const oneUpcomingReservationMessage = `Deleting ${listing.name} will affect 1 upcoming reservation. That reservation must be reconciled in Stranded Reservations.`;
  const multipleUpcomingReservationsMessage = `Deleting ${listing.name} will affect ${numOfUpcomingReservations} upcoming reservations. Those reservations must be reconciled in Stranded Reservations.`;

  let message;
  if (numOfUpcomingReservations === 0) {
    message = noUpcomingReservationMessage;
  } else if (numOfUpcomingReservations === 1) {
    message = oneUpcomingReservationMessage;
  } else {
    message = multipleUpcomingReservationsMessage;
  }

  const onConfirmDeleteListing = () => {
    void (async () => {
      const response = await deleteListing(listing.restaurantId, listing.id);
      if (response.ok) {
        refreshListings();
        clearSelectedListing();
      } else {
        errorToast({ message: 'Failed to delete the listing' });
      }
    })();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Delete Listing"
      subtitle={message}
    >
      <ModalActions>
        <Button
          label="Keep Listing"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Delete Listing"
          onClick={onConfirmDeleteListing}
          variant={ButtonVariants.Error}
        />
      </ModalActions>
    </Modal>
  );
};
