// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateEditListingPageLayout__pageWrapper___kEquI{display:flex;flex:1;flex-direction:column}.CreateEditListingPageLayout__main___TJPC0{display:flex;gap:2.5rem}.CreateEditListingPageLayout__main___TJPC0 .CreateEditListingPageLayout__displayPanel___z6SZA{display:flex;flex-direction:column;min-width:64%;width:64%}.CreateEditListingPageLayout__main___TJPC0 .CreateEditListingPageLayout__sidePanelContainer___szZBY{display:flex;flex-direction:column;min-width:34%;width:34%}.CreateEditListingPageLayout__main___TJPC0 .CreateEditListingPageLayout__sidePanelContainer___szZBY .CreateEditListingPageLayout__sidePanel___O5C3N{flex:1 0 0;display:flex;flex-direction:column;row-gap:1rem;overflow:auto}.CreateEditListingPageLayout__main___TJPC0 .CreateEditListingPageLayout__sidePanelContainer___szZBY .CreateEditListingPageLayout__sidePanel___O5C3N h2 span{display:block}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/CreateEditListingPageLayout.scss"],"names":[],"mappings":"AAAA,kDACE,YAAA,CACA,MAAA,CACA,qBAAA,CAGF,2CACE,YAAA,CACA,UAAA,CAEA,8FACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,SAAA,CAGF,oGACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,SAAA,CAEA,oJACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,aAAA,CAGE,4JACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": "CreateEditListingPageLayout__pageWrapper___kEquI",
	"main": "CreateEditListingPageLayout__main___TJPC0",
	"displayPanel": "CreateEditListingPageLayout__displayPanel___z6SZA",
	"sidePanelContainer": "CreateEditListingPageLayout__sidePanelContainer___szZBY",
	"sidePanel": "CreateEditListingPageLayout__sidePanel___O5C3N"
};
export default ___CSS_LOADER_EXPORT___;
