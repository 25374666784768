import { format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Error } from '@components/error/Error';
import { FinanceCard } from '@components/financeCard/FinanceCard';
import type { ApiResponse } from '@shared/types/apiHelpers';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { useError } from 'restaurantAdmin/errors/useError';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import type { FinancialSummaryForRestaurantAdmin } from './apiHelpers';
import { getFinancialSummary } from './apiHelpers';
import styles from './FinancesPage.scss';

const previousMonth = () => format(sub(Date.now(), { months: 1 }), 'yyyy-MM');

const isInvalidMonth = (value: string) => !/^\d{4}-\d{2}$/.test(value);

export const FinancesPage = () => {
  const restaurant = useRestaurant();
  const setError = useError();
  const [financialSummary, setFinancialSummary] =
    useState<FinancialSummaryForRestaurantAdmin>();
  const [errorMessage, setErrorMessage] = useState('');
  const [month, setMonth] = useState<string>(previousMonth());

  const clearError = () => setErrorMessage('');

  useEffect(() => {
    if (isInvalidMonth(month)) return;

    const fetch = async () => {
      try {
        const response: ApiResponse<FinancialSummaryForRestaurantAdmin> =
          await getFinancialSummary(restaurant.id, month);
        if (isErrorResponse(response)) {
          setErrorMessage(response.message);
        } else {
          setFinancialSummary(response);
          clearError();
        }
      } catch (e) {
        setError(e);
      }
    };
    fetch();
  }, [month, restaurant.id]);

  return (
    <>
      <PageHeader title={`${restaurant.name} Finance`} />
      <PageContent>
        <input
          aria-label="month"
          className={styles.datePicker}
          onChange={({ target: { value } }) => setMonth(value)}
          placeholder="YYYY-MM"
          type="month"
          value={month}
        />
        <Error className={styles.error} message={errorMessage} />
        {financialSummary && <FinanceCard financialData={financialSummary} />}
      </PageContent>
    </>
  );
};
