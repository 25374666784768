// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HookAwareTextareaAutosize__container___SPJ_J{display:flex;flex-direction:row;width:100%;justify-content:space-between;gap:2rem;align-items:flex-start}.HookAwareTextareaAutosize__container___SPJ_J label{margin-top:.5rem;width:min-content}.HookAwareTextareaAutosize__container___SPJ_J label.HookAwareTextareaAutosize__labelError___QnYFB{color:var(--pink)}.HookAwareTextareaAutosize__container___SPJ_J>div{flex:auto 2 1}.HookAwareTextareaAutosize__container___SPJ_J>div .HookAwareTextareaAutosize__textarea___OLs9V{background-color:var(--darkGrey100);border-radius:5px;border:3px solid var(--darkGrey100);color:var(--darkBlue);font-family:-apple-system,BlinkMacSystemFont,Inter,sans-serif;font-size:.875rem;font-weight:400;height:3rem;line-height:1rem;padding:1rem;width:100%}.HookAwareTextareaAutosize__container___SPJ_J>div .HookAwareTextareaAutosize__textarea___OLs9V:active,.HookAwareTextareaAutosize__container___SPJ_J>div .HookAwareTextareaAutosize__textarea___OLs9V:focus,.HookAwareTextareaAutosize__container___SPJ_J>div .HookAwareTextareaAutosize__textarea___OLs9V:hover{border:3px solid var(--white)}.HookAwareTextareaAutosize__container___SPJ_J>div .HookAwareTextareaAutosize__inputError___Fhw6e{background-color:var(--red100);color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/hookAwareTextareaAutosize/HookAwareTextareaAutosize.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,6BAAA,CACA,QAAA,CACA,sBAAA,CAEA,oDACE,gBAAA,CACA,iBAAA,CAEA,kGACE,iBAAA,CAIJ,kDACE,aAAA,CAEA,+FACE,mCAAA,CACA,iBAAA,CACA,mCAAA,CACA,qBAAA,CACA,6DAAA,CACA,iBAAA,CACA,eAAA,CACA,WAAA,CACA,gBAAA,CACA,YAAA,CACA,UAAA,CAEA,gTAGE,6BAAA,CAIJ,iGACE,8BAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HookAwareTextareaAutosize__container___SPJ_J",
	"labelError": "HookAwareTextareaAutosize__labelError___QnYFB",
	"textarea": "HookAwareTextareaAutosize__textarea___OLs9V",
	"inputError": "HookAwareTextareaAutosize__inputError___Fhw6e"
};
export default ___CSS_LOADER_EXPORT___;
