import { type DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import {
  ModalType,
  useModalContext,
} from 'restaurantAdmin/context/ModalContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  getWalkInAvailability,
  seatWaitListEntry,
  type WalkInAvailability,
} from 'restaurantAdmin/reservations/apiHelpers';
import { useWaitListContext } from '../sidePanel/waitList/state/WaitListContext';
import {
  type AvailableWalkInTimeDictionary,
  useReservationServiceContext,
} from '../state/ReservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface WaitListSeatModeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  refreshFloorPlan: () => void;
  exitSeatMode: () => void;
  tables: HostFloorPlanTable[];
  availableTimesByFloorPlanTableId: AvailableWalkInTimeDictionary;
}

export const WaitListSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  refreshFloorPlan,
  exitSeatMode,
  tables,
  availableTimesByFloorPlanTableId,
}: WaitListSeatModeFloorPlanProps) => {
  const restaurant = useRestaurant();
  const { closeSidePanelSheet } = useReservationServiceContext();
  const {
    selectedWaitListEntry,
    setSelectedWaitListEntryIndex,
    fetchWaitListEntries,
  } = useWaitListContext();
  const { openModal } = useModalContext();

  useDndMonitor({
    onDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      const floorPlanTable = over?.data.current
        ?.floorPlanTable as HostFloorPlanTable;

      if (
        over &&
        active &&
        over.data.current?.accepts.includes(active.data.current?.type)
      ) {
        const seatWaitListEntryOnDragEnd = () => onTableClick(floorPlanTable);
        void seatWaitListEntryOnDragEnd();
      }
    },
  });

  if (!selectedWaitListEntry) return null;

  const onSeatWaitList = async (
    availability: WalkInAvailability,
    floorPlanTable: HostFloorPlanTable,
  ): Promise<void> => {
    const response = await seatWaitListEntry({
      restaurantId: restaurant.id,
      floorPlanTableId: floorPlanTable.id,
      waitListEntryId: selectedWaitListEntry.id,
      hasAvailability: availability.hasAvailability,
      turnTime: availability.turnTime,
    });

    if (!response.ok) {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }

    closeSidePanelSheet();
    setSelectedWaitListEntryIndex(-1);
    exitSeatMode();
    refreshFloorPlan();
    void fetchWaitListEntries();
  };

  const onTableClick = async (floorPlanTable: HostFloorPlanTable) => {
    try {
      const response = await getWalkInAvailability({
        floorPlanTableId: floorPlanTable.id,
        guestCount: selectedWaitListEntry.guestCount,
        restaurantId: restaurant.id,
      });
      if (!response.hasAvailability) {
        openModal(ModalType.WalkInSeatingConflict, {
          handleConfirm: () => handleConfirm(response, floorPlanTable),
        });
        return;
      }

      if (response.turnTime !== null) {
        openModal(ModalType.WalkInTurnTimeInformation, {
          handleConfirm: () => handleConfirm(response, floorPlanTable),
          turnTime: response.turnTime,
        });
        return;
      }

      await onSeatWaitList(response, floorPlanTable);
    } catch (error) {
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
      reportAppError(error);
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          handleTableOnClick={() => {
            void onTableClick(floorPlanTable);
          }}
          key={floorPlanTable.id}
          availableTime={
            availableTimesByFloorPlanTableId[floorPlanTable.id] || null
          }
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );
  const handleConfirm = (
    walkInAvailability: WalkInAvailability,
    selectedFloorPlanTable: HostFloorPlanTable,
  ) => {
    if (selectedFloorPlanTable && walkInAvailability) {
      void onSeatWaitList(walkInAvailability, selectedFloorPlanTable);
    }
  };
  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
