import { PageContent } from '../layout/PageContent';
import { DraggableFloorPlanTable } from './DraggableFloorPlanTable';
import { DroppableFloorPlan } from './DroppableFloorPlan';
import styles from './FloorPlanEditor.scss';
import { FloorPlanEditorActions } from './FloorPlanEditorActions';
import { useFloorPlanEditorContext } from './FloorPlanEditorContext';
import { FloorPlanExportActions } from './FloorPlanExportActions';
import { SelectedFloorPlanTable } from './SelectedFloorPlanTable';

export const FloorPlanEditor = () => {
  const { dirtyAndNewFloorPlanTables, floorPlan, selectedFloorPlanTable } =
    useFloorPlanEditorContext();

  if (!floorPlan) return null;

  return (
    <PageContent className={styles.container}>
      <div>
        <FloorPlanEditorActions />
        <SelectedFloorPlanTable />
        <FloorPlanExportActions />
      </div>
      <div>
        <DroppableFloorPlan
          backgroundSrc={floorPlan.backgroundSrc!}
          floorPlanTablesRenderer={(scale: number) => [
            ...dirtyAndNewFloorPlanTables.map((table) => (
              <DraggableFloorPlanTable
                id={table.id}
                isHighlighted={table.id === selectedFloorPlanTable?.id}
                iconName={table.iconName}
                iconWidthScale={table.iconWidthScale}
                key={`${table.top},${table.left}`}
                left={table.left}
                name={table.name}
                orientation={table.orientation}
                tableIconScale={scale}
                top={table.top}
              />
            )),
          ]}
        />
      </div>
    </PageContent>
  );
};
