import { type ApiResponse } from '@shared/types/apiHelpers';
import API from '../../../../api/apiClient';

export interface CreateWaitListEntryParams {
  estimatedWait: number;
  firstName: string;
  guestCount: number;
  lastName: string;
  phone: string;
  restaurantId: string;
}

interface WaitListEntryEvent {
  type: string;
}

export interface WaitListEntry {
  estimatedWait: number;
  firstName: string;
  guestCount: number;
  id: string;
  lastName: string;
  phone: string;
  restaurantId: string;
  createdAt: string;
  events: WaitListEntryEvent[];
}

export const createWaitListEntry = async ({
  estimatedWait,
  firstName,
  guestCount,
  lastName,
  phone,
  restaurantId,
}: CreateWaitListEntryParams): Promise<Response> =>
  API.post(`/restaurants/${restaurantId}/wait-list-entries`, {
    estimatedWait,
    firstName,
    guestCount,
    lastName,
    phone,
  });

export const getWaitList = async ({
  restaurantId,
}: {
  restaurantId: string;
}): Promise<ApiResponse<WaitListEntry[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/wait-list-entries`,
  );

  return response.json();
};

export const sendTableReadyMessage = async (
  restaurantId: string,
  waitListEntryId: string,
): Promise<ApiResponse<object>> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/wait-list-entries/${waitListEntryId}/notify`,
  );

  if (!response.ok) {
    return response.json();
  }

  return {};
};

export const cancelWaitListEntry = async ({
  restaurantId,
  waitListEntryId,
}: {
  restaurantId: string;
  waitListEntryId: string;
}) => {
  await API.post(
    `/restaurants/${restaurantId}/wait-list-entries/${waitListEntryId}/cancel`,
  );
};
