import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Card } from '@components/card/Card';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import typography from '~styles/typography.scss';
import { AdminCalendar } from '../calendar/AdminCalendar';
import { InteractiveAdminFloorPlan } from '../floorPlan/InteractiveAdminFloorPlan';
import { useListingsContext } from '../ListingsContext';
import { ListingDetailsSheet } from './ListingDetailsSheet';
import { ListingsList } from './ListingsList';
import styles from './ListingsPage.scss';
import { ListingsPageHeader } from './ListingsPageHeader';
import {
  listingsPagePath,
  ListingsPageScope,
  ListingsPageSubView,
} from './listingsPagePath';

export const ListingsPage = () => {
  const { selectedListing, floorPlan, isLoading, subView } =
    useListingsContext();

  const tabClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tab]: true,
      [styles.activeTab]: isActive,
      [typography.t1]: true,
    });

  const isTabsVisible = !selectedListing;

  if (isLoading) return null;

  return (
    <>
      <ListingsPageHeader />
      <PageContent className={styles.content}>
        <Card
          className={cx(styles.displayPanel, {
            [styles.alignStart]: subView === ListingsPageSubView.FloorPlan,
          })}
        >
          {subView === ListingsPageSubView.FloorPlan ? (
            <InteractiveAdminFloorPlan
              backgroundSrc={floorPlan.backgroundSrc}
              tables={floorPlan.floorPlanTables}
            />
          ) : (
            <AdminCalendar
              className={styles.calendar}
              highlightedListing={selectedListing}
              interactive
            />
          )}
        </Card>
        <div className={styles.sidePanel}>
          <div
            className={styles.tabs}
            data-testid="tabs"
            style={{ display: isTabsVisible ? undefined : 'none' }}
          >
            <div className={styles.tabBar}>
              <NavLink
                className={tabClassNames}
                to={listingsPagePath({
                  scope: ListingsPageScope.Published,
                  subView,
                })}
              >
                Published
              </NavLink>
              <NavLink
                className={tabClassNames}
                to={listingsPagePath({
                  scope: ListingsPageScope.Draft,
                  subView,
                })}
              >
                Draft
              </NavLink>
              <NavLink
                className={tabClassNames}
                to={listingsPagePath({
                  scope: ListingsPageScope.Inactive,
                  subView,
                })}
              >
                Inactive
              </NavLink>
            </div>
            <ListingsList />
          </div>
          {selectedListing && <ListingDetailsSheet />}
        </div>
      </PageContent>
    </>
  );
};
