import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import cx from 'classnames';
import { get } from 'lodash-es';
import {
  type Control,
  type FieldErrors,
  type FieldPath,
  type FieldValues,
  useController,
  type UseControllerProps,
  useWatch,
} from 'react-hook-form';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './HookAwareTextareaAutosize.scss';

export interface HookAwareTextareaAutosizeProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  errors: FieldErrors;
  isDisabled?: boolean;
  label: string;
  name: Name;
  placeholder?: string;
  validations?: UseControllerProps<T, Name>['rules'];
}

export const HookAwareTextareaAutosize = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  errors,
  isDisabled = false,
  label,
  name,
  placeholder,
  validations,
}: HookAwareTextareaAutosizeProps<T, Name>) => {
  const { field } = useController({
    control,
    name,
    rules: validations,
  });
  const watch = useWatch({ control });
  const value = get(watch, name);
  const hasError = name in errors;

  return (
    <div className={cx(styles.container, className)}>
      <label
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: hasError,
        })}
        htmlFor={name}
      >
        {label}
      </label>
      <div>
        <TextareaAutosize
          aria-errormessage={`${name}-error`}
          aria-invalid={hasError}
          className={cx({
            [styles.textarea]: true,
            [styles.inputError]: hasError,
          })}
          disabled={isDisabled}
          id={name}
          maxRows={14}
          onChange={field.onChange}
          placeholder={placeholder}
          value={value}
        />
        <ValidationErrorMessage errors={errors} label={label} name={name} />
      </div>
    </div>
  );
};
