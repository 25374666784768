// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayIconFieldSet__fieldset___P8Q9w{position:relative;display:flex;flex-direction:column;width:100%;background-color:var(--panelBlue);padding:12px;row-gap:1rem;border-radius:8px}.DisplayIconFieldSet__fieldset___P8Q9w h3{text-transform:uppercase;letter-spacing:.0625rem;line-height:1rem;color:var(--white)}.DisplayIconFieldSet__displayIconEmptyState___nfqBt{border-radius:.25rem;border:1px solid var(--hairline);line-height:1.25rem;padding:1rem;color:var(--white70)}.DisplayIconFieldSet__displayIconButtons___HrnbX{align-items:center;display:flex;flex-flow:row wrap;gap:.5rem;justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/DisplayIconFieldSet.scss"],"names":[],"mappings":"AAAA,uCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,iCAAA,CACA,YAAA,CACA,YAAA,CACA,iBAAA,CAEA,0CACE,wBAAA,CACA,uBAAA,CACA,gBAAA,CACA,kBAAA,CAIJ,oDACE,oBAAA,CACA,gCAAA,CACA,mBAAA,CACA,YAAA,CACA,oBAAA,CAGF,iDACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": "DisplayIconFieldSet__fieldset___P8Q9w",
	"displayIconEmptyState": "DisplayIconFieldSet__displayIconEmptyState___nfqBt",
	"displayIconButtons": "DisplayIconFieldSet__displayIconButtons___HrnbX"
};
export default ___CSS_LOADER_EXPORT___;
