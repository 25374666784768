// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeatedFloorPlanTable__container___cGg6q{display:flex;position:absolute;padding:0;border:none}.SeatedFloorPlanTable__container___cGg6q .SeatedFloorPlanTable__button___hZRKm{background-color:rgba(0,0,0,0);padding:0;border:none}.SeatedFloorPlanTable__container___cGg6q .SeatedFloorPlanTable__serviceStatusIcon___AOt7T{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:1}.SeatedFloorPlanTable__container___cGg6q .SeatedFloorPlanTable__serviceStatusIcon___AOt7T.SeatedFloorPlanTable__isHighlighted___uWbF9{border:2px solid var(--white);border-radius:50%}.SeatedFloorPlanTable__container___cGg6q .SeatedFloorPlanTable__serviceStatusIcon___AOt7T.SeatedFloorPlanTable__isDimmed___jWRrt{opacity:.75}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/SeatedFloorPlanTable.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,+EACE,8BAAA,CACA,SAAA,CACA,WAAA,CAGF,0FACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,SAAA,CAEA,sIACE,6BAAA,CACA,iBAAA,CAGF,iIACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SeatedFloorPlanTable__container___cGg6q",
	"button": "SeatedFloorPlanTable__button___hZRKm",
	"serviceStatusIcon": "SeatedFloorPlanTable__serviceStatusIcon___AOt7T",
	"isHighlighted": "SeatedFloorPlanTable__isHighlighted___uWbF9",
	"isDimmed": "SeatedFloorPlanTable__isDimmed___jWRrt"
};
export default ___CSS_LOADER_EXPORT___;
