import type { AnyIcon } from '@components/icon/Icon';
import { api } from '../api';

interface NewFloorPlanTable {
  iconName: AnyIcon;
  left: string;
  name: string;
  orientation: string;
  top: string;
}

interface UpdatedFloorPlanTable {
  id: string;
  iconName: AnyIcon;
  left: string;
  name: string;
  orientation: string;
  top: string;
}

export interface SaveEditPayload {
  createdFloorPlanTables: NewFloorPlanTable[];
  updatedFloorPlanTables: UpdatedFloorPlanTable[];
  deletedFloorPlanTableIds: string[];
}

export interface ImportedGuest {
  firstName: string;
  lastName: string;
  phone: string;
  notes: string | null;
  tags: string[] | null;
}

export const importGuests = async (
  restaurantId: string,
  importedGuest: ImportedGuest[],
): Promise<void> => {
  await api.post(`/restaurants/${restaurantId}/guest-imports`, importedGuest);
};

export const saveEdit = async (
  restaurantId: string,
  floorPlanId: string,
  payload: SaveEditPayload,
): Promise<void> => {
  await api.post(
    `/restaurants/${restaurantId}/floor-plans/${floorPlanId}/floor-plan-tables`,
    payload,
  );
};
