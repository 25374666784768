import API from '@shared/api/apiClient';

export interface MergingTable {
  primaryMergeTableId: string;
  secondaryMergeTableIds: string[];
}

export const mergeTables = (
  restaurantId: string,
  mergingTables: MergingTable[],
  unmergingTableIds: string[],
): Promise<Response> =>
  API.put(`/restaurants/${restaurantId}/merge-tables`, {
    mergingTables,
    unmergingTableIds,
  });
