// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConciergePage__pageContent___Upimt{display:flex;flex-direction:column;position:relative;min-height:0}.ConciergePage__filters___F6lOR{display:flex;flex-direction:row;gap:1rem}.ConciergePage__content___Jymhp{align-items:center;column-gap:3rem;display:flex;flex-direction:column;width:100%;min-height:0}@media only screen and (min-width: 768px){.ConciergePage__content___Jymhp{align-items:normal;flex-direction:row}}.ConciergePage__floorPlanContainer___NzrT_{background-color:var(--primary400);display:flex;justify-content:center;margin-top:0;max-width:var(--maxFloorPlanDesktopWidth);padding:1rem;top:var(--headerHeight)}@media only screen and (min-width: 768px){.ConciergePage__floorPlanContainer___NzrT_{background-color:unset;display:inline;margin-top:1rem;min-width:var(--minFloorPlanTabletWidth);padding:0;width:53vw}}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/concierge/ConciergePage.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,YAAA,CAGF,gCACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,gCACE,kBAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,YAAA,CCWA,0CDjBF,gCASI,kBAAA,CACA,kBAAA,CAAA,CAIJ,2CACE,kCAAA,CACA,YAAA,CACA,sBAAA,CACA,YAAA,CACA,yCAAA,CACA,YAAA,CACA,uBAAA,CCJA,0CDHF,2CAUI,sBAAA,CACA,cAAA,CACA,eAAA,CACA,wCAAA,CACA,SAAA,CACA,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "ConciergePage__pageContent___Upimt",
	"filters": "ConciergePage__filters___F6lOR",
	"content": "ConciergePage__content___Jymhp",
	"floorPlanContainer": "ConciergePage__floorPlanContainer___NzrT_"
};
export default ___CSS_LOADER_EXPORT___;
