// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateListingModalContainer__modalContent___vwRAK{text-align:center}.CreateListingModalContainer__modalContent___vwRAK .CreateListingModalContainer__message___qlTQf{color:var(--white70);max-width:30em}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/create/CreateListingModalContainer.scss"],"names":[],"mappings":"AAAA,mDACE,iBAAA,CAEA,iGACE,oBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "CreateListingModalContainer__modalContent___vwRAK",
	"message": "CreateListingModalContainer__message___qlTQf"
};
export default ___CSS_LOADER_EXPORT___;
