// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeatingTypeFieldSet__fieldset___Q7H4P{position:relative;display:flex;flex-direction:column;width:100%;background-color:var(--panelBlue);padding:12px;row-gap:1rem;border-radius:8px}.SeatingTypeFieldSet__fieldset___Q7H4P h3{text-transform:uppercase;letter-spacing:.0625rem;line-height:1rem;color:var(--white)}.SeatingTypeFieldSet__fieldset___Q7H4P p{color:var(--white70);margin:0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/SeatingTypeFieldSet.scss"],"names":[],"mappings":"AAAA,uCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,iCAAA,CACA,YAAA,CACA,YAAA,CACA,iBAAA,CAEA,0CACE,wBAAA,CACA,uBAAA,CACA,gBAAA,CACA,kBAAA,CAGF,yCACE,oBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": "SeatingTypeFieldSet__fieldset___Q7H4P"
};
export default ___CSS_LOADER_EXPORT___;
