import {
  type Control,
  type UseFormGetValues,
  type UseFormHandleSubmit,
  type UseFormSetValue,
} from 'react-hook-form';
import { Card } from '@components/card/Card';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import typography from '~styles/typography.scss';
import { BasePriceField } from '../form/BasePriceField';
import pageLayoutStyles from '../listingsPage/CreateEditListingPageLayout.scss';
import type {
  ListingPricingFormData,
  ListingReservableDaysFormData,
} from '../types';
import { PricePointsList } from './PricePointsList';

interface EditListingPricingPageBodyProps {
  listingName: string;
  pricingControl: Control<ListingPricingFormData>;
  pricingFormGetValues: UseFormGetValues<ListingPricingFormData>;
  pricingFormHandleSubmit: UseFormHandleSubmit<ListingPricingFormData>;
  pricingFormSetValue: UseFormSetValue<ListingPricingFormData>;
  reservableDaysFormValues: Pick<
    ListingReservableDaysFormData,
    'endTime' | 'repeat' | 'startTime'
  >;
}

export const EditListingPricingPageBody = ({
  listingName,
  pricingControl,
  pricingFormGetValues,
  pricingFormHandleSubmit,
  pricingFormSetValue,
  reservableDaysFormValues,
}: EditListingPricingPageBodyProps) => (
  <PageContent className={pageLayoutStyles.main}>
    <div className={pageLayoutStyles.displayPanel} />
    <div className={pageLayoutStyles.sidePanelContainer}>
      <Card bordered className={pageLayoutStyles.sidePanel}>
        <h2 className={typography.h7}>Edit Weekly Price Points</h2>
        <h3 className={typography.h4}>{listingName}</h3>
        <BasePriceField
          pricingControl={pricingControl}
          pricingFormHandleSubmit={pricingFormHandleSubmit}
          pricingFormSetValue={pricingFormSetValue}
        />
        <PricePointsList
          pricingControl={pricingControl}
          pricingFormGetValues={pricingFormGetValues}
          pricingFormHandleSubmit={pricingFormHandleSubmit}
          pricingFormSetValue={pricingFormSetValue}
          reservableDaysFormValues={reservableDaysFormValues}
        />
      </Card>
    </div>
  </PageContent>
);
