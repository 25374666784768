import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import {
  type FloorPlanEditorTable,
  useFloorPlanEditorContext,
} from './FloorPlanEditorContext';
import styles from './FloorPlanExportActions.scss';

const DataExport = ({ jsData }: { jsData: FloorPlanEditorTable[] }) => {
  const space = 2;
  const jsonData = JSON.stringify(jsData, null, space);

  const handleCopyClick = (): void => {
    void (async () => {
      try {
        await navigator.clipboard.writeText(jsonData);

        successToast({
          message: 'Copied to clipboard',
        });
      } catch (_error) {
        errorToast({
          message: 'Failed to copy to clipboard',
        });
      }
    })();
  };

  return (
    <div>
      <textarea readOnly value={jsonData} />
      <IconButton
        ariaLabel="Copy to clipboard"
        iconName="copy"
        onClick={handleCopyClick}
      />
    </div>
  );
};

export const FloorPlanExportActions = () => {
  const [showJSON, setShowJSON] = useState(false);
  const { dirtyAndNewFloorPlanTables } = useFloorPlanEditorContext();

  return (
    <div className={styles.container}>
      <Button
        isDisabled={!dirtyAndNewFloorPlanTables.length}
        label={showJSON ? 'Hide JSON' : 'Export tables as JSON'}
        variant={ButtonVariants.Tertiary}
        onClick={() => setShowJSON(!showJSON)}
      />
      {showJSON && <DataExport jsData={dirtyAndNewFloorPlanTables} />}
    </div>
  );
};
