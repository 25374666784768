// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalkInSheet__container___Is0z4{align-items:start;display:flex;flex-direction:row;gap:1rem}.WalkInSheet__container___Is0z4 .WalkInSheet__icon___eACvO{align-items:center;background-color:var(--darkGrey300);border-radius:50%;display:flex;height:2.5rem;justify-content:center;min-width:2.5rem;width:2.5rem}.WalkInSheet__container___Is0z4>div:last-child{width:100%}.WalkInSheet__container___Is0z4 p{margin:0}.WalkInSheet__container___Is0z4 .WalkInSheet__heading___L9RTE{align-items:center;display:flex;justify-content:space-between;width:100%}.WalkInSheet__container___Is0z4 .WalkInSheet__heading___L9RTE h2{max-width:13rem}.WalkInSheet__container___Is0z4 .WalkInSheet__heading___L9RTE .WalkInSheet__status___XTMol{text-transform:capitalize}.WalkInSheet__container___Is0z4 .WalkInSheet__subheading___vH5Fh{align-items:center;color:var(--white70);display:flex;justify-content:space-between;text-transform:lowercase;width:100%}.WalkInSheet__container___Is0z4 .WalkInSheet__seatedTableName___aVAha{color:var(--white70);letter-spacing:.02em;line-height:1.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/sidePanel/WalkInSheet.scss"],"names":[],"mappings":"AAAA,gCACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,2DACE,kBAAA,CACA,mCAAA,CACA,iBAAA,CACA,YAAA,CACA,aAAA,CACA,sBAAA,CACA,gBAAA,CACA,YAAA,CAGF,+CACE,UAAA,CAGF,kCACE,QAAA,CAGF,8DACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,UAAA,CAEA,iEACE,eAAA,CAGF,2FACE,yBAAA,CAIJ,iEACE,kBAAA,CACA,oBAAA,CACA,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,UAAA,CAGF,sEACE,oBAAA,CACA,oBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WalkInSheet__container___Is0z4",
	"icon": "WalkInSheet__icon___eACvO",
	"heading": "WalkInSheet__heading___L9RTE",
	"status": "WalkInSheet__status___XTMol",
	"subheading": "WalkInSheet__subheading___vH5Fh",
	"seatedTableName": "WalkInSheet__seatedTableName___aVAha"
};
export default ___CSS_LOADER_EXPORT___;
