// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LabelsFieldSet__fieldset___OV9Ej{position:relative;display:flex;flex-direction:column;width:100%;background-color:var(--panelBlue);padding:12px;row-gap:1rem;border-radius:8px}.LabelsFieldSet__fieldset___OV9Ej h3{text-transform:uppercase;letter-spacing:.0625rem;line-height:1rem;color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/LabelsFieldSet.scss"],"names":[],"mappings":"AAAA,kCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,iCAAA,CACA,YAAA,CACA,YAAA,CACA,iBAAA,CAEA,qCACE,wBAAA,CACA,uBAAA,CACA,gBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": "LabelsFieldSet__fieldset___OV9Ej"
};
export default ___CSS_LOADER_EXPORT___;
