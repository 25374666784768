import cx from 'classnames';
import AccentTable from '@assets/icons/accent-table.svg';
import typography from '~styles/typography.scss';
import styles from './NoTables.scss';

export const NoTables = () => (
  <div className={styles.container}>
    <AccentTable role="presentation" />
    <h2 className={cx(typography.headingOne, styles.noTablesHeader)}>
      No Tables Available Today
    </h2>
    <p className={styles.noTablesMessage}>Adjust your search and try again</p>
  </div>
);
