import cx from 'classnames';
import { toMonthDateAndYear } from '@utils/date';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { isReservation } from '../apiHelpers';
import { useOccupantContext } from '../state/OccupantContext';
import styles from './OccupantList.scss';
import { groupOccupantsByFifteenMinuteIncrement } from './occupantUtils';
import { ReservationCard } from './ReservationCard';
import { WalkInCard } from './WalkInCard';

export const OccupantList = () => {
  const {
    handleOnSelectOccupant,
    reservationDate,
    occupants,
    selectedOccupant,
  } = useOccupantContext();

  const occupantsGroupedByTime =
    groupOccupantsByFifteenMinuteIncrement(occupants);

  if (!occupants.length) {
    return (
      <span className={styles.noContent}>
        There are no reservations to display for{' '}
        {toMonthDateAndYear(reservationDate)}
      </span>
    );
  }

  return (
    <ul
      aria-label="all occupants for the selected date"
      className={styles.timeList}
    >
      {Object.keys(occupantsGroupedByTime).map((time) => {
        const timeGroupId = `time-group-${time}`;

        return (
          <li aria-labelledby={timeGroupId} key={`time-divider-${time}`}>
            <h2
              className={cx(styles.timeDivider, typography.h6)}
              id={timeGroupId}
            >
              {ISOTimeTo12HourTime(time)}
            </h2>
            <ul
              aria-label={`occupants starting at ${ISOTimeTo12HourTime(time)}`}
              className={styles.cardList}
            >
              {occupantsGroupedByTime[time].map((occupant) => {
                const isSelected = Boolean(
                  selectedOccupant?.id === occupant.id,
                );

                if (isReservation(occupant)) {
                  return (
                    <ReservationCard
                      isSelected={isSelected}
                      key={occupant.id}
                      onClick={() => {
                        handleOnSelectOccupant(occupant.id);
                      }}
                      reservation={occupant}
                    />
                  );
                }

                return (
                  <WalkInCard
                    key={occupant.id}
                    onClick={() => {
                      handleOnSelectOccupant(occupant.id);
                    }}
                    walkIn={occupant}
                  />
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
