import { max } from 'lodash-es';
import {
  type BaseSyntheticEvent,
  createContext,
  type FormEventHandler,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  type Control,
  useForm,
  type UseFormGetValues,
  type UseFormHandleSubmit,
  type UseFormSetValue,
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { useFeatureFlagContext } from '@shared/context/FeatureFlagContext';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { CENTS_IN_DOLLAR } from '@shared/utils/currency';
import { todayInTimezone } from '@utils/date';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH,
  OPERATIONS_LISTINGS_CREATE_PRICING_PATH,
  OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH,
} from 'restaurantAdmin/paths';
import { createListing } from '../apiHelpers';
import { ListingFormSteps } from '../flowStepper/ListingFormSteps';
import { useListingsContext } from '../ListingsContext';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';
import {
  type ListingFloorPlanFormData,
  type ListingPricingFormData,
  type ListingReservableDaysFormData,
} from '../types';
import {
  listingFloorPlanFormDataFromListing,
  type ListingLocationState,
  listingReservableDaysFormDataFromListing,
} from '../utils/listingUtils';
import { buildCreateListingPayload, FormNames } from './createListingUtils';

export interface ModalState {
  currentModal: 'error' | 'warning' | 'incomplete' | null;

  isSavingDraft?: boolean;

  message?: string;
}

export interface CreateListingContextState {
  clearModalState: () => void;
  floorPlanControl: Control<ListingFloorPlanFormData>;
  floorPlanFormData?: ListingFloorPlanFormData;
  handleOnClickConfigureFloorPlan: FormEventHandler<HTMLFormElement>;
  handleOnClickPublish: () => Promise<void>;
  handleOnClickSaveAsDraft: () => Promise<void>;
  handleOnConfirmSubmit: () => void;
  handleOnSubmitFloorPlan: FormEventHandler<HTMLFormElement>;
  handleOnSubmitPricing: FormEventHandler<HTMLFormElement>;
  handleOnSubmitReservableDays: FormEventHandler<HTMLFormElement>;
  modalState: ModalState;
  pricingControl: Control<ListingPricingFormData>;
  pricingFormSetValue: UseFormSetValue<ListingPricingFormData>;
  reservableDaysControl: Control<ListingReservableDaysFormData>;
  reservableDaysFormData?: ListingReservableDaysFormData;
  reservableDaysFormHandleSubmit: UseFormHandleSubmit<
    ListingReservableDaysFormData,
    undefined
  >;
  setFloorPlanFormData: (data?: ListingFloorPlanFormData) => void;
  setReservableDaysFormData: (data?: ListingReservableDaysFormData) => void;
  pricingFormGetValues: UseFormGetValues<ListingPricingFormData>;
  reservableDaysFormGetValues: UseFormGetValues<ListingReservableDaysFormData>;
  pricingFormHandleSubmit: UseFormHandleSubmit<ListingPricingFormData>;
}

export const CreateListingContext = createContext<CreateListingContextState>(
  {} as CreateListingContextState,
);
CreateListingContext.displayName = 'CreateListingContext';

export const useCreateListingContext = (): CreateListingContextState =>
  useContext(CreateListingContext);

export const CreateListingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const { duplicateListingId } = (location.state as ListingLocationState) || {};
  const navigate = useNavigate();
  const { isEnabled } = useFeatureFlagContext();
  const { id: restaurantId, timezone } = useRestaurant();
  const {
    floorPlan,
    refreshFloorPlan,
    refreshListings,
    scopedListings,
    setSelectedDate,
    setSelectedListingId,
    subView,
  } = useListingsContext();
  const [floorPlanFormData, setFloorPlanFormData] = useState<
    ListingFloorPlanFormData | undefined
  >();
  const [reservableDaysFormData, setReservableDaysFormData] = useState<
    ListingReservableDaysFormData | undefined
  >();

  const listingToDuplicate = scopedListings.find(
    (listing) => listing.id === duplicateListingId,
  );

  const floorPlanForm = useForm<ListingFloorPlanFormData>({
    defaultValues: listingToDuplicate
      ? {
          ...listingFloorPlanFormDataFromListing(listingToDuplicate, floorPlan),
          name: `${listingToDuplicate.name} - Copy`,
        }
      : { highlightedTables: [], isCommunal: false },
  });

  const reservableDaysForm = useForm<ListingReservableDaysFormData>({
    defaultValues: listingToDuplicate
      ? listingReservableDaysFormDataFromListing(listingToDuplicate)
      : { endDate: '', repeat: [], startTime: [], endTime: [] },
  });

  const pricingForm = useForm<ListingPricingFormData>({
    defaultValues: listingToDuplicate
      ? { price: `${listingToDuplicate.price / CENTS_IN_DOLLAR}` }
      : {
          price: '0',
        },
    reValidateMode: 'onSubmit',
  });

  const [modalState, setModalState] = useState<ModalState>({} as ModalState);

  const attemptListingCreate = async ({
    isSavingDraft,
    ignoreWarnings,
  }: {
    isSavingDraft: boolean;
    ignoreWarnings: boolean;
  }) => {
    const floorPlanFormValues = floorPlanForm.getValues();
    const reservableDaysFormValues = reservableDaysForm.getValues();
    const pricingFormValues = pricingForm.getValues();

    const response = await createListing(
      restaurantId,
      buildCreateListingPayload({
        reservableDaysFormValues,
        floorPlanFormValues,
        ignoreWarnings,
        ...(isEnabled('pricingManagerFlag') && { pricingFormValues }),
      }),
      isSavingDraft,
    );

    if (isSuccessResponse(response)) {
      if ('warnings' in response) {
        setModalState({
          currentModal: 'warning',
          isSavingDraft,
          message: response.warnings[0],
        });
      } else {
        refreshListings();
        refreshFloorPlan();
        successToast({
          message: `Listing successfully ${isSavingDraft ? 'saved as draft' : 'published'}`,
        });
        setSelectedDate(
          max([reservableDaysFormValues.startDate, todayInTimezone(timezone)])!,
        );
        setSelectedListingId(response.createdListingId);
        navigate(
          listingsPagePath({
            scope: isSavingDraft
              ? ListingsPageScope.Draft
              : ListingsPageScope.Published,
            subView,
          }),
        );
      }
    } else {
      setModalState({
        currentModal: 'error',
        isSavingDraft,
        message: response.message,
      });
    }
  };

  const handleOnClickSaveAsDraft = async () => {
    if (!floorPlanFormData) {
      setModalState({
        currentModal: 'incomplete',
        isSavingDraft: true,
        message: FormNames.FloorPlan,
      });
      return;
    }

    await attemptListingCreate({
      isSavingDraft: true,
      ignoreWarnings: false,
    });
  };

  const handleOnClickPublish = async () => {
    if (!floorPlanFormData) {
      setModalState({
        currentModal: 'incomplete',
        isSavingDraft: false,
        message: FormNames.FloorPlan,
      });
      return;
    }

    await attemptListingCreate({
      isSavingDraft: false,
      ignoreWarnings: false,
    });
  };

  const handleOnConfirmSubmit = async () => {
    await attemptListingCreate({
      isSavingDraft: modalState.isSavingDraft!,
      ignoreWarnings: true,
    });
  };

  const handleOnClickConfigureFloorPlan = () => {
    setReservableDaysFormData(reservableDaysForm.getValues());

    navigate(OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH, {
      state: {
        duplicateListingId,
      } as ListingLocationState,
    });
  };

  const handleOnSubmitFloorPlan = (
    data: ListingFloorPlanFormData,
    e: BaseSyntheticEvent<any> | undefined,
  ) => {
    setFloorPlanFormData(data);
    const submitButton = (e?.nativeEvent as SubmitEvent)?.submitter as
      | HTMLButtonElement
      | undefined;

    let path: string;

    switch (submitButton?.name) {
      case ListingFormSteps.NextStep:
      case ListingFormSteps.ReservableDays:
        path = OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH;
        break;
      case ListingFormSteps.Pricing:
        path = OPERATIONS_LISTINGS_CREATE_PRICING_PATH;
        break;
      default:
        path = OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH;
    }

    navigate(path, {
      state: {
        duplicateListingId,
      } as ListingLocationState,
    });
  };

  const handleOnSubmitReservableDays = (
    data: ListingReservableDaysFormData,
    e: BaseSyntheticEvent<any> | undefined,
  ) => {
    setReservableDaysFormData(data);
    const submitButton = (e?.nativeEvent as SubmitEvent)?.submitter as
      | HTMLButtonElement
      | undefined;

    let path: string;

    switch (submitButton?.name) {
      case ListingFormSteps.NextStep:
      case ListingFormSteps.Pricing:
        path = OPERATIONS_LISTINGS_CREATE_PRICING_PATH;
        break;
      case ListingFormSteps.PreviousStep:
      case ListingFormSteps.FloorPlan:
        path = OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH;
        break;
      default:
        path = OPERATIONS_LISTINGS_CREATE_PRICING_PATH;
    }

    navigate(path, {
      state: {
        duplicateListingId,
      } as ListingLocationState,
    });
  };

  const handleOnSubmitPricing = (
    _data: ListingPricingFormData,
    e: BaseSyntheticEvent<any> | undefined,
  ) => {
    const submitButton = (e?.nativeEvent as SubmitEvent)?.submitter as
      | HTMLButtonElement
      | undefined;

    let path: string;

    switch (submitButton?.name) {
      case ListingFormSteps.PreviousStep:
      case ListingFormSteps.ReservableDays:
        path = OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH;
        break;
      case ListingFormSteps.FloorPlan:
        path = OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH;
        break;
      default:
        path = OPERATIONS_LISTINGS_CREATE_PRICING_PATH;
    }

    navigate(path, {
      state: {
        duplicateListingId,
      } as ListingLocationState,
    });
  };

  const clearModalState = () => {
    setModalState({ currentModal: null });
  };

  const value = useMemo(
    (): CreateListingContextState => ({
      clearModalState,
      floorPlanControl: floorPlanForm.control,
      floorPlanFormData,
      handleOnClickConfigureFloorPlan: reservableDaysForm.handleSubmit(
        handleOnClickConfigureFloorPlan,
      ),
      handleOnClickPublish,
      handleOnClickSaveAsDraft,
      handleOnConfirmSubmit,
      handleOnSubmitFloorPlan: floorPlanForm.handleSubmit(
        handleOnSubmitFloorPlan,
      ),
      handleOnSubmitPricing: pricingForm.handleSubmit(handleOnSubmitPricing),
      handleOnSubmitReservableDays: reservableDaysForm.handleSubmit(
        handleOnSubmitReservableDays,
      ),
      modalState,
      pricingControl: pricingForm.control,
      pricingFormHandleSubmit: pricingForm.handleSubmit,
      pricingFormGetValues: pricingForm.getValues,
      reservableDaysFormGetValues: reservableDaysForm.getValues,
      pricingFormSetValue: pricingForm.setValue,
      reservableDaysControl: reservableDaysForm.control,
      reservableDaysFormData,
      reservableDaysFormHandleSubmit: reservableDaysForm.handleSubmit,
      setFloorPlanFormData,
      setReservableDaysFormData,
    }),
    [
      floorPlanFormData,
      reservableDaysFormData,
      modalState,
      floorPlanForm.control,
      reservableDaysForm.control,
    ],
  );

  return (
    <CreateListingContext.Provider value={value}>
      {children}
    </CreateListingContext.Provider>
  );
};
