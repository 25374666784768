import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { toShortWeekDateFormat } from '@utils/date';
import { appendGuestCountLabel } from '@utils/guestCount';
import styles from './DateAndGuestCountFilterPill.scss';
import { FilterPopover } from './FilterPopover';

export interface DateAndGuestCountFilterPillProps {
  closeFilter: () => void;
  isOpenFilter: boolean;
  onFilterApply: () => void;
  openFilter: () => void;
  selectedDate: Date;
  selectedGuestCount: number;
  setSelectedDate: (date: Date) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  minimumDate?: Date;
}

export const DateAndGuestCountFilterPill = ({
  closeFilter,
  isOpenFilter,
  onFilterApply,
  openFilter,
  selectedDate,
  selectedGuestCount,
  setSelectedDate,
  setSelectedGuestCount,
  minimumDate,
}: DateAndGuestCountFilterPillProps) => {
  const togglePopoverVisibility = () => {
    if (isOpenFilter) {
      closeFilter();
    } else {
      openFilter();
    }
  };

  const { ref } = useHandleClickOutside(closeFilter);

  const handleApply = (appliedDate: Date, appliedGuestCount: number) => {
    setSelectedDate(appliedDate);
    setSelectedGuestCount(appliedGuestCount);
    if (onFilterApply) onFilterApply();
    closeFilter();
  };

  const pillClassNames = cx(styles.pill, {
    [styles.isOpen]: isOpenFilter,
  });

  const ariaLabel = `${appendGuestCountLabel(
    selectedGuestCount,
  )}, ${toShortWeekDateFormat(selectedDate)}. Filter by date and guest count`;

  return (
    <div ref={ref}>
      <button
        aria-label={ariaLabel}
        className={pillClassNames}
        onClick={togglePopoverVisibility}
      >
        <Icon className={styles.icon} name="couple" /> {selectedGuestCount}
        <span className={styles.verticalDivider} />
        <Icon className={styles.icon} name="calendar" />
        {toShortWeekDateFormat(selectedDate)}
      </button>
      {isOpenFilter && (
        <FilterPopover
          onApply={handleApply}
          selectedDate={selectedDate}
          selectedGuestCount={selectedGuestCount}
          minimumDate={minimumDate}
        />
      )}
    </div>
  );
};
