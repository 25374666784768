// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PricePointsList__addButton___ZMUBF{border:1px dashed var(--white30);border-radius:6px;background-color:rgba(0,0,0,0);display:flex;flex-direction:row;justify-content:space-between;align-items:center;padding:16px 24px;color:var(--white30);font-size:14px;font-weight:700;line-height:1.1429;width:100%;cursor:pointer}.PricePointsList__addButton___ZMUBF:hover:enabled{border:1px solid var(--darkGrey100)}.PricePointsList__addButton___ZMUBF svg{width:1.5rem;stroke:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/edit/PricePointsList.scss"],"names":[],"mappings":"AAAA,oCACE,gCAAA,CACA,iBAAA,CACA,8BAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAEA,kDACE,mCAAA,CAGF,wCACE,YAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "PricePointsList__addButton___ZMUBF"
};
export default ___CSS_LOADER_EXPORT___;
