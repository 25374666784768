import cx from 'classnames';
import { type Control, useController } from 'react-hook-form';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { interactiveFloorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import {
  type FloorPlanData,
  type FloorPlanTableData,
} from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import { type ListingFloorPlanFormData } from '../types';
import styles from './FloorPlanFieldset.scss';

export interface FloorPlanFieldsetProps {
  control: Control<ListingFloorPlanFormData>;
  floorPlan: FloorPlanData;
}
export const FloorPlanFieldset = ({
  control,
  floorPlan,
}: FloorPlanFieldsetProps) => {
  // hooks
  const {
    field: {
      onChange: handleOnChangeHighlightedTables,
      value: highlightedTables,
    },
    fieldState: { error },
  } = useController({
    control,
    name: 'highlightedTables',
    rules: {
      required: true,
    },
  });

  // handlers
  const handleFloorPlanTableOnClick = (floorPlanTable: FloorPlanTableData) => {
    const indexOfHighlightedFloorPlanTable = highlightedTables.findIndex(
      (highlightedTable) => highlightedTable.id === floorPlanTable.id,
    );
    const isHighlighted = indexOfHighlightedFloorPlanTable !== -1;

    const newHighlightedTables = [...(highlightedTables || [])];
    if (isHighlighted) {
      newHighlightedTables.splice(indexOfHighlightedFloorPlanTable, 1);
    } else {
      newHighlightedTables.push(floorPlanTable);
    }

    handleOnChangeHighlightedTables(newHighlightedTables);
  };

  // derived state
  const calculateIsHighlighted = (floorPlanTable: FloorPlanTableData) =>
    highlightedTables?.some(
      (highlightedTable: FloorPlanTableData) =>
        highlightedTable.id === floorPlanTable.id,
    );
  const floorPlanSelectionIsInvalid = !!error && !highlightedTables.length;

  return (
    <fieldset
      className={styles.floorPlanFieldset}
      data-testid="floor-plan-fieldset"
    >
      <legend className={styles.floorPlanLegend}>
        <p
          className={cx({
            [typography.c2]: true,
            [styles.floorPlanInstructions]: true,
            [styles.error]: !!error,
          })}
        >
          Select at least one table to be on the listing
        </p>
      </legend>
      <FloorPlan
        backgroundSrc={floorPlan?.backgroundSrc}
        floorPlanTablesRenderer={interactiveFloorPlanTablesRendererFactory({
          calculateIsHighlighted,
          errorState: floorPlanSelectionIsInvalid,
          handleTableOnClick: handleFloorPlanTableOnClick,
          tables: floorPlan?.floorPlanTables || [],
        })}
      />
      <button
        className={styles.clearSelectionButton}
        onClick={() => handleOnChangeHighlightedTables([])}
        type="button"
      >
        <span role="status">
          {highlightedTables.length}
          {highlightedTables.length === 1 ? ' table ' : ' tables '}
          selected
        </span>
        &nbsp;
        {highlightedTables.length ? (
          <span className={cx(typography.c2, styles.unselectText)}>
            Unselect all
          </span>
        ) : null}
      </button>
    </fieldset>
  );
};
