import cx from 'classnames';
import { useState } from 'react';
import {
  type Control,
  type UseFormHandleSubmit,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { IconButton } from '@components/iconButton/IconButton';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import { type ListingPricingFormData } from '../types';
import styles from './BasePriceField.scss';

interface BasePriceFieldProps {
  pricingControl: Control<ListingPricingFormData>;
  pricingFormHandleSubmit: UseFormHandleSubmit<ListingPricingFormData>;
  pricingFormSetValue: UseFormSetValue<ListingPricingFormData>;
}

export const BasePriceField = ({
  pricingControl,
  pricingFormHandleSubmit,
  pricingFormSetValue,
}: BasePriceFieldProps) => {
  const { isOpen: isEditing, close, open } = useIsOpen();
  const formBasePrice = useWatch({
    control: pricingControl,
    name: 'price',
  });

  const [currentBasePrice, setCurrentBasePrice] =
    useState<string>(formBasePrice);

  const handleOnClickOkay = () => {
    setCurrentBasePrice(formBasePrice);
    close();
  };

  const handleOnClickCancel = () => {
    pricingFormSetValue('price', currentBasePrice);
    close();
  };

  return isEditing ? (
    <fieldset className={styles.editContainer}>
      <ControlledFormCurrencyInput
        control={pricingControl}
        label="Base Price"
        name="price"
        rules={{
          min: {
            message: 'Base listing price cannot be less than $0',
            value: 0,
          },
          max: {
            message: 'Base listing price cannot exceed $999999',
            value: 999999,
          },
          required: true,
        }}
      />
      <p className={typography.t2}>
        This will be the listing price in times where there is no active price
        point.
      </p>
      <div className={styles.actions}>
        <Button
          label="Cancel"
          onClick={handleOnClickCancel}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Okay"
          onClick={pricingFormHandleSubmit(handleOnClickOkay)}
          variant={ButtonVariants.Primary}
        />
      </div>
    </fieldset>
  ) : (
    <div className={styles.showContainer}>
      <div className={typography.h7m}>Base Price</div>
      <div className={cx(typography.h7_20, styles.editButtonContainer)}>
        <span>${currentBasePrice}</span>
        <IconButton
          ariaLabel="Edit Base Price"
          className={styles.editButton}
          iconName="edit"
          onClick={open}
        />
      </div>
    </div>
  );
};
