import { lastDayOfMonth, setDate } from 'date-fns';
import { forwardRef, useEffect, useState } from 'react';
import ReactCalendar from 'react-calendar';
import { toISODateFormat } from '@utils/date';
import styles from '../../../components/dateSelector/DateSelector.scss';
import '../../../components/Calendar.overrides.css';
import { useRestaurant } from '../../../context/useRestaurant';
import { getClosedDates } from '../apiHelpers';

interface ClosureCalendarProps {
  onChange: (date: Date) => void;
  selectedDate: Date;
}

const FIRST_DAY_OF_MONTH = 1;

interface ClosedLabelProps {
  closedDates: string[];
  date: Date;
}

const ClosedLabel = ({ date, closedDates }: ClosedLabelProps) =>
  closedDates.includes(toISODateFormat(date)) ? (
    <span className={styles.closedLabel}>(closed)</span>
  ) : null;

const tileContent = (date: Date, closedDates: string[]) => (
  <ClosedLabel date={date} closedDates={closedDates} />
);

export const ClosureCalendar = forwardRef<HTMLDivElement, ClosureCalendarProps>(
  ({ onChange, selectedDate }, ref) => {
    const [closedDates, setClosedDates] = useState<string[]>([]);
    const [calendarStartDate, setCalendarStartDate] = useState(selectedDate);
    const { id: restaurantId } = useRestaurant();

    useEffect(() => {
      const getClosedDatesForMonth = async () => {
        const startDate = toISODateFormat(
          setDate(calendarStartDate, FIRST_DAY_OF_MONTH),
        );
        const endDate = toISODateFormat(lastDayOfMonth(calendarStartDate));

        setClosedDates(await getClosedDates(restaurantId, startDate, endDate));
      };

      void getClosedDatesForMonth();
    }, [calendarStartDate]);

    return (
      <ReactCalendar
        inputRef={ref}
        maxDetail="month"
        minDetail="month"
        nextAriaLabel="Next Month"
        onActiveStartDateChange={({ activeStartDate }) =>
          activeStartDate && setCalendarStartDate(activeStartDate)
        }
        onChange={(value) => value instanceof Date && onChange(value)}
        prevAriaLabel="Previous month"
        showNeighboringMonth={false}
        tileClassName={({ date }) =>
          closedDates.includes(toISODateFormat(date)) ? 'closed-date' : null
        }
        tileContent={({ date }) => tileContent(date, closedDates)}
        value={selectedDate}
        view="month"
      />
    );
  },
);
ClosureCalendar.displayName = 'ClosureCalendar';
