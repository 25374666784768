import cx from 'classnames';
import { isEqual } from 'lodash-es';
import { useState } from 'react';
import type {
  Control,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import {
  ControlledFormTimeSelect,
  type TimeValues,
} from '@components/formInputs/ControlledFormTimeSelect';
import { isWholeNumberRule } from '@components/formInputs/rules';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import {
  isCompleteTimeValue,
  parseTimeValues,
} from '@components/formInputs/timeValuesUtils';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { DAY_INDEX_MAP, formatWeekDays } from '@utils/date';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type {
  ListingPricingFormData,
  ListingReservableDaysFormData,
  PricePointFormData,
} from '../types';
import styles from './PricePointField.scss';

export const DEFAULT_PRICE_POINT = {
  startTime: [],
  endTime: [],
  price: '',
  activeDays: [],
};

interface PricePointEditProps {
  pricePointIndex: number;
  pricePoint: PricePointFormData;
  pricingControl: Control<ListingPricingFormData>;
  pricingFormGetValues: UseFormGetValues<ListingPricingFormData>;
  pricingFormHandleSubmit: UseFormHandleSubmit<ListingPricingFormData>;
  pricingFormSetValue: UseFormSetValue<ListingPricingFormData>;
  hasOverlap: boolean;
  removePricePoint: UseFieldArrayRemove;
  reservableDaysFormValues: Pick<
    ListingReservableDaysFormData,
    'endTime' | 'repeat' | 'startTime'
  >;
}

export const PricePointField = ({
  pricePointIndex,
  pricePoint,
  pricingControl,
  pricingFormGetValues,
  pricingFormHandleSubmit,
  pricingFormSetValue,
  hasOverlap,
  removePricePoint,
  reservableDaysFormValues,
}: PricePointEditProps) => {
  const pricePointIsPersisted =
    pricingFormGetValues('pricePoints')[pricePointIndex].id;
  const { isOpen, close, open } = useIsOpen(!pricePointIsPersisted);
  const [currentPricePointValues, setCurrentPricePointValues] =
    useState<PricePointFormData>(pricePoint);

  const handleOnClickOkay = pricingFormHandleSubmit((data) => {
    setCurrentPricePointValues(data.pricePoints[pricePointIndex]);
    close();
  });

  const handleOnClickRemovePricePoint = () => {
    removePricePoint(pricePointIndex);
  };

  const handleOnClickCancel = () => {
    if (isEqual(DEFAULT_PRICE_POINT, currentPricePointValues)) {
      handleOnClickRemovePricePoint();
    } else {
      pricingFormSetValue(
        `pricePoints.${pricePointIndex}`,
        currentPricePointValues,
      );
    }

    close();
  };

  return isOpen ? (
    <fieldset className={styles.editContainer}>
      <h4 className={typography.t3}>
        WEEKLY PRICE POINT
        <IconButton
          ariaLabel="Remove Price Point"
          className={styles.iconButton}
          iconName="trash"
          onClick={handleOnClickRemovePricePoint}
        />
      </h4>
      <p className={typography.t2}>
        This price point will be applied on the selected weekdays and time range
        instead of the Minimum Price.
      </p>
      <ControlledFormTimeSelect
        control={pricingControl}
        label="Start Time"
        name={`pricePoints.${pricePointIndex}.startTime`}
        variant={LayoutVariant.Horizontal}
        rules={{
          required: true,
          validate: {
            isComplete: (timeValues: TimeValues): string | boolean =>
              isCompleteTimeValue(timeValues)
                ? true
                : 'Time must be completely filled in',
            isWithinListing: (timeValues: TimeValues): string | boolean =>
              parseTimeValues(timeValues) >=
              parseTimeValues(reservableDaysFormValues.startTime)
                ? true
                : 'Start Time must be within the listings Start Time',
          },
        }}
      />
      <ControlledFormTimeSelect
        control={pricingControl}
        label="End Time"
        name={`pricePoints.${pricePointIndex}.endTime`}
        variant={LayoutVariant.Horizontal}
        rules={{
          required: true,
          validate: {
            isGreaterThanOrEqualToStartTime: (
              endTime: TimeValues,
            ): string | boolean =>
              pricingFormGetValues(
                `pricePoints.${pricePointIndex}.startTime`,
              ) &&
              parseTimeValues(endTime) >=
                parseTimeValues(
                  pricingFormGetValues(
                    `pricePoints.${pricePointIndex}.startTime`,
                  ),
                )
                ? true
                : 'End time must be the same as or after the Start Time',
            isComplete: (timeValues: TimeValues): string | boolean =>
              isCompleteTimeValue(timeValues)
                ? true
                : 'Time must be completely filled in',
            isWithinListing: (timeValues: TimeValues): string | boolean =>
              parseTimeValues(timeValues) <=
              parseTimeValues(reservableDaysFormValues.endTime)
                ? true
                : 'End Time must be within the listings End Time',
          },
        }}
      />
      <ControlledFormCurrencyInput
        control={pricingControl}
        label="Price"
        name={`pricePoints.${pricePointIndex}.price`}
        variant={LayoutVariant.Horizontal}
        rules={{
          min: {
            message: 'Price must be greater than or equal to 0',
            value: 0,
          },
          validate: {
            isWholeNumber: isWholeNumberRule('Price'),
          },
          required: true,
        }}
      />
      <ControlledFormDayRadio
        control={pricingControl}
        label="Active Days"
        name={`pricePoints.${pricePointIndex}.activeDays`}
        daysOfWeek={reservableDaysFormValues.repeat.map(
          (indexOfWeek) => DAY_INDEX_MAP[indexOfWeek],
        )}
        rules={{
          required: true,
        }}
      />
      {hasOverlap && (
        <div className={styles.overlapMessage}>
          <Icon name="alertCircle" />
          <span className={typography.t1}>Overlapping Price Points</span>
          <p className={typography.t1}>
            Some price points in this listing overlap with the other price
            points.
          </p>
        </div>
      )}
      <div className={styles.actions}>
        <Button
          type="button"
          label="Cancel"
          onClick={handleOnClickCancel}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          type="button"
          label="Okay"
          onClick={handleOnClickOkay}
          variant={ButtonVariants.Primary}
        />
      </div>
    </fieldset>
  ) : (
    <button
      className={styles.showContainer}
      onClick={open}
      aria-label="Edit Price Point"
      title="Edit Price Point"
    >
      {hasOverlap && (
        <Icon
          name="alertCircle"
          className={styles.overlapIcon}
          ariaLabel="overlap indicator"
        />
      )}
      <div className={typography.h7m}>
        {`${ISOTimeTo12HourTime(
          parseTimeValues(
            pricingFormGetValues(`pricePoints.${pricePointIndex}.startTime`),
          ),
        )}–${ISOTimeTo12HourTime(
          parseTimeValues(
            pricingFormGetValues(`pricePoints.${pricePointIndex}.endTime`),
          ),
        )}`}
      </div>
      <div className={cx(styles.price, typography.h7)}>
        {`$${pricingFormGetValues(`pricePoints.${pricePointIndex}.price`)}`}
      </div>
      <div className={cx(styles.activeDays, typography.t1)}>
        {formatWeekDays(
          pricingFormGetValues(`pricePoints.${pricePointIndex}.activeDays`),
        )}
      </div>
    </button>
  );
};
