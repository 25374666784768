import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import styles from './EditListingAlertModalContainer.scss';
import { useEditListingContext } from './EditListingContext';

interface EditListingAlertModalContainerProps {
  handleOnConfirmSubmit: () => void;
}

export const EditListingAlertModalContainer = ({
  handleOnConfirmSubmit,
}: EditListingAlertModalContainerProps) => {
  const { modalState, clearModalState } = useEditListingContext();

  return (
    <>
      <Modal
        isOpen={modalState.currentModal === 'error'}
        onClose={clearModalState}
        title="Edit Listing Error"
      >
        <div className={styles.modalContent}>
          <p className={cx(typography.c2, styles.message)}>
            {modalState.message}
          </p>
          <p className={cx(typography.c2, styles.message)}>
            Please address the error and try again.
          </p>
          <ModalActions>
            <Button
              className={styles.button}
              label="Understood"
              onClick={clearModalState}
              variant={ButtonVariants.Tertiary}
            />
          </ModalActions>
        </div>
      </Modal>
      <Modal
        isOpen={modalState.currentModal === 'warning'}
        onClose={clearModalState}
        title="Edit Listing Warning"
      >
        <div className={styles.modalContent}>
          <p className={cx(typography.c2, styles.message)}>
            {modalState.message}
          </p>
          <p className={cx(typography.c2, styles.message)}>
            Are you sure you want to proceed?
          </p>
          <ModalActions>
            <Button
              className={styles.button}
              label="Cancel"
              onClick={clearModalState}
              variant={ButtonVariants.Tertiary}
            />
            <Button
              className={styles.button}
              label="Save Anyway"
              onClick={handleOnConfirmSubmit}
              variant={ButtonVariants.Primary}
            />
          </ModalActions>
        </div>
      </Modal>
    </>
  );
};
