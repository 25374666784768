// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DraggableFloorPlanTable__container___uK1f8{display:flex;position:absolute;padding:0;border:none}@keyframes DraggableFloorPlanTable__pulsate___kKu9X{0%{opacity:0;transform:scale(1, 1)}50%{opacity:.5}100%{border-width:2px;opacity:0;transform:scale(2.5, 2.5)}}.DraggableFloorPlanTable__container___uK1f8 .DraggableFloorPlanTable__newTable___IgoxX{animation-delay:375ms;animation-duration:1125ms;animation-iteration-count:5;animation-name:DraggableFloorPlanTable__pulsate___kKu9X;animation-timing-function:linear;border-radius:50%;border:0 solid rgba(193,163,110,.7);position:absolute}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/support/DraggableFloorPlanTable.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,oDACE,GACE,SAAA,CACA,qBAAA,CAGF,IACE,UAAA,CAGF,KACE,gBAAA,CACA,SAAA,CACA,yBAAA,CAAA,CAIJ,uFACE,qBAAA,CACA,yBAAA,CACA,2BAAA,CACA,uDAAA,CACA,gCAAA,CACA,iBAAA,CACA,mCAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DraggableFloorPlanTable__container___uK1f8",
	"newTable": "DraggableFloorPlanTable__newTable___IgoxX",
	"pulsate": "DraggableFloorPlanTable__pulsate___kKu9X"
};
export default ___CSS_LOADER_EXPORT___;
