import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Kebab } from '@components/kebab/Kebab';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import { useListingsContext } from '../ListingsContext';
import { CreateListingSharedPageHeader } from '../listingsPage/CreateListingSharedPageHeader';
import { listingsPagePath } from '../listingsPage/listingsPagePath';
import { useCreateListingContext } from './CreateListingContext';

export const CreateListingReservableDaysPageHeader = () => {
  const navigate = useNavigate();

  const { scope, subView } = useListingsContext();
  const {
    handleOnClickPublish,
    handleOnClickSaveAsDraft,
    reservableDaysFormHandleSubmit,
  } = useCreateListingContext();

  return (
    <CreateListingSharedPageHeader category="Operations" title="Create Listing">
      <FeatureFlagOn<RestaurantAdminFeatureFlag>
        element={
          <Kebab
            ariaLabelPrefix="create listing"
            options={[
              {
                label: 'Cancel',
                onClick: () => navigate(listingsPagePath({ scope, subView })),
              },
            ]}
          />
        }
        fallback={
          <>
            <Button
              label="Publish Listing"
              name="publish"
              onClick={() => {
                reservableDaysFormHandleSubmit(handleOnClickPublish)();
              }}
              variant={ButtonVariants.Primary}
            />
            <Kebab
              ariaLabelPrefix="create listing"
              options={[
                {
                  label: 'Save as Draft',
                  name: 'draft',
                  onClick: () => {
                    reservableDaysFormHandleSubmit(handleOnClickSaveAsDraft)();
                  },
                },
                {
                  label: 'Cancel',
                  onClick: () => navigate(listingsPagePath({ scope, subView })),
                },
              ]}
            />
          </>
        }
        name="pricingManagerFlag"
      />
    </CreateListingSharedPageHeader>
  );
};
