import { Button, ButtonVariants } from '@components/button/Button';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import {
  noShow,
  unseatReservation,
} from 'restaurantAdmin/reservations/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import { isReservation } from '../apiHelpers';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import { GuestFinishedConfirmationModal } from './GuestFinishedConfirmationModal';
import { NoShowConfirmationModal } from './NoShowConfirmationModal';
import styles from './ReservationGuestSheetActions.scss';
import { UnseatGuestConfirmationModal } from './UnseatGuestConfirmationModal';

export interface ReservationGuestSheetActionsProps {
  isSeated: boolean;
  isStranded: boolean;
  onEnableSeatMode: () => void;
  reservationId: string;
  seatModeEnabled: boolean;
}

const SUCCESS_MESSAGE =
  'Success: The reservation has been flagged as a no show';

export const ReservationGuestSheetActions = ({
  isSeated,
  isStranded,
  onEnableSeatMode,
  reservationId,
  seatModeEnabled,
}: ReservationGuestSheetActionsProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();
  const {
    isOpen: isUnseatModalOpen,
    open: openUnseatModal,
    close: closeUnseatModal,
  } = useIsOpen();
  const { id: restaurantId } = useRestaurant();
  const {
    closeSidePanelSheet,
    refreshOccupants,
    refreshFloorPlan,
    selectedOccupant,
  } = useReservationServiceContext();

  const handleOnClickNoShow = async () => {
    const response = await noShow({
      restaurantId,
      reservationId,
    });

    if (response.ok) {
      refreshOccupants();
      successToast({ message: SUCCESS_MESSAGE });
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    closeSidePanelSheet();
  };

  const onFinishReservation = () => {
    refreshFloorPlan();
    refreshOccupants();
    closeSidePanelSheet();
  };

  const handleUnseatGuest = async (): Promise<void> => {
    if (!isReservation(selectedOccupant)) return;

    const response = await unseatReservation({
      restaurantId,
      reservationId: selectedOccupant.id,
    });
    if (response.ok) {
      closeSidePanelSheet();
      refreshOccupants();
      refreshFloorPlan();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
  };

  if (seatModeEnabled) {
    return null;
  }

  const showNoShowButton = !isSeated;
  const showSeatOrChangeSeatButton = !seatModeEnabled;
  const showFinishAndUnseatButtons = isSeated;
  const hasReservationActions =
    showNoShowButton ||
    showSeatOrChangeSeatButton ||
    showFinishAndUnseatButtons;

  return hasReservationActions ? (
    <div className={styles.reservationActions}>
      {showSeatOrChangeSeatButton && !isSeated && (
        <Button
          isDisabled={isStranded}
          label="Seat Guest"
          onClick={onEnableSeatMode}
          variant={ButtonVariants.Primary}
        />
      )}
      {showNoShowButton && (
        <>
          <Button
            variant={ButtonVariants.Tertiary}
            onClick={openModal}
            label="No Show"
          />
          <NoShowConfirmationModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            handleOnClickNoShow={() => handleOnClickNoShow()}
          />
        </>
      )}
      {showFinishAndUnseatButtons && (
        <>
          <Button
            label="Guest Finished"
            onClick={openModal}
            variant={ButtonVariants.Primary}
          />
          <GuestFinishedConfirmationModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            onFinishReservation={onFinishReservation}
            reservationId={reservationId}
          />
        </>
      )}
      {showSeatOrChangeSeatButton && isSeated && (
        <Button
          isDisabled={isStranded}
          label="Change Seat"
          onClick={onEnableSeatMode}
          variant={ButtonVariants.Tertiary}
        />
      )}
      {showFinishAndUnseatButtons && (
        <>
          <Button
            label="Unseat Guest"
            onClick={openUnseatModal}
            variant={ButtonVariants.Tertiary}
          />
          <UnseatGuestConfirmationModal
            closeModal={closeUnseatModal}
            isOpen={isUnseatModalOpen}
            onUnseatGuest={handleUnseatGuest}
          />
        </>
      )}
    </div>
  ) : null;
};
