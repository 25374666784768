import { useForm } from 'react-hook-form';
import { type Listing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import styles from '../listingsPage/CreateEditListingPageLayout.scss';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';
import { type ListingFloorPlanFormData } from '../types';
import { listingFloorPlanFormDataFromListing } from '../utils/listingUtils';
import { EditListingAlertModalContainer } from './EditListingAlertModalContainer';
import { useEditListingContext } from './EditListingContext';
import { EditListingFloorPlanPageBody } from './EditListingFloorPlanPageBody';
import { EditListingPageHeader } from './EditListingPageHeader';

export const EditListingFloorPlanForm = ({
  existingListing,
}: {
  existingListing: Listing;
}) => {
  const { floorPlan, subView } = useListingsContext();
  const { processEditSubmission } = useEditListingContext();

  const { control, handleSubmit, getValues } =
    useForm<ListingFloorPlanFormData>({
      defaultValues: listingFloorPlanFormDataFromListing(
        existingListing,
        floorPlan,
      ),
    });

  const buildListingFromForm = (
    detailsFormValues: ListingFloorPlanFormData,
  ): Listing => ({
    ...existingListing,
    iconName: detailsFormValues.iconName,
    interval: detailsFormValues.interval,
    inventoryCount: Number(detailsFormValues.inventoryCount),
    maximumGuests: Number(detailsFormValues.maximumGuests),
    minimumGuests: Number(detailsFormValues.minimumGuests),
    name: detailsFormValues.name,
    publicName: detailsFormValues.publicName,
    turnTime: detailsFormValues.turnTime,
  });

  const submit = (ignoreWarnings: boolean) => {
    const detailsFormValues = getValues();
    const highlightedFloorPlanTableIds =
      detailsFormValues.highlightedTables.map((table) => table.id);
    const updatedListing = buildListingFromForm(detailsFormValues);
    void processEditSubmission(
      highlightedFloorPlanTableIds,
      ignoreWarnings,
      updatedListing,
    );
  };
  const handleOnSubmit = () => submit(false);
  const handleOnConfirmSubmit = () => submit(true);

  return (
    <>
      <form
        className={styles.pageWrapper}
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
      >
        <EditListingPageHeader
          cancelPath={listingsPagePath({
            scope:
              existingListing.status === 'draft'
                ? ListingsPageScope.Draft
                : ListingsPageScope.Published,
            subView,
          })}
        />
        <EditListingFloorPlanPageBody
          isEditing
          control={control}
          floorPlan={floorPlan}
        />
      </form>
      <EditListingAlertModalContainer
        handleOnConfirmSubmit={handleOnConfirmSubmit}
      />
    </>
  );
};
