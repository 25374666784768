import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { CoverLimitForm } from './CoverLimitForm';

interface CoverLimitSettingsProps {
  coverLimit: number | null;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const CoverLimitSettings = ({
  coverLimit,
  refreshRestaurant,
  restaurantId,
}: CoverLimitSettingsProps) => (
  <RestaurantSettingsSection
    heading="Cover Limit"
    isEditable
    renderForm={(exitEditMode) => (
      <CoverLimitForm
        coverLimit={String(coverLimit)}
        exitEditMode={exitEditMode}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
      />
    )}
  >
    <p className={typography.t2}>
      Set the cover limit for you entire restaurant. This setting will make sure
      that at any given moment, the number of guests in your restaurant does not
      exceed the set limit.
    </p>
    <h3 className={typography.h6}>
      {coverLimit ? 'Limit' : 'No cover limit set'}
    </h3>
    {!!coverLimit && (
      <div
        className={typography.c2}
      >{`${coverLimit} cover${coverLimit > 1 ? 's' : ''}`}</div>
    )}
  </RestaurantSettingsSection>
);
