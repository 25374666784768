export const ROOT_PATH = '/';
export const PREVIOUS_PATH = -1;

// root level standalone paths
export const FORGOT_PASSWORD_ROOT_PATH = '/forgot-password';
export const GUEST_BOOK_ROOT_PATH = '/guest-book';
export const RESET_PASSWORD_ROOT_PATH = '/reset-password';
export const INVITE_EXPIRED_PATH = '/invite-expired';
export const CREATE_ACCOUNT_PATH = '/create-account';

// reservations paths
export const RESERVATIONS_ROOT_PATH = '/reservations';
// reservations report paths
export const RESERVATIONS_REPORT_PATH = '/reservations/report';
// reservations service paths
export const RESERVATIONS_SERVICE_PATH = '/reservations/service';
export const RESERVATIONS_SERVICE_TERMINAL_PATH = 'service';
// reservations concierge paths
export const RESERVATIONS_CONCIERGE_PATH = '/reservations/concierge';
export const RESERVATIONS_CONCIERGE_TERMINAL_PATH = 'concierge';
// reservations stranded paths
export const RESERVATIONS_STRANDED_PATH = '/reservations/stranded';
export const RESERVATIONS_STRANDED_TERMINAL_PATH = 'stranded';
// occupants paths
export const RESERVATIONS_OCCUPANTS_PATH = '/reservations/occupants';
export const RESERVATIONS_OCCUPANTS_TERMINAL_PATH = 'occupants';

// operations paths
export const OPERATIONS_ROOT_PATH = '/operations';
// operations listings paths
export const OPERATIONS_LISTINGS_PATH = '/operations/listings';
export const OPERATIONS_LISTINGS_TERMINAL_PATH = 'listings';
export const OPERATIONS_LISTINGS_CREATE_TERMINAL_PATH = 'create';
export const OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH =
  '/operations/listings/create/floor-plan';
export const OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_TERMINAL_PATH = 'floor-plan';
export const OPERATIONS_LISTINGS_CREATE_PRICING_PATH =
  '/operations/listings/create/pricing';
export const OPERATIONS_LISTINGS_CREATE_PRICING_TERMINAL_PATH = 'pricing';
export const OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH =
  '/operations/listings/create/reservable-days';
export const OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_TERMINAL_PATH =
  'reservable-days';
export const OPERATIONS_LISTINGS_EDIT_TERMINAL_PATH = 'edit';
export const OPERATIONS_LISTINGS_EDIT_FLOOR_PLAN_TERMINAL_PATH = 'floor-plan';
export const OPERATIONS_LISTINGS_EDIT_PRICING_TERMINAL_PATH = 'pricing';
export const OPERATIONS_LISTINGS_EDIT_RESERVABLE_DAYS_TERMINAL_PATH =
  'reservable-days';

export const OPERATIONS_LISTING_EDIT_PATH = '/operations/listings/edit';
// operations events paths
export const OPERATIONS_EVENTS_PATH = '/operations/events';
export const OPERATIONS_EVENTS_TERMINAL_PATH = 'events';
export const OPERATIONS_EVENTS_CREATE_PATH = '/operations/events/create';
export const OPERATIONS_EVENTS_CREATE_TERMINAL_PATH = 'create';
export const OPERATIONS_EVENTS_UPDATE_PATH = '/operations/events/update';
export const OPERATIONS_EVENTS_UPDATE_TERMINAL_PATH = 'update';

// settings paths
export const SETTINGS_ROOT_PATH = '/settings';
// settings general paths
export const SETTINGS_GENERAL_PATH = '/settings/general';
export const SETTINGS_GENERAL_TERMINAL_PATH = 'general';
// settings team paths
export const SETTINGS_TEAM_PATH = '/settings/team';
export const SETTINGS_TEAM_TERMINAL_PATH = 'team';
export const SETTINGS_INVITE_MEMBER_PATH = '/settings/invite-team-member';
export const SETTINGS_INVITE_MEMBER_TERMINAL_PATH = 'invite-team-member';
// settings finances paths
export const SETTINGS_FINANCES_PATH = '/settings/finances';
export const SETTINGS_FINANCES_TERMINAL_PATH = 'finances';

export const SUPPORT_ROOT_PATH = '/support';
export const SUPPORT_FLOOR_PLAN_EDITOR_TERMINAL_PATH = 'floor-plan-editor';
export const SUPPORT_GUEST_IMPORT_TERMINAL_PATH = 'guest-import';
