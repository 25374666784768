import { useState } from 'react';
import { errorToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import { getListings } from './apiHelpers';

export const useListings = (isDraft: boolean) => {
  const { id: restaurantId } = useRestaurant();

  const [invalidateListings, setInvalidateListings] = useState(0);

  const refreshListings = () => setInvalidateListings((i) => i + 1);

  const { data: listings, isPending: isLoading } = useAbortEffect(
    {
      effect: (signal) => getListings(restaurantId, isDraft, signal),
      onError: (e) => {
        errorToast({ message: 'Error loading listings' });
        reportAppError(e);
      },
    },
    [restaurantId, isDraft, invalidateListings],
  );

  return {
    listings: listings || [],
    isLoading,
    refreshListings,
  };
};
