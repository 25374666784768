// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditListingReservableDaysPageBody__calendar___J5K7I{height:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/edit/EditListingReservableDaysPageBody.scss"],"names":[],"mappings":"AAAA,qDACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": "EditListingReservableDaysPageBody__calendar___J5K7I"
};
export default ___CSS_LOADER_EXPORT___;
