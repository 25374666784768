import { Spinner } from '@components/Spinner';
import { useListingsContext } from '../ListingsContext';
import { ListingCard } from './ListingCard';
import styles from './ListingsList.scss';

export const ListingsList = () => {
  const {
    isLoading,
    setSelectedListingId,
    listListings: listings,
  } = useListingsContext();

  if (isLoading) {
    return (
      <div className={styles.tabSpinnerContainer}>
        <Spinner className={styles.tabSpinner} />
      </div>
    );
  }
  if (listings.length === 0) {
    return (
      <div className={styles.tabNoMatchingListings}>
        <div>No matching listings</div>
      </div>
    );
  }
  return (
    <ul className={styles.main}>
      {listings.map((listing) => (
        <ListingCard
          key={listing.id}
          listing={listing}
          onClick={() => setSelectedListingId(listing.id)}
        />
      ))}
    </ul>
  );
};
