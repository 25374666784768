import cx from 'classnames';
import { useState } from 'react';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { ISOTimeAddMinutes, ISOTimeToMinuteOfDay } from '@utils/time';
import { useListingsContext } from '../ListingsContext';
import { mapN } from '../utils/listingUtils';
import styles from './AdminCalendar.scss';
import { getOverlappingListingIds, timeToRow, weekDayToCol } from './utils';

interface SelectTimeButtonProps {
  date: string;
  day: number;
  startTime: string;
  endTime: string;
}

export const SelectTimeButton = ({
  date,
  day,
  startTime,
  endTime,
}: SelectTimeButtonProps) => {
  const {
    pageListings: listings,
    setSelectedCalendarCellListingIds,
    clearSelectedListing,
  } = useListingsContext();
  const [isSelected, setIsSelected] = useState(false);
  const { ref } = useHandleClickOutside<HTMLButtonElement>(() => {
    if (isSelected) {
      setIsSelected(false);
      setSelectedCalendarCellListingIds([]);
    }
  });

  const handleOnClick = () => {
    setIsSelected(true);
    clearSelectedListing();
    const overlappingListingIds = getOverlappingListingIds(
      startTime,
      endTime,
      date,
      listings,
    );
    setSelectedCalendarCellListingIds(overlappingListingIds);
  };

  const label = `Select ${date} ${startTime} - ${endTime}`;

  return (
    <button
      aria-label={label}
      className={cx(styles.selectTimeButton, {
        [styles.isSelected]: isSelected,
      })}
      style={{
        gridColumn: weekDayToCol(day),
        gridRow: timeToRow(startTime),
      }}
      title={label}
      type="button"
      ref={ref}
      onClick={handleOnClick}
    />
  );
};

const SELECT_TIME_RESOLUTION = 15;

interface SelectTimeButtonsProps {
  date: string;
  startTime: string;
  endTime: string;
  weekDay: number;
}

export const SelectTimeButtons = ({
  date,
  startTime: rangeStartTime,
  endTime: rangeEndTime,
  weekDay,
}: SelectTimeButtonsProps) => {
  const buttonCount =
    (ISOTimeToMinuteOfDay(rangeEndTime) -
      ISOTimeToMinuteOfDay(rangeStartTime)) /
    SELECT_TIME_RESOLUTION;
  return (
    <>
      {mapN(buttonCount, (i) => {
        const startTime = ISOTimeAddMinutes(
          rangeStartTime,
          SELECT_TIME_RESOLUTION * i,
        );
        const endTime = ISOTimeAddMinutes(startTime, SELECT_TIME_RESOLUTION);
        return (
          <SelectTimeButton
            key={startTime}
            startTime={startTime}
            endTime={endTime}
            date={date}
            day={weekDay}
          />
        );
      })}
    </>
  );
};
