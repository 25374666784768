import {
  CardElement as StripeCardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type {
  PaymentMethodResult,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { debounce } from 'lodash-es';
import { type FormEventHandler, useCallback, useEffect, useState } from 'react';
import { type Control, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import type { PricingInfo } from '@shared/pricingInfoApi';
import { getPricingInfo } from '@shared/pricingInfoApi';
import { isCancellationPolicyApplicable } from '@shared/reservations/reservationUtil';
import { type CancellationPolicy } from '@shared/reservations/types';
import { CONFLICT } from '@shared/statusCodes';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import { wholeDollarsToCents } from '@utils/currency';
import { ISOTimeTo12HourTime } from '@utils/time';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  type AdminRestaurant,
  getAdminRestaurant,
} from 'restaurantAdmin/settings/team/apiHelpers';
import { RESERVATIONS_SERVICE_PATH } from '../../paths';
import type { HostBookReservationPayload } from './apiHelpers';
import { hostBookReservation } from './apiHelpers';
import { useAvailabilityDrawerContext } from './state/AvailabilityDrawerContext';

const UPDATE_PRICING_INFO_DEBOUNCE_INTERVAL = 1000;

export enum CheckoutTypes {
  FullPrice = 'fullPrice',
  NoPrice = 'noPrice',
  CustomPrice = 'customPrice',
}

interface UseAdminCheckout {
  addGuest: (args: AddGuestArgs) => void;
  control: Control<AdminCheckoutFormData>;
  cancellationPolicy?: CancellationPolicy | null;
  customPriceInDollars: string;
  handleBookReservationClick: FormEventHandler<HTMLFormElement>;
  handleCardElementOnChange: ({
    complete,
  }: StripeCardElementChangeEvent) => void;
  handleCheckoutTypeOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  isBookButtonDisabled: boolean;
  isGuestFieldsDisabled: boolean;
  pricingInfo?: PricingInfo;
  removeGuest: () => void;
  responseError: string;
  selectedCheckoutType: CheckoutTypes;
}

interface AdminCheckoutFormData {
  guestId?: string;
  firstName: string;
  lastName: string;
  customPriceInDollars: string;
  phone?: string;
}

interface AddGuestArgs {
  firstName: string;
  lastName: string;
  phone: string | null;
}

const DEFAULT_PRICING_INFO: PricingInfo = {
  fee: 0,
  tax: 0,
  total: 0,
};

const useFetchAdminRestaurant = (restaurantId: string) => {
  const [adminRestaurant, setAdminRestaurant] =
    useState<AdminRestaurant | null>(null);

  const refetch = async () => {
    const response = await getAdminRestaurant(restaurantId);
    if (isSuccessResponse(response)) {
      setAdminRestaurant(response);
    }
  };

  useAbortEffect(
    async (signal) => {
      const response = await getAdminRestaurant(restaurantId, signal);
      if (isSuccessResponse(response)) {
        setAdminRestaurant(response);
      }
    },
    [restaurantId],
  );

  return { adminRestaurant, refetch };
};

export const useAdminCheckout = (): UseAdminCheckout => {
  const restaurant = useRestaurant();
  const { closeDrawer, availabilityData } = useAvailabilityDrawerContext();

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [pricingInfo, setPricingInfo] = useState(DEFAULT_PRICING_INFO);

  const {
    control,
    handleSubmit,
    resetField,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm<AdminCheckoutFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      customPriceInDollars: '',
      phone: '',
    },
  });

  const guestId = watch('guestId');

  useEffect(() => {
    const subscription = watch(({ customPriceInDollars }, { name }) => {
      if (name === 'customPriceInDollars' && !!customPriceInDollars) {
        void fetchPricingInfoWithDebounce(
          wholeDollarsToCents(Number(customPriceInDollars)),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [responseError, setResponseError] = useState('');
  const [selectedCheckoutType, setSelectedCheckoutType] = useState(
    CheckoutTypes.NoPrice,
  );
  const [isCardElementFormComplete, setIsCardElementFormComplete] =
    useState(false);
  const { adminRestaurant, refetch } = useFetchAdminRestaurant(restaurant.id);

  const fetchPricingInfoWithoutDebounce = async (priceInCents: number) => {
    const pricingInfoResponse = await getPricingInfo({
      price: priceInCents,
      zipCode: restaurant.postalCode,
    });
    if (isSuccessResponse(pricingInfoResponse)) {
      setPricingInfo(pricingInfoResponse);
    }
  };

  const addGuest = (newGuest: AddGuestArgs) => {
    setValue('firstName', newGuest.firstName);
    setValue('lastName', newGuest.lastName);
    setValue('phone', newGuest.phone || '');
  };

  const removeGuest = () => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('phone', '');
  };

  const fetchPricingInfoWithDebounce = useCallback(
    debounce(async (priceInCents: number) => {
      const pricingInfoResponse = await getPricingInfo({
        price: priceInCents,
        zipCode: restaurant.postalCode,
      });
      if (isSuccessResponse(pricingInfoResponse)) {
        setPricingInfo(pricingInfoResponse);
      }
    }, UPDATE_PRICING_INFO_DEBOUNCE_INTERVAL),
    [],
  );

  const handleCardElementOnChange = ({
    complete,
  }: StripeCardElementChangeEvent) => {
    setIsCardElementFormComplete(complete);
  };

  const createPaymentMethod = async (): Promise<
    PaymentMethodResult | undefined
  > => {
    if (!stripe || !elements) {
      return undefined;
    }
    const stripeCardElement = elements.getElement(StripeCardElement);
    let stripeResponse;
    if (stripeCardElement) {
      stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: stripeCardElement,
      });

      if (stripeResponse.error?.message) {
        setResponseError(stripeResponse.error.message);
        return undefined;
      }
    }

    return stripeResponse;
  };

  // todo wrap submit
  const handleBookReservationClick = handleSubmit(
    async (data: AdminCheckoutFormData) => {
      if (!availabilityData) return;

      let paymentMethodResponse;

      if (selectedCheckoutType !== CheckoutTypes.NoPrice) {
        paymentMethodResponse = await createPaymentMethod();
        if (!paymentMethodResponse) {
          return;
        }
      }

      const price =
        selectedCheckoutType === CheckoutTypes.CustomPrice
          ? wholeDollarsToCents(Number(data.customPriceInDollars))
          : availabilityData.listingPrice;

      const getExpectedCancellationPolicyId = () => {
        if (selectedCheckoutType === CheckoutTypes.NoPrice) return null;
        if (!adminRestaurant?.cancellationPolicy) return null;
        if (
          !isCancellationPolicyApplicable(
            price,
            adminRestaurant?.cancellationPolicy?.threshold,
          )
        )
          return null;
        return adminRestaurant.cancellationPolicy.id;
      };

      const hostBookReservationPayload: HostBookReservationPayload = {
        date: availabilityData.date,
        expectedCancellationPolicyId: getExpectedCancellationPolicyId(),
        firstName: data.firstName,
        guestCount: availabilityData.guestCount,
        lastName: data.lastName,
        listingId: availabilityData.listingId,
        listingPrice: availabilityData.listingPrice,
        guestId: data.guestId,
        paymentMethodId: paymentMethodResponse?.paymentMethod?.id,
        restaurantId: restaurant.id,
        time: availabilityData.time,
      };

      if (data.phone) {
        hostBookReservationPayload.phone = data.phone;
      }

      if (selectedCheckoutType === CheckoutTypes.CustomPrice) {
        hostBookReservationPayload.customPrice = wholeDollarsToCents(
          Number(data.customPriceInDollars),
        );
      }

      const response = await hostBookReservation(hostBookReservationPayload);

      if (response.ok) {
        const formattedTime = ISOTimeTo12HourTime(availabilityData.time);
        closeDrawer();
        navigate(`${RESERVATIONS_SERVICE_PATH}?date=${availabilityData.date}`);
        successToast({
          message: `${data.firstName} ${data.lastName}'s ${formattedTime} reservation was booked.`,
        });
      } else {
        let errorMessage: string;
        if (response.status === CONFLICT) {
          refetch();
          errorMessage =
            "We've encountered an error. " +
            'Something may have changed. ' +
            'Please review the updated cancellation policy and confirm your purchase';
        } else {
          errorMessage = await getErrorResponseMessage(response);
        }
        setResponseError(errorMessage);
      }
    },
  );

  const isBookButtonDisabled =
    selectedCheckoutType === CheckoutTypes.NoPrice
      ? isSubmitting
      : !isCardElementFormComplete || isSubmitting;

  const handleCheckoutTypeOnChange = async ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckoutType(value as CheckoutTypes);
    setPricingInfo(DEFAULT_PRICING_INFO);
    resetField('customPriceInDollars');
    if (availabilityData && value === CheckoutTypes.FullPrice) {
      await fetchPricingInfoWithoutDebounce(availabilityData.listingPrice);
    }
  };

  return {
    addGuest,
    cancellationPolicy: adminRestaurant?.cancellationPolicy,
    control,
    customPriceInDollars: watch('customPriceInDollars'),
    handleBookReservationClick,
    handleCardElementOnChange,
    handleCheckoutTypeOnChange,
    isBookButtonDisabled,
    isGuestFieldsDisabled: !!guestId,
    pricingInfo,
    removeGuest,
    responseError,
    selectedCheckoutType,
  };
};
