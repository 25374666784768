import cx from 'classnames';
import { has } from 'lodash-es';
import {
  type Control,
  Controller,
  type FieldErrors,
  type FieldValues,
  type Path,
} from 'react-hook-form';
import ReactPhoneNumberInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { DEFAULT_COUNTRY } from '@components/formInputs/ControlledFormPhoneInput';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './HookAwarePhoneInput.scss';

export interface HookAwarePhoneInputProps<T extends FieldValues> {
  control?: Control<T>;
  errors: FieldErrors<T>;
  isRequired?: boolean;
  label: string;
  name: Path<T>;
}
/**
 * @deprecated use <ControlledFormPhoneInput /> instead
 */
export const HookAwarePhoneInput = <T extends FieldValues>({
  control,
  errors,
  isRequired = true,
  label,
  name,
}: HookAwarePhoneInputProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange } }) => (
      <div className={styles.phoneNumberInputGroup}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: has(errors, name),
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <div className={styles.phoneInputAndErrorContainer}>
          <div
            className={cx({
              [styles.phoneInputContainer]: true,
              [styles.phoneInputContainerError]: has(errors, name),
            })}
          >
            <ReactPhoneNumberInput
              autoComplete="off"
              className={styles.phoneInput}
              data-testid="phone-input"
              defaultCountry={DEFAULT_COUNTRY}
              id={name}
              international
              name={name}
              onChange={onChange}
            />
          </div>
          <ValidationErrorMessage errors={errors} label={label} name={name} />
        </div>
      </div>
    )}
    rules={{
      required: isRequired,
      validate: (value: string) =>
        isValidPhoneNumber(value) || `${label} must be a valid phone number`,
    }}
  />
);
