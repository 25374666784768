// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalkInForm__container___KiUtx{display:flex;flex-direction:column;padding:1.5rem}.WalkInForm__container___KiUtx header{display:flex;justify-content:space-between}.WalkInForm__container___KiUtx header p{margin:0}.WalkInForm__container___KiUtx header button{background-color:rgba(0,0,0,0);border:none;cursor:pointer;padding:0}.WalkInForm__container___KiUtx header button svg{stroke:var(--white70);width:20px}.WalkInForm__container___KiUtx .WalkInForm__tableCard___Kf4mU{align-items:center;background-color:var(--panelBlue);color:var(--white70);display:flex;gap:1rem;margin:1rem 0;text-transform:uppercase}.WalkInForm__container___KiUtx .WalkInForm__tableCard___Kf4mU svg{width:42px}.WalkInForm__container___KiUtx form{display:flex;flex-direction:column;gap:1.5rem}.WalkInForm__container___KiUtx form .WalkInForm__nameInput___LlfRA label{white-space:nowrap}@media only screen and (min-width: 1280px){.WalkInForm__container___KiUtx form .WalkInForm__nameInput___LlfRA{gap:1rem}.WalkInForm__container___KiUtx form .WalkInForm__nameInput___LlfRA>div:first-child{width:max-content}.WalkInForm__container___KiUtx form .WalkInForm__nameInput___LlfRA>div:last-child{width:100%}}.WalkInForm__container___KiUtx form .WalkInForm__callToActionSection___Vga7C{display:flex;flex-direction:column;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/WalkInForm.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,+BACE,YAAA,CACA,qBAAA,CACA,cAAA,CAEA,sCACE,YAAA,CACA,6BAAA,CAEA,wCACE,QAAA,CAGF,6CACE,8BAAA,CACA,WAAA,CACA,cAAA,CACA,SAAA,CAEA,iDACE,qBAAA,CACA,UAAA,CAKN,8DACE,kBAAA,CACA,iCAAA,CACA,oBAAA,CACA,YAAA,CACA,QAAA,CACA,aAAA,CACA,wBAAA,CAEA,kEACE,UAAA,CAIJ,oCACE,YAAA,CACA,qBAAA,CACA,UAAA,CAGE,yEACE,kBAAA,CCjBN,2CDeE,mEAMI,QAAA,CAEA,mFACE,iBAAA,CAGF,kFACE,UAAA,CAAA,CAKN,6EACE,YAAA,CACA,qBAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WalkInForm__container___KiUtx",
	"tableCard": "WalkInForm__tableCard___Kf4mU",
	"nameInput": "WalkInForm__nameInput___LlfRA",
	"callToActionSection": "WalkInForm__callToActionSection___Vga7C"
};
export default ___CSS_LOADER_EXPORT___;
