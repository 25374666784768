import { type ApiResponse } from '@shared/types/apiHelpers';
import API from '../../api/apiClient';

export interface EligibleCancellationFeeResponse {
  isEligibleForCancellationFeeUponCancellation: boolean;
  eligibleForCancellationFeeUntil: string;
  cancellationPolicy: {
    fee: number;
  } | null;
}

export const eligibleForCancellationFee = async (
  restaurantId: string,
  reservationId: string,
): Promise<ApiResponse<EligibleCancellationFeeResponse>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/reservations/${reservationId}/eligible-for-cancellation-fee`,
  );

  return response.json() as Promise<
    ApiResponse<EligibleCancellationFeeResponse>
  >;
};
