// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnoccupiedFloorPlanTable__container___evmU1{display:flex;position:absolute;padding:0;border:none}.UnoccupiedFloorPlanTable__button___wVdyg{background-color:rgba(0,0,0,0);padding:0;justify-content:center;align-items:center;border:none;display:inline-flex;cursor:pointer}.UnoccupiedFloorPlanTable__button___wVdyg:disabled{cursor:not-allowed}.UnoccupiedFloorPlanTable__timerPill___vhIMB{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:32px;height:16px;color:var(--darkBlue);background-color:var(--secondary100);border-radius:8px;padding:0 4px;display:flex;align-items:center;justify-content:center}.UnoccupiedFloorPlanTable__timerPill___vhIMB svg{width:12px;stroke:var(--darkBlue)}.UnoccupiedFloorPlanTable__selectedTimerPill___e2Ron{background-color:var(--secondary300);color:var(--white)}.UnoccupiedFloorPlanTable__selectedNAPill___amYAL{background-color:var(--red300);color:var(--white)}.UnoccupiedFloorPlanTable__selectedNAPill___amYAL svg{stroke:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/UnoccupiedFloorPlanTable.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CAGF,0CACE,8BAAA,CACA,SAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,mBAAA,CACA,cAAA,CAEA,mDACE,kBAAA,CAIJ,6CACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,oCAAA,CACA,iBAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,iDACE,UAAA,CACA,sBAAA,CAIJ,qDACE,oCAAA,CACA,kBAAA,CAGF,kDACE,8BAAA,CACA,kBAAA,CAEA,sDACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UnoccupiedFloorPlanTable__container___evmU1",
	"button": "UnoccupiedFloorPlanTable__button___wVdyg",
	"timerPill": "UnoccupiedFloorPlanTable__timerPill___vhIMB",
	"selectedTimerPill": "UnoccupiedFloorPlanTable__selectedTimerPill___e2Ron",
	"selectedNAPill": "UnoccupiedFloorPlanTable__selectedNAPill___amYAL"
};
export default ___CSS_LOADER_EXPORT___;
