import { type Control } from 'react-hook-form';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { isWholeNumberRule } from '@components/formInputs/rules';
import { type ListingReservableDaysFormData } from '../types';
import styles from './PriceFieldSet.scss';

export interface PriceFieldSetProps {
  control: Control<ListingReservableDaysFormData>;
}

export const PriceFieldSet = ({ control }: PriceFieldSetProps) => (
  <fieldset
    className={styles.fieldset}
    data-testid="listing-life-span-fieldset"
  >
    <ControlledFormCurrencyInput
      control={control}
      label="Price"
      name="price"
      rules={{
        min: {
          message: 'Price per table must be greater than or equal to 0',
          value: 0,
        },
        validate: {
          isWholeNumber: isWholeNumberRule('Price per table'),
        },
        required: true,
      }}
    />
  </fieldset>
);
