import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageHeader } from '../../../layout/PageHeader';

export const EditListingPageHeader = ({
  cancelPath,
}: {
  cancelPath: string;
}) => (
  <PageHeader category="Operations" title="Edit Listing">
    <LinkStyledAsButton
      label="Cancel"
      to={cancelPath}
      variant={ButtonVariants.Tertiary}
    />
    <Button
      label="Save Listing"
      variant={ButtonVariants.Primary}
      type="submit"
    />
  </PageHeader>
);
