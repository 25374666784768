import cx from 'classnames';
import { has } from 'lodash-es';
import type {
  FieldErrors,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import {
  FormInputTooltip,
  type SetTooltipIsOpenDispatch,
  setTooltipIsOpenNoOp,
} from '@components/formInputTooltip/FormInputTooltip';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './HookAwareFormInput.scss';
import { getValidationValue } from './utils';

export interface HookAwareFormInputProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  errors: FieldErrors;
  inputType: string;
  isDisabled: boolean;
  label: string;
  name: Name;
  placeholder?: string;
  register: UseFormRegister<T>;
  setTooltipIsOpen?: SetTooltipIsOpenDispatch;
  tooltipText?: string;
  validations?: RegisterOptions<T, Name>;
  className?: string;
}

export const HookAwareFormInput = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  errors,
  inputType,
  isDisabled,
  label,
  name,
  placeholder,
  register,
  setTooltipIsOpen,
  tooltipText,
  validations,
  className,
}: HookAwareFormInputProps<T, Name>) => {
  const max = getValidationValue({
    inputType,
    validations,
    key: 'max',
  });
  const min = getValidationValue({
    inputType,
    validations,
    key: 'min',
  });
  const maxLength = getValidationValue({
    inputType,
    validations,
    key: 'maxLength',
  });
  const minLength = getValidationValue({
    inputType,
    validations,
    key: 'minLength',
  });
  const hasError = has(errors, name);

  return (
    <div className={cx(styles.fieldContainer, className)}>
      <div className={styles.labelContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: hasError,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <FormInputTooltip
          fieldLabel={label}
          id={`${name}-tooltip`}
          setTooltipIsOpen={setTooltipIsOpen || setTooltipIsOpenNoOp}
          text={tooltipText}
        />
      </div>
      <div className={styles.inputContainer}>
        <input
          aria-errormessage={`${name}-error`}
          aria-invalid={hasError}
          autoComplete="off"
          className={cx({
            [typography.t1]: true,
            [styles.inputError]: hasError,
          })}
          disabled={isDisabled}
          id={name}
          max={max}
          maxLength={maxLength ? Number(maxLength) : undefined}
          min={min}
          minLength={minLength ? Number(minLength) : undefined}
          placeholder={placeholder}
          type={inputType}
          {...register(name, validations)}
        />
        <ValidationErrorMessage errors={errors} label={label} name={name} />
      </div>
    </div>
  );
};
