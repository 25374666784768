import { ISOTimeFloorToQuarterHour } from '@utils/time';
import type { OccupantsOccupant } from '../apiHelpers';

type Time = string;
type TimeMap<T> = Record<Time, T>;

export const groupOccupantsByFifteenMinuteIncrement = (
  sortedOccupants: OccupantsOccupant[],
): TimeMap<OccupantsOccupant[]> =>
  sortedOccupants.reduce(
    (acc: TimeMap<OccupantsOccupant[]>, occupant: OccupantsOccupant) => {
      const key = ISOTimeFloorToQuarterHour(occupant.time);

      if (!acc[key]) acc[key] = [];

      acc[key].push(occupant);

      return acc;
    },
    {},
  );

export const isOccupantFinished = (
  occupantStatus: Pick<OccupantsOccupant, 'status'>['status'],
) => ['finished', 'autoResolved'].includes(occupantStatus);
