import { Spinner } from '@components/Spinner';
import { useListingsContext } from '../ListingsContext';
import { useEditListingContext } from './EditListingContext';
import { EditListingFloorPlanForm } from './EditListingFloorPlanForm';

export const EditListingFloorPlanPage = () => {
  const { isLoading: isFloorPlanLoading } = useListingsContext();
  const { isListingLoading, existingListing } = useEditListingContext();

  if (isFloorPlanLoading || isListingLoading) {
    return <Spinner />;
  }
  if (!existingListing) {
    return null;
  }
  return <EditListingFloorPlanForm existingListing={existingListing} />;
};
