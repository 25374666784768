import { type CancellationPolicy } from '@shared/reservations/types';
import { type ApiResponse } from '@shared/types/apiHelpers';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';
import { api } from '../../api';
import API from '../../api/apiClient';

export interface ReservationsApiGuest {
  email?: string;
  firstName: string;
  id: string;
  isDeleted: boolean;
  lastName: string;
  phone?: string;
}

export interface TransactionDetails {
  createdBy: {
    fullName?: string;
  };
  fee: number;
  price: number;
  receiptNumber: string;
  refundAmount: number | null;
  tax: number;
}

export type ReservationStatus =
  | 'active'
  | 'autoResolved'
  | 'canceled'
  | 'finished'
  | 'noShow'
  | 'resold';

export interface OccupantsReservation {
  cancellationFeeStatus: 'collected' | 'waived' | null;
  cancellationPolicy?: CancellationPolicy;
  date: string;
  guest: ReservationsApiGuest;
  guestCount: number;
  hasDietaryRestrictionOrAllergy: boolean;
  hasNote: boolean;
  id: string;
  isEligibleForCancellationFee: boolean;
  isStranded: boolean;
  isVIP: boolean;
  listingId: string;
  listingName: string;
  seatedTableName: string | null;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
  type: 'reservation';
}

export type WalkInStatus = 'active' | 'finished' | 'autoResolved';

export interface OccupantsWalkIn {
  date: string;
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
  seatedTableId: string;
  seatedTableName: string;
  status: WalkInStatus;
  time: string;
  type: 'walkIn';
}

// ...as opposed to ServiceOccupant
export type OccupantsOccupant = OccupantsReservation | OccupantsWalkIn;

export const isReservation = (
  occupant: OccupantsOccupant | undefined,
): occupant is OccupantsReservation => occupant?.type === 'reservation';

export const isWalkIn = (
  occupant: OccupantsOccupant | undefined,
): occupant is OccupantsWalkIn => occupant?.type === 'walkIn';

export const getOccupants = async (
  restaurantId: string,
  date: string,
  guestTagFilters: GuestTag[],
  signal?: AbortSignal,
): Promise<OccupantsOccupant[]> => {
  const guestTagFilterQueryParam = guestTagFilters.length
    ? `?guestTagFilters=${guestTagFilters.map((tag) => tag.title).join(',')}`
    : '';

  return api.get(
    `/restaurants/${restaurantId}/occupants/${date}${guestTagFilterQueryParam}`,
    {
      signal,
    },
  );
};

export const closeRestaurant = async (
  restaurantId: string,
  date: string,
): Promise<boolean> => {
  const response = await API.post(`/restaurants/${restaurantId}/closures`, {
    date,
  });
  return response.ok;
};

export const openRestaurant = (
  restaurantId: string,
  date: string,
): Promise<Response> =>
  API.delete(`/restaurants/${restaurantId}/closures?date=${date}`);

interface Closure {
  date: string;
  id: string;
  restaurantId: string;
}

export const getClosedDates = async (
  restaurantId: string,
  startDate: string,
  endDate: string,
): Promise<string[]> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/closures?startDate=${startDate}&endDate=${endDate}`,
  );

  const closures = await response.json();

  if (closures.error) return [];

  return closures.map((closure: Closure) => closure.date);
};

export interface RestaurantDayNote {
  date: string;
  note: string;
  restaurantId: string;
}

export const getRestaurantNote = async ({
  restaurantId,
  date,
}: {
  restaurantId: string;
  date: string;
}): Promise<ApiResponse<RestaurantDayNote>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/day-notes/${date}`,
  );

  return response.json();
};

export const saveRestaurantNote = async ({
  date,
  note,
  restaurantId,
}: RestaurantDayNote): Promise<ApiResponse<RestaurantDayNote>> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/day-notes/${date}`,
    {
      note,
    },
  );

  return response.json();
};

export const deleteRestaurantNote = async ({
  date,
  restaurantId,
}: {
  date: string;
  restaurantId: string;
}): Promise<Response> =>
  API.delete(`/restaurants/${restaurantId}/day-notes/${date}`);

export const collectCancellationFee = async (
  restaurantId: string,
  reservationId: string,
): Promise<Response> =>
  API.post(
    `/restaurants/${restaurantId}/reservations/${reservationId}/collect-fee`,
  );

export const waiveCancellationFee = async (
  restaurantId: string,
  reservationId: string,
): Promise<Response> =>
  API.post(
    `/restaurants/${restaurantId}/reservations/${reservationId}/waive-fee`,
  );
