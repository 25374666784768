import { isValid } from 'date-fns/isValid';
import type {
  FieldPath,
  FieldValues,
  RegisterOptions,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';

type ValidationKey = 'max' | 'maxLength' | 'min' | 'minLength';

const isValidDateLimit = (
  inputType: string,
  key: ValidationKey,
  dateString: string | number,
): boolean =>
  inputType === 'date' &&
  typeof dateString === 'string' &&
  (key === 'min' || key === 'max') &&
  /^\d\d\d\d-[01]\d-[0123]\d$/.test(dateString) &&
  isValid(dateString);

const isValidationValueMessage = (
  rule: ValidationRule,
): rule is ValidationValueMessage =>
  Boolean(rule && typeof rule === 'object' && 'value' in rule);

const isValidTextLength = (
  inputType: string,
  key: ValidationKey,
  value: string | number,
): boolean =>
  inputType === 'text' &&
  (key === 'minLength' || key === 'maxLength') &&
  typeof value === 'number' &&
  value > 0;

const isValidNumberLimit = (
  inputType: string,
  key: ValidationKey,
  value: string | number,
): boolean =>
  inputType === 'number' &&
  (key === 'min' || key === 'max') &&
  typeof value === 'number';

const isValidValue = (
  inputType: string,
  key: ValidationKey,
  value: string | number,
) =>
  isValidDateLimit(inputType, key, value) ||
  isValidTextLength(inputType, key, value) ||
  isValidNumberLimit(inputType, key, value);

export interface GetValidationValueArgs<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  inputType: string;
  key: ValidationKey;
  validations?: RegisterOptions<T, Name>;
}

export const getValidationValue = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  inputType,
  key,
  validations,
}: GetValidationValueArgs<T, Name>) => {
  if (!validations) return undefined;
  if (key in validations) {
    const validationRule = validations[key];

    if (!validationRule) return undefined;

    if (isValidationValueMessage(validationRule)) {
      const { value } = validationRule;

      if (value === undefined) {
        return undefined;
      }

      if (isValidValue(inputType, key, value)) {
        return value;
      }
      return undefined;
    }

    if (isValidValue(inputType, key, validationRule)) {
      return validationRule;
    }

    return undefined;
  }
  return undefined;
};
