import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Kebab } from '@components/kebab/Kebab';
import { useListingsContext } from '../ListingsContext';
import { CreateListingSharedPageHeader } from '../listingsPage/CreateListingSharedPageHeader';
import { listingsPagePath } from '../listingsPage/listingsPagePath';
import { useCreateListingContext } from './CreateListingContext';

export const CreateListingPricingPageHeader = () => {
  const { scope, subView } = useListingsContext();
  const {
    handleOnClickPublish,
    handleOnClickSaveAsDraft,
    pricingFormHandleSubmit,
  } = useCreateListingContext();
  const navigate = useNavigate();

  return (
    <CreateListingSharedPageHeader category="Operations" title="Create Listing">
      <Button
        label="Publish Listing"
        name="publish"
        onClick={() => {
          pricingFormHandleSubmit(handleOnClickPublish)();
        }}
        variant={ButtonVariants.Primary}
      />
      <Kebab
        ariaLabelPrefix="create listing"
        options={[
          {
            label: 'Save as Draft',
            name: 'draft',
            onClick: () => {
              pricingFormHandleSubmit(handleOnClickSaveAsDraft)();
            },
          },
          {
            label: 'Cancel',
            onClick: () => navigate(listingsPagePath({ scope, subView })),
          },
        ]}
      />
    </CreateListingSharedPageHeader>
  );
};
