import { Tab, TabPanel, Tabs } from '@mui/base';
import cx from 'classnames';
import { useMemo } from 'react';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { IconButton } from '@components/iconButton/IconButton';
import { TabsList } from '@components/tabs/TabsList';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { centsToWholeDollar } from '@utils/currency';
import { formatWeekDays, toShortDateRange } from '@utils/date';
import { ISOTimeAddMinutes, ISOTimeTo12HourTime } from '@utils/time';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import typography from '~styles/typography.scss';
import { useListingsContext } from '../ListingsContext';
import { ListingMenu } from '../menu/ListingMenu';
import {
  listingsOverlap,
  seatingTypeFromIsCommunal,
} from '../utils/listingUtils';
import styles from './ListingDetailsSheet.scss';
import { ListingOverlapWarning } from './ListingOverlapWarning';
import { TableList } from './TableList';

export const ListingDetailsSheet = () => {
  const {
    clearSelectedListing,
    scopedListings,
    selectedListing: listing,
    floorPlan,
  } = useListingsContext();

  // memoize since this can be expensive
  const hasOverlap = useMemo(
    () =>
      listing &&
      listing.status !== 'draft' &&
      scopedListings.some(
        (other) => other.id !== listing.id && listingsOverlap(listing, other),
      ),
    [scopedListings, listing],
  );

  if (!listing) {
    return null;
  }

  const {
    endDate,
    endTime,
    interval,
    inventoryCount,
    maximumGuests,
    minimumGuests,
    name,
    price,
    publicName,
    repeat,
    startDate,
    startTime,
    turnTime,
    pricePoints,
  } = listing;
  const highlightedTables = floorPlan.floorPlanTables.filter((t) =>
    listing.highlightedFloorPlanTableIds.includes(t.id),
  );
  return (
    <article aria-label="Listing Details" className={styles.main}>
      <IconButton
        ariaLabel="close listing details"
        onClick={() => clearSelectedListing()}
        iconName="smallArrowLeft"
        className={styles.closeButton}
      />
      <section className={styles.heading}>
        <div className={styles.nameAndLifeSpan}>
          <h2 className={typography.h5}>{name}</h2>
          <p className={cx(typography.t1, styles.lifespan)}>
            {toShortDateRange(startDate, endDate)}
          </p>
        </div>
        <ListingMenu listing={listing} />
      </section>
      {hasOverlap && <ListingOverlapWarning />}
      <Tabs defaultValue={0}>
        <TabsList>
          <Tab className={typography.t1} value={0}>
            Floor Plan
          </Tab>
          <Tab className={typography.t1} value={1}>
            Reservable Days
          </Tab>
          <FeatureFlagOn<RestaurantAdminFeatureFlag>
            name="pricingManagerFlag"
            element={
              <Tab className={typography.t1} value={2}>
                Pricing
              </Tab>
            }
            fallback={null}
          />
        </TabsList>
        <TabPanel value={0} className={styles.floorPlanTab}>
          <FloorPlan
            className={styles.floorPlan}
            disableControls
            backgroundSrc={floorPlan.backgroundSrc}
            floorPlanTablesRenderer={floorPlanTablesRendererFactory({
              calculateIsHighlighted: (floorPlanTable) =>
                listing.highlightedFloorPlanTableIds.includes(
                  floorPlanTable.id,
                ),
              tables: floorPlan.floorPlanTables,
            })}
          />
          <TableList tables={highlightedTables} />
          <section>
            <h3 className={typography.h7}>Listing Details</h3>
            <dl>
              <div className={styles.detail}>
                <dt className={typography.t1}>Guest Facing Name</dt>
                <dd className={typography.t1}>{publicName}</dd>
              </div>
              <div className={styles.detail}>
                <dt className={typography.t1}>Seating Type</dt>
                <dd className={typography.t1}>
                  {seatingTypeFromIsCommunal(listing.isCommunal)}
                </dd>
              </div>
              <div className={styles.detail}>
                <dt className={typography.t1}>Min-Max Guests</dt>
                <dd className={typography.t1}>
                  {minimumGuests}-{maximumGuests}
                </dd>
              </div>
              <div className={styles.detail}>
                <dt className={typography.t1}>
                  {listing.isCommunal ? 'Number of Seats' : 'Number of Tables'}
                </dt>
                <dd className={typography.t1}>{inventoryCount}</dd>
              </div>
              <div className={styles.detail}>
                <dt className={typography.t1}>Turn Time</dt>
                <dd className={typography.t1}>{turnTime} min</dd>
              </div>
              <div className={styles.detail}>
                <dt className={typography.t1}>Interval</dt>
                <dd className={typography.t1}>{interval} min</dd>
              </div>
            </dl>
          </section>
        </TabPanel>
        <TabPanel value={1} className={styles.reservableDaysTab}>
          <div className={styles.card}>
            <div className={typography.h7m}>{formatWeekDays(repeat)}</div>
            <div className={styles.timeAndPrice}>
              <div
                className={cx(typography.t1, styles.timeRange)}
                data-testid="listing-details-time-range"
              >
                <span className={typography.t1}>
                  {ISOTimeTo12HourTime(startTime)}–
                  {ISOTimeTo12HourTime(endTime)}
                </span>
                <span className={typography.t1}>
                  {` (last turn ends ${ISOTimeTo12HourTime(ISOTimeAddMinutes(endTime, turnTime))})`}
                </span>
              </div>
              <FeatureFlagOn<RestaurantAdminFeatureFlag>
                name="pricingManagerFlag"
                element={null}
                fallback={
                  <div className={typography.h7}>
                    {centsToWholeDollar(price)}
                  </div>
                }
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={2} className={styles.pricingTab}>
          <div className={styles.basePrice}>
            <span className={typography.h7m}>Base Price</span>
            <span className={typography.h7}>{centsToWholeDollar(price)}</span>
          </div>
          {!!pricePoints.length && (
            <ul>
              {pricePoints.map((p) => (
                <li key={p.id}>
                  <div className={styles.timeAndDayRange}>
                    <span className={typography.h7m}>
                      {ISOTimeTo12HourTime(p.startTime)} –{' '}
                      {ISOTimeTo12HourTime(p.endTime)}
                    </span>
                    <span className={typography.t1}>
                      {formatWeekDays(p.activeDays)}
                    </span>
                  </div>
                  <span className={typography.h7}>
                    {centsToWholeDollar(p.price)}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </TabPanel>
      </Tabs>
    </article>
  );
};
