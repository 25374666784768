import type { ReactNode } from 'react';
import { useFeatureFlagContext } from '@shared/context/FeatureFlagContext';

interface FeatureFlagOnProps<FeatureFlag> {
  element: ReactNode;
  fallback: ReactNode;
  name: FeatureFlag;
}

export const FeatureFlagOn = <FeatureFlag extends string>({
  element,
  fallback,
  name,
}: FeatureFlagOnProps<FeatureFlag>) => {
  const { isEnabled } = useFeatureFlagContext();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isEnabled(name) ? element : fallback}</>;
};
