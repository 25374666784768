// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlowStepper__container___ZEjG7{align-items:baseline;display:flex}.FlowStepper__container___ZEjG7>button{background-color:#333;background-color:rgba(0,0,0,0);border:none;color:var(--white80);height:3rem;letter-spacing:.125em;padding-inline:.5rem;text-transform:uppercase;width:3rem}.FlowStepper__container___ZEjG7>button svg{border-radius:50%;border:2px solid rgba(0,0,0,0);fill:var(--white);min-width:1.25rem;transform:translateY(-2px);width:1.25rem}.FlowStepper__container___ZEjG7>button:active svg,.FlowStepper__container___ZEjG7>button:focus svg,.FlowStepper__container___ZEjG7>button:hover svg{background-color:var(--gold);fill:var(--black)}.FlowStepper__container___ZEjG7>button[aria-label=\"previous step\"] svg{transform:scaleX(-1) translateY(-2px)}.FlowStepper__container___ZEjG7>button[disabled]{color:var(--darkGrey200)}.FlowStepper__container___ZEjG7>button[disabled] svg{opacity:.2}.FlowStepper__container___ZEjG7>div{display:flex;gap:.25rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/flowStepper/FlowStepper.scss"],"names":[],"mappings":"AAAA,gCACE,oBAAA,CACA,YAAA,CAGA,uCACE,qBAAA,CACA,8BAAA,CACA,WAAA,CACA,oBAAA,CACA,WAAA,CACA,qBAAA,CACA,oBAAA,CACA,wBAAA,CACA,UAAA,CAEA,2CACE,iBAAA,CACA,8BAAA,CACA,iBAAA,CACA,iBAAA,CACA,0BAAA,CACA,aAAA,CAMA,oJACE,4BAAA,CACA,iBAAA,CAKF,uEACE,qCAAA,CAIJ,iDACE,wBAAA,CAEA,qDACE,UAAA,CAKN,oCACE,YAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FlowStepper__container___ZEjG7"
};
export default ___CSS_LOADER_EXPORT___;
