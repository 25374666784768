import { useSwitch } from '@mui/base/useSwitch';
import cx from 'classnames';
import { type InputHTMLAttributes } from 'react';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './UpcomingTimerSwitch.scss';

export const UpcomingTimerSwitch = (
  props: InputHTMLAttributes<HTMLInputElement>,
) => {
  const { getInputProps, checked } = useSwitch(props);

  return (
    <label
      className={cx(styles.label, typography.h7)}
      htmlFor="show-upcoming-timers"
    >
      <Icon
        ariaLabel="Show Upcoming Timers"
        className={styles.hourglass}
        name="hourglass"
      />
      <div className={styles.switchTrack} data-testid="switch-track">
        <span className={styles.switchThumb} data-testid="switch-thumb" />
        <input
          aria-checked={checked}
          className={styles.switchInput}
          id="show-upcoming-timers"
          role="switch"
          {...getInputProps()}
          type="checkbox"
        />
      </div>
    </label>
  );
};
