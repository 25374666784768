import { Status, StatusPill } from '../statusPill/StatusPill';
import styles from './ReservationGuestSheetStatuses.scss';

export interface ReservationGuestSheetStatusesProps {
  isCanceled?: boolean;
  isHostBooked?: boolean;
  isNoShow?: boolean;
  isStranded?: boolean;
  showHostBooked?: boolean;
}

export const ReservationGuestSheetStatuses = ({
  isCanceled = false,
  isHostBooked = false,
  isNoShow = false,
  isStranded = false,
  showHostBooked = false,
}: ReservationGuestSheetStatusesProps) => (
  <div className={styles.container}>
    {isCanceled && <StatusPill status={Status.Canceled} />}
    {isHostBooked && showHostBooked && (
      <StatusPill status={Status.HostBooked} />
    )}
    {isNoShow && <StatusPill status={Status.NoShow} />}
    {isStranded && <StatusPill status={Status.Stranded} />}
  </div>
);
