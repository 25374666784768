import cx from 'classnames';
import type { ReactNode } from 'react';
import { FlowStepper } from 'restaurantAdmin/operations/listings/flowStepper/FlowStepper';
import typography from '~styles/typography.scss';
import styles from './CreateListingSharedPageHeader.scss';

export interface CreateListingSharedPageHeaderProps {
  category?: string;
  children?: ReactNode;
  title: string;
}

export const CreateListingSharedPageHeader = ({
  category,
  children,
  title,
}: CreateListingSharedPageHeaderProps) => (
  <header className={styles.pageHeader}>
    <div className={styles.pageMargin}>
      <h1 className={typography.h4}>
        {category && (
          <div className={cx(styles.category, typography.t3)}>{category}</div>
        )}
        {title}
      </h1>
      <FlowStepper />
      <div className={styles.actions}>{children}</div>
    </div>
  </header>
);
