import { useWatch } from 'react-hook-form';
import { useFeatureFlagContext } from '@shared/context/FeatureFlagContext';
import { EditListingReservableDaysPageBody } from '../edit/EditListingReservableDaysPageBody';
import styles from '../listingsPage/CreateEditListingPageLayout.scss';
import { useCreateListingContext } from './CreateListingContext';
import { CreateListingReservableDaysPageHeader } from './CreateListingReservableDaysPageHeader';

export const CreateListingReservableDaysPage = () => {
  const {
    floorPlanControl,
    handleOnClickConfigureFloorPlan,
    handleOnSubmitReservableDays,
    reservableDaysControl,
  } = useCreateListingContext();

  const publicName = useWatch({
    control: floorPlanControl,
    name: 'publicName',
  });

  const { isEnabled } = useFeatureFlagContext();

  const pricingManagerFlagEnabled = isEnabled('pricingManagerFlag');

  return (
    <form
      className={styles.pageWrapper}
      noValidate
      onSubmit={
        pricingManagerFlagEnabled
          ? handleOnSubmitReservableDays
          : handleOnClickConfigureFloorPlan
      }
    >
      <CreateListingReservableDaysPageHeader />
      <EditListingReservableDaysPageBody
        floorPlanControl={floorPlanControl}
        listingName={publicName}
        reservableDaysControl={reservableDaysControl}
      />
    </form>
  );
};
