import cx from 'classnames';
import { kebabCase } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './FlowStep.scss';

export interface FlowStepProps {
  hasErrors: boolean;
  isComplete: boolean;
  label: string;
  path: string;
}

export const FlowStep = ({
  hasErrors,
  isComplete,
  label,
  path,
}: FlowStepProps) => {
  const labelId = `label-${kebabCase(label)}`;

  const isCurrentStep = path === useLocation().pathname;

  const containerStyle = cx(styles.container, {
    [styles.current]: isCurrentStep,
    [styles.complete]: isComplete,
    [styles.error]: hasErrors,
  });

  return (
    <button
      aria-labelledby={labelId}
      className={containerStyle}
      disabled={isCurrentStep}
      name={label}
      type="submit"
    >
      <Icon name="stepper" />
      <span className={typography.t3} id={labelId}>
        {label}
      </span>
    </button>
  );
};
