import cx from 'classnames';
import { type MouseEventHandler } from 'react';
import { Avatar } from '@components/avatar/Avatar';
import { Icon } from '@components/icon/Icon';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { getFullName } from '@utils/formatName';
import { ISOTimeTo12HourTime } from '@utils/time';
import { isReservationFinished } from 'restaurantAdmin/reservations/reservationUtils';
import typography from '~styles/typography.scss';
import { Status, StatusTag } from '../../../components/statusTag/StatusTag';
import type { OccupantsReservation } from '../apiHelpers';
import { CancellationFeeStatusTag } from './CancellationFeeStatusTag';
import styles from './ReservationCard.scss';

interface ReservationCardProps {
  isSelected?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  reservation: OccupantsReservation;
}

export const ReservationCard = ({
  isSelected = false,
  onClick,
  reservation,
}: ReservationCardProps) => {
  const {
    guest,
    guestCount,
    hasDietaryRestrictionOrAllergy,
    hasNote,
    isEligibleForCancellationFee,
    isStranded,
    isVIP,
    listingName,
    seatedTableName,
    time,
  } = reservation;

  const isCanceled = reservation.status === 'canceled';
  const isFinished = isReservationFinished(reservation.status);
  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;
  const isNoShow = reservation.status === 'noShow';
  const isSeated = seatedTableName && !isFinished;
  const cancellationFee = reservation.cancellationPolicy?.fee || null;

  return (
    <li
      aria-current={isSelected}
      aria-label={`reservation card for ${reservation.guest.firstName} ${reservation.guest.lastName}`}
      className={styles.card}
    >
      <button className={styles.cardButton} onClick={onClick} type="button">
        <div className={styles.avatarAndInfoStack}>
          <Avatar
            className={styles.avatar}
            firstName={guest.firstName}
            lastName={guest.lastName}
          />
          <div className={styles.infoContainer}>
            <div className={styles.timeAndStatusRow}>
              <div className={cx(typography.h8, styles.time)}>
                {ISOTimeTo12HourTime(time)}
                {isVIP && (
                  <Icon
                    ariaLabel="Is a VIP"
                    className={cx(styles.vipIcon)}
                    name="crown"
                    role="img"
                  />
                )}
                {isHostBooked && (
                  <Icon
                    ariaLabel="Host booked"
                    className={styles.fillIcon}
                    name="roomService"
                    role="img"
                  />
                )}
                {hasNote && (
                  <Icon
                    ariaLabel="Has notes"
                    className={cx(styles.strokeIcon, styles.hasNoteIcon)}
                    name="note"
                    role="img"
                  />
                )}
                {hasDietaryRestrictionOrAllergy && (
                  <Icon
                    ariaLabel="Has dietary restriction or allergy"
                    name="cutlery"
                    role="img"
                  />
                )}
              </div>
              {isSeated && (
                <div className={cx(typography.t3, styles.status)}>Seated</div>
              )}
              {isFinished && (
                <div className={cx(typography.t3, styles.status)}>
                  Finished (Table {seatedTableName})
                </div>
              )}
              {isCanceled && <StatusTag status={Status.Canceled} />}
              {isNoShow && <StatusTag status={Status.NoShow} />}
              {isStranded && <StatusTag status={Status.Stranded} />}
            </div>
            <div className={styles.nameAndGuestCountRow}>
              <div className={styles.userFullName}>
                {getFullName(guest.firstName, guest.lastName)}
              </div>
              <div className={styles.listingNameAndGuestStack}>
                <div className={cx(typography.t3, styles.listingName)}>
                  {listingName}
                </div>
                <div
                  aria-label={appendGuestCountLabel(guestCount)}
                  className={styles.guestCount}
                >
                  <Icon name="couple" /> {guestCount}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CancellationFeeStatusTag
          isEligibleForCancellationFee={isEligibleForCancellationFee}
          fee={cancellationFee}
          status={reservation.cancellationFeeStatus}
        />
      </button>
    </li>
  );
};
