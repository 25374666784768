import { Option } from '@mui/base/Option';
import type { SelectRootSlotProps } from '@mui/base/Select';
import { Select } from '@mui/base/Select';
import cx from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import styles from './ControlledFormTimeSelect.scss';
import { FormTooltip } from './FormTooltip';
import { ValidationErrorMessage } from './ValidationErrorMessage';

/**
 * This type is used for ControlledFormTimeSelect.
 * Generally we prefer ISO time strings (HH:mm:ss) over this type.
 */
export type TimeValues =
  | [hour: string, minute: string, meridiem: string]
  | string[];

const HOUR_OPTIONS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
const MINUTE_OPTIONS = ['00', '15', '30', '45'];
const MERIDIEM_OPTIONS = ['AM', 'PM'];

export interface ControlledFormTimeSelectProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  label: string;
  name: Name;
  tooltipText?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  disabled?: boolean;
  defaultValue?: PathValue<T, Name>;
  variant?: LayoutVariant;
}

// this custom root component was needed to add a chevron
const SelectButton = forwardRef(
  <TValue extends object, Multiple extends boolean>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ownerState, ...rest } = props;
    return (
      <button type="button" {...rest} ref={ref}>
        {rest.children}
        <Icon name="chevron" />
      </button>
    );
  },
);
SelectButton.displayName = 'SelectButton';

const getTimeValues = (values: string[]) => {
  const hour = values.findLast((value) => HOUR_OPTIONS.includes(value));
  const minute = values.findLast((value) => MINUTE_OPTIONS.includes(value));
  const meridiem = values.findLast((value) => MERIDIEM_OPTIONS.includes(value));

  return [hour, minute, meridiem];
};

export const ControlledFormTimeSelect = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  label,
  name,
  tooltipText,
  rules,
  disabled = false,
  defaultValue,
  variant = LayoutVariant.Vertical,
}: ControlledFormTimeSelectProps<T, Name>) => {
  const {
    field: { onChange, value: selectedValues },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const {
    isOpen: isListboxOpen,
    close: closeListbox,
    toggle: toggleListbox,
  } = useIsOpen();
  const { ref } = useHandleClickOutside(closeListbox);

  return (
    <div
      className={cx(
        {
          [styles.fieldContainer]: true,
          [styles[variant]]: true,
        },
        className,
      )}
      ref={ref}
    >
      <div className={styles.labelContainer}>
        <label
          htmlFor={`${name}-select`}
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!error,
          })}
        >
          {label}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      <div className={styles.selectContainer}>
        <Select
          aria-errormessage={`${name}-error`}
          aria-invalid={!!error}
          id={`${name}-select`}
          disabled={disabled}
          className={cx({
            [typography.t1]: true,
            [styles.select]: true,
            [styles.selectError]: !!error,
          })}
          slotProps={{
            popup: {
              disablePortal: true,
              style: { zIndex: 10, boxShadow: '0 16px 16px 0 #272f3e66' },
            },
            listbox: {
              style: {
                overflowY: 'hidden',
                borderRadius: '5px',
                marginTop: '8px',
                display: 'flex',
                backgroundColor: 'var(--darkGrey100)',
              },
            },
          }}
          slots={{
            root: SelectButton,
          }}
          multiple
          onChange={(_e, values: string[]) => {
            if (values.length >= selectedValues.length) {
              onChange(getTimeValues(values));
            }
          }}
          value={selectedValues}
          renderValue={(values) => {
            const [hour = '--', minute = '--', meridiem = '--'] = getTimeValues(
              values.map((value) => value.value),
            );

            return `${hour}:${minute} ${meridiem}`;
          }}
          listboxOpen={isListboxOpen}
          onClick={toggleListbox}
        >
          <ul className={styles.optionsList}>
            {HOUR_OPTIONS.map((hour) => (
              <Option
                tabIndex={0}
                key={hour}
                value={hour}
                className={cx({
                  [styles.option]: true,
                  [typography.t1]: true,
                  [styles.selected]: selectedValues?.includes(hour),
                })}
              >
                {hour}
              </Option>
            ))}
          </ul>
          <ul className={styles.optionsList}>
            {MINUTE_OPTIONS.map((minute) => (
              <Option
                tabIndex={0}
                key={minute}
                value={minute}
                className={cx({
                  [styles.option]: true,
                  [typography.t1]: true,
                  [styles.selected]: selectedValues?.includes(minute),
                })}
              >
                {minute}
              </Option>
            ))}
          </ul>
          <ul className={styles.optionsList}>
            {MERIDIEM_OPTIONS.map((meridiem) => (
              <Option
                tabIndex={0}
                onClick={closeListbox}
                key={meridiem}
                value={meridiem}
                className={cx({
                  [styles.option]: true,
                  [typography.t1]: true,
                  [styles.selected]: selectedValues?.includes(meridiem),
                })}
              >
                {meridiem}
              </Option>
            ))}
          </ul>
        </Select>
        <ValidationErrorMessage error={error} label={label} name={name} />
      </div>
    </div>
  );
};
