import { type Control, useWatch } from 'react-hook-form';
import {
  isCompleteTimeValue,
  parseTimeValues,
} from '@components/formInputs/timeValuesUtils';
import {
  type ListingFloorPlanFormData,
  type ListingReservableDaysFormData,
} from '../types';
import { AdminCalendar } from './AdminCalendar';

export interface ControlledAdminCalendarProps {
  className?: string;
  floorPlanControl: Control<ListingFloorPlanFormData>;
  reservableDaysControl: Control<ListingReservableDaysFormData>;
}

export const EditListingAdminCalendar = ({
  className,
  floorPlanControl,
  reservableDaysControl,
}: ControlledAdminCalendarProps) => {
  const [startDate, endDate, repeat, startTime, endTime] = useWatch({
    control: reservableDaysControl,
    name: ['startDate', 'endDate', 'repeat', 'startTime', 'endTime'],
  });
  const turnTime = useWatch({
    control: floorPlanControl,
    name: 'turnTime',
  });

  const isComplete = !!(
    startDate &&
    repeat &&
    repeat.length > 0 &&
    startTime &&
    endTime &&
    isCompleteTimeValue(startTime) &&
    isCompleteTimeValue(endTime)
  );

  const highlightedListing = isComplete
    ? {
        startDate,
        endDate,
        repeat,
        startTime: parseTimeValues(startTime),
        endTime: parseTimeValues(endTime),
        turnTime,
      }
    : undefined;

  return (
    <AdminCalendar
      className={className}
      highlightedListing={highlightedListing}
      interactive={false}
    />
  );
};
