import { useDroppable } from '@dnd-kit/core';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';

interface DroppableFloorPlanProps {
  backgroundSrc: string;
  floorPlanTablesRenderer: FloorPlanTablesRenderer;
}

export const DroppableFloorPlan = ({
  backgroundSrc,
  floorPlanTablesRenderer,
}: DroppableFloorPlanProps) => {
  const { setNodeRef } = useDroppable({
    id: backgroundSrc,
  });
  return (
    <div ref={setNodeRef}>
      <FloorPlan
        backgroundSrc={backgroundSrc}
        floorPlanTablesRenderer={floorPlanTablesRenderer}
        disableControls
      />
    </div>
  );
};
