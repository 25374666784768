import { useEffect, useReducer, useState } from 'react';
import { createResponseError } from '@shared/api/createResponseError';
import { type ApiResponse, isErrorResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import { useError } from '../../../errors/useError';
import {
  getServiceReservationDetails,
  getServiceWalkInDetails,
  type ServiceOccupant,
} from '../apiHelpers';
import type { OccupantType } from '../state/types';

export interface OccupantId {
  id: string;
  type: OccupantType;
}

export const useFetchOccupantDetails = (id: OccupantId | null) => {
  const { id: restaurantId } = useRestaurant();
  const [occupant, setOccupant] = useState<ServiceOccupant>();
  const [invalidationKey, invalidate] = useReducer((n: number) => n + 1, 0);
  const setError = useError();
  useEffect(() => {
    if (!id) return undefined;
    const { id: occupantId, type: occupantType } = id;
    const getOccupantDetails = getOccupantDetailsFunction(occupantType);
    let isCurrent = true;
    void (async () => {
      const response = await getOccupantDetails(restaurantId, occupantId);
      if (isCurrent) {
        if (isErrorResponse(response)) {
          setError(createResponseError(response));
        } else {
          setOccupant(response);
        }
      }
    })();
    return () => {
      isCurrent = false;
    };
  }, [restaurantId, id, invalidationKey]);
  return { occupant, invalidate };
};

type GetOccupantDetails = (
  restaurantId: string,
  occupantId: string,
) => Promise<ApiResponse<ServiceOccupant>>;

const getOccupantDetailsFunction = (
  occupantType: string,
): GetOccupantDetails => {
  if (occupantType === 'reservation') return getServiceReservationDetails;
  if (occupantType === 'walkIn') return getServiceWalkInDetails;
  throw new TypeError('occupantType fallthrough');
};
