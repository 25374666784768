import { useWatch } from 'react-hook-form';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import { EditListingPricingPageBody } from '../edit/EditListingPricingPageBody';
import styles from '../listingsPage/CreateEditListingPageLayout.scss';
import { useCreateListingContext } from './CreateListingContext';
import { CreateListingPricingPageHeader } from './CreateListingPricingPageHeader';

export const CreateListingPricingPage = () => {
  const {
    floorPlanControl,
    handleOnSubmitPricing,
    pricingControl,
    pricingFormGetValues,
    pricingFormHandleSubmit,
    pricingFormSetValue,
    reservableDaysFormGetValues,
  } = useCreateListingContext();

  const listingName = useWatch({
    control: floorPlanControl,
    name: 'publicName',
  });

  return (
    <FeatureFlagOn<RestaurantAdminFeatureFlag>
      element={
        <form className={styles.pageWrapper} onSubmit={handleOnSubmitPricing}>
          <CreateListingPricingPageHeader />
          <EditListingPricingPageBody
            listingName={listingName}
            pricingControl={pricingControl}
            pricingFormGetValues={pricingFormGetValues}
            pricingFormHandleSubmit={pricingFormHandleSubmit}
            pricingFormSetValue={pricingFormSetValue}
            reservableDaysFormValues={reservableDaysFormGetValues()}
          />
        </form>
      }
      fallback={null}
      name="pricingManagerFlag"
    />
  );
};
