import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Icon } from '@components/icon/Icon';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { IPAD1024 } from '@shared/styles/breakpoints';
import { deleteCoverLimit, updateCoverLimit } from '../apiHelpers';
import styles from './CoverLimitForm.scss';

interface CoverLimitFormData {
  coverLimit: string;
}

export interface CoverLimitFormProps {
  coverLimit: string | null;
  exitEditMode: () => void;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const CoverLimitForm = ({
  coverLimit,
  exitEditMode,
  refreshRestaurant,
  restaurantId,
}: CoverLimitFormProps) => {
  const { control, handleSubmit } = useForm<CoverLimitFormData>({
    defaultValues: {
      coverLimit: coverLimit || undefined,
    },
  });

  const handleOnSubmit = handleSubmit(async (data) => {
    const isSuccessfulUpdate = await updateCoverLimit(
      restaurantId,
      Number(data.coverLimit),
    );

    if (isSuccessfulUpdate) {
      await refreshRestaurant();
      successToast({ message: 'Cover Limit successfully updated' });
      exitEditMode();
    } else {
      errorToast({ message: 'Failed to update Cover Limit' });
    }
  });

  const handleOnDelete = (): void => {
    void (async () => {
      const response = await deleteCoverLimit(restaurantId);
      if (response.ok) {
        await refreshRestaurant();
        successToast({ message: 'Cover Limit successfully deleted' });
        exitEditMode();
      } else {
        errorToast({ message: 'Failed to delete Cover Limit' });
      }
    })();
  };

  const layoutVariant = useMediaQuery({ minWidth: IPAD1024 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  return (
    <form onSubmit={handleOnSubmit} className={styles.form}>
      <ControlledFormInput
        control={control}
        label="Cover Limit"
        name="coverLimit"
        rules={{
          min: {
            message: `Must be at least 1`,
            value: 1,
          },
          required: true,
        }}
        type="number"
        variant={layoutVariant}
      />
      <div className={styles.actions}>
        {!!coverLimit && (
          <button
            aria-label="Remove Cover Limit"
            type="button"
            className={styles.deleteButton}
            onClick={handleOnDelete}
          >
            <Icon ariaLabel="Remove Cover Limit" name="trash" />
            <span className={styles.buttonText}>Remove Cover Limit</span>
          </button>
        )}
        <Button
          variant={ButtonVariants.Tertiary}
          label="Discard Edits"
          onClick={exitEditMode}
        />
        <Button
          label="Save Changes"
          type="submit"
          variant={ButtonVariants.Primary}
        />
      </div>
    </form>
  );
};
