import { type Control } from 'react-hook-form';
import { Card } from '@components/card/Card';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import typography from '~styles/typography.scss';
import { EditListingAdminCalendar } from '../calendar/EditListingAdminCalendar';
import { ListingLifeSpanFieldSet } from '../form/ListingLifeSpanFieldSet';
import { PriceFieldSet } from '../form/PriceFieldSet';
import { ReservableDaysFieldSet } from '../form/ReservableDaysFieldSet';
import { ReservableTimesFieldSet } from '../form/ReservableTimesFieldSet';
import pageLayoutStyles from '../listingsPage/CreateEditListingPageLayout.scss';
import type {
  ListingFloorPlanFormData,
  ListingReservableDaysFormData,
} from '../types';
import styles from './EditListingReservableDaysPageBody.scss';

interface EditListingReservableDaysPageBodyProps {
  floorPlanControl: Control<ListingFloorPlanFormData>;
  listingName: string;
  reservableDaysControl: Control<ListingReservableDaysFormData>;
}

export const EditListingReservableDaysPageBody = ({
  floorPlanControl,
  listingName,
  reservableDaysControl,
}: EditListingReservableDaysPageBodyProps) => (
  <PageContent className={pageLayoutStyles.main}>
    <Card className={pageLayoutStyles.displayPanel}>
      <EditListingAdminCalendar
        className={styles.calendar}
        floorPlanControl={floorPlanControl}
        reservableDaysControl={reservableDaysControl}
      />
    </Card>
    <div className={pageLayoutStyles.sidePanelContainer}>
      <Card bordered className={pageLayoutStyles.sidePanel}>
        <h2 className={typography.h7}>Reservable Days</h2>
        <h3 className={typography.h4}>{listingName}</h3>
        <ListingLifeSpanFieldSet control={reservableDaysControl} />
        <ReservableDaysFieldSet control={reservableDaysControl} />
        <ReservableTimesFieldSet control={reservableDaysControl} />
        <FeatureFlagOn<RestaurantAdminFeatureFlag>
          element={null}
          fallback={<PriceFieldSet control={reservableDaysControl} />}
          name="pricingManagerFlag"
        />
      </Card>
    </div>
  </PageContent>
);
