import cx from 'classnames';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { generateTimes } from '../utils/listingUtils';
import styles from './AdminCalendar.scss';

export const CalendarTimeColumn = () => (
  <>
    {generateTimes().map((time, index) => (
      <div
        key={time}
        className={cx(typography.t2, styles.time)}
        style={{ gridArea: `${2 + index} / 1` }}
      >
        {index % 4 === 0 ? ISOTimeTo12HourTime(time) : undefined}
      </div>
    ))}
  </>
);
