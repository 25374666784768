import cx from 'classnames';
import { get } from 'lodash-es';
import { type FieldErrors, type FieldValues, type Path } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ValidationErrorMessage.scss';

interface ValidationErrorMessageProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  label: string;
  name: Path<T>;
}
export const ValidationErrorMessage = <T extends FieldValues>({
  errors,
  label,
  name,
}: ValidationErrorMessageProps<T>) => {
  const error = get(errors, name);

  if (!error) {
    return null;
  }

  const errorMessage =
    String(error.message) || `${label} ${String(error.type)}`;
  return (
    <div
      className={cx(typography.t2, styles.errorMessage)}
      id={`${name}-error`}
    >
      {errorMessage}
    </div>
  );
};
