import { useForm, useWatch } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { IPAD1024 } from '@shared/styles/breakpoints';
import {
  getListings,
  type Listing,
} from 'restaurantAdmin/operations/listings/apiHelpers';
import { updateMaxGuestCount } from '../apiHelpers';
import { MaxGuestCountConflictModal } from './MaxGuestCountConflictModal';
import styles from './MaxGuestCountForm.scss';

export const maxGuestCountTip = (
  maxParty: number | string,
  phone: string | null,
) =>
  `Given a Maximum Guest Count of ${maxParty}, when a person seeking a ` +
  'reservation sets the guest count higher than this number, they ' +
  `will see the message “For parties of ${Number(maxParty) + 1} or more ` +
  `guests, please call ${phone || 'the restaurant directly'}.” ` +
  'Listings should not have a max guest count higher than this value.';

interface MaxGuestCountFormData {
  maxGuestCount: number;
}

export interface MaxGuestCountFormProps {
  exitEditMode: () => void;
  maxGuestCount: number;
  phone: string | null;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const MaxGuestCountForm = ({
  exitEditMode,
  maxGuestCount,
  phone,
  refreshRestaurant,
  restaurantId,
}: MaxGuestCountFormProps) => {
  const { control, getValues, handleSubmit } = useForm<MaxGuestCountFormData>({
    defaultValues: {
      maxGuestCount,
    },
  });
  const {
    isOpen: isMaxGuestCountConflictModalOpen,
    close: closeMaxGuestCountConflictModal,
    open: openMaxGuestCountConflictModal,
  } = useIsOpen();
  const currentMaxGuestCount = useWatch({
    control,
    name: 'maxGuestCount',
  });

  const submitMaxGuestCount = async (
    data: MaxGuestCountFormData,
  ): Promise<void> => {
    const isSuccessfulUpdate = await updateMaxGuestCount(
      restaurantId,
      data.maxGuestCount,
    );

    if (isSuccessfulUpdate) {
      await refreshRestaurant();
      successToast({ message: 'Maximum Guest Count successfully updated' });
      exitEditMode();
    } else {
      errorToast({ message: 'Failed to update Maximum Guest Count' });
    }
  };

  const confirmMaxGuestCount = async (data: MaxGuestCountFormData) => {
    let response: Listing[];
    try {
      response = await getListings(restaurantId, false);
    } catch (e) {
      errorToast({ message: 'Failed to fetch listings. Please try again.' });
      reportAppError(e);
      return;
    }
    const hasMaxGuestCountConflict = response.some(
      (listing) => listing.maximumGuests > data.maxGuestCount,
    );

    if (hasMaxGuestCountConflict) {
      openMaxGuestCountConflictModal();
      return;
    }

    await submitMaxGuestCount(data);
  };

  const layoutVariant = useMediaQuery({ minWidth: IPAD1024 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  return (
    <>
      <form
        onSubmit={handleSubmit(confirmMaxGuestCount)}
        className={styles.form}
      >
        <ControlledFormInput
          control={control}
          label="Max Guest Count"
          name="maxGuestCount"
          rules={{
            max: {
              message: 'Must not exceed 1,000',
              value: 1000,
            },
            min: {
              message: 'Must be at least 1',
              value: 1,
            },
            required: true,
          }}
          tooltipText={maxGuestCountTip(currentMaxGuestCount, phone)}
          type="number"
          variant={layoutVariant}
        />
        <div className={styles.actions}>
          <Button
            label="Discard Edits"
            onClick={exitEditMode}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            label="Save Changes"
            type="submit"
            variant={ButtonVariants.Primary}
          />
        </div>
      </form>
      <MaxGuestCountConflictModal
        closeModal={closeMaxGuestCountConflictModal}
        isOpen={isMaxGuestCountConflictModalOpen}
        handleConfirm={() => {
          void submitMaxGuestCount(getValues());
        }}
      />
    </>
  );
};
