// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HookAwareCheckboxInput__container___YE8Um input{margin:1rem}", "",{"version":3,"sources":["webpack://./src/shared/components/hookAwareCheckboxInput/HookAwareCheckboxInput.scss"],"names":[],"mappings":"AACE,iDACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HookAwareCheckboxInput__container___YE8Um"
};
export default ___CSS_LOADER_EXPORT___;
