import cx from 'classnames';
import { forwardRef } from 'react';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import {
  calculateContainerStyle,
  calculateIconStyle,
} from '@components/floorPlan/utils';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import { getAnonymousName } from '@shared/utils/formatName';
import type { SeatedOccupant } from '../../../floorPlans/apiHelpers';
import { ServiceStatusIcon } from '../serviceStatus/ServiceStatusIcon';
import styles from './SeatedFloorPlanTable.scss';

export interface SeatedFloorPlanTableProps extends TableIconCommonProps {
  seatedOccupant: SeatedOccupant;
  tableName: string;
  isDisabled: boolean;
  isHighlighted: boolean;
  isAnyTableHighlighted: boolean;
  handleTableOnClick?: () => void;
}

export const SeatedFloorPlanTable = forwardRef<
  HTMLDivElement,
  SeatedFloorPlanTableProps
>(
  (
    {
      handleTableOnClick,
      iconName,
      iconWidthScale,
      isAnyTableHighlighted,
      isDisabled,
      isHighlighted,
      left,
      orientation,
      seatedOccupant,
      tableIconScale,
      tableName,
      testId,
      top,
    },
    ref,
  ) => {
    const fullName = getAnonymousName(
      seatedOccupant.firstName,
      seatedOccupant.lastName,
    );
    const tooltip = `${fullName} seated at table ${tableName}, party of ${seatedOccupant.guestCount}`;

    const iconSuffix = () => {
      if (isHighlighted) return 'Selected';
      return '';
    };

    const newIconName = `${iconName}${iconSuffix()}` as AnyIcon;

    const iconClassNames = cx({
      [styles.serviceStatusIcon]: true,
      [styles.isHighlighted]: isHighlighted && isAnyTableHighlighted,
      [styles.isDimmed]: !isHighlighted && isAnyTableHighlighted,
    });

    return (
      <div
        className={styles.container}
        data-testid={testId}
        ref={ref}
        style={calculateContainerStyle({
          left,
          tableIconScale,
          top,
        })}
        title={tooltip}
      >
        <button
          aria-label={`View details for table ${tableName}`}
          className={styles.button}
          disabled={isDisabled}
          onClick={handleTableOnClick}
          type="button"
        >
          <ServiceStatusIcon
            serviceStatus={seatedOccupant.serviceStatus}
            className={iconClassNames}
            scale={tableIconScale * Number(iconWidthScale)}
          />
          <Icon
            name={newIconName}
            style={calculateIconStyle({
              iconWidthScale,
              orientation,
              tableIconScale,
            })}
            testId={`icon-${newIconName}`}
          />
        </button>
      </div>
    );
  },
);

SeatedFloorPlanTable.displayName = 'SeatedFloorPlanTable';
