import { api } from '../../api';
import { type RestaurantReservationsReportResponse } from '../apiHelpers';

export const getRestaurantsReservationsReport = async (
  restaurantId: string,
  date: string,
  signal?: AbortSignal,
): Promise<RestaurantReservationsReportResponse> =>
  api.get(`/restaurants/${restaurantId}/reservations-report?date=${date}`, {
    signal,
  });
