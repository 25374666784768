// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestTagPill__tag___yQAm2{display:flex;flex-direction:row;align-items:center;width:100%;column-gap:.5rem;margin-top:.5rem}.GuestTagPill__tag___yQAm2 .GuestTagPill__pill___pTIn1{border-radius:80px;border:1px solid var(--white);display:flex;flex-direction:row;align-items:center;justify-content:center;padding:4px 8px;font-weight:600;white-space:nowrap}.GuestTagPill__tag___yQAm2 .GuestTagPill__pill___pTIn1 svg{margin-right:4px;width:16px}.GuestTagPill__tag___yQAm2>span{color:var(--white70);font-style:italic;white-space:nowrap;width:100%;text-overflow:ellipsis;overflow-x:hidden}.GuestTagPill__tag___yQAm2 .GuestTagPill__green___Z8_vj{border:1px solid var(--green200);color:var(--green200)}.GuestTagPill__tag___yQAm2 .GuestTagPill__white___J2Qk0{border:1px solid var(--white);color:var(--white)}.GuestTagPill__tag___yQAm2 .GuestTagPill__gold___ySFWt{border:1px solid var(--gold);color:var(--gold);background-color:var(--darkGrey400)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestTags/GuestTagPill/GuestTagPill.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CACA,gBAAA,CACA,gBAAA,CAEA,uDACE,kBAAA,CACA,6BAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAEA,2DACE,gBAAA,CACA,UAAA,CAIJ,gCACE,oBAAA,CACA,iBAAA,CACA,kBAAA,CACA,UAAA,CACA,sBAAA,CACA,iBAAA,CAGF,wDACE,gCAAA,CACA,qBAAA,CAGF,wDACE,6BAAA,CACA,kBAAA,CAGF,uDACE,4BAAA,CACA,iBAAA,CACA,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "GuestTagPill__tag___yQAm2",
	"pill": "GuestTagPill__pill___pTIn1",
	"green": "GuestTagPill__green___Z8_vj",
	"white": "GuestTagPill__white___J2Qk0",
	"gold": "GuestTagPill__gold___ySFWt"
};
export default ___CSS_LOADER_EXPORT___;
