import { useNavigate } from 'react-router-dom';
import { Kebab } from '@components/kebab/Kebab';
import { CreateListingSharedPageHeader } from 'restaurantAdmin/operations/listings/listingsPage/CreateListingSharedPageHeader';
import { useListingsContext } from '../ListingsContext';
import { listingsPagePath } from '../listingsPage/listingsPagePath';

export const CreateListingFloorPlanPageHeader = () => {
  const navigate = useNavigate();
  const { scope, subView } = useListingsContext();

  return (
    <CreateListingSharedPageHeader category="Operations" title="Create Listing">
      <Kebab
        ariaLabelPrefix="create listing"
        options={[
          {
            label: 'Cancel',
            onClick: () => navigate(listingsPagePath({ scope, subView })),
          },
        ]}
      />
    </CreateListingSharedPageHeader>
  );
};
