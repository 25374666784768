// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HookAwareFormInput__fieldContainer___jMvHh{align-items:center;display:flex;justify-content:space-between}.HookAwareFormInput__inputContainer____LkAH{display:flex;flex-direction:column;gap:.25em;min-width:0}.HookAwareFormInput__inputContainer____LkAH input{background-color:var(--darkGrey100);border-radius:5px;border:3px solid var(--darkGrey100);color:var(--darkBlue);height:3rem;padding:1rem;width:15rem}.HookAwareFormInput__inputContainer____LkAH input:active,.HookAwareFormInput__inputContainer____LkAH input:focus,.HookAwareFormInput__inputContainer____LkAH input:hover{border:3px solid var(--white)}.HookAwareFormInput__inputContainer____LkAH input.HookAwareFormInput__inputError___v6vq7{background-color:var(--red100);color:var(--pink)}.HookAwareFormInput__inputContainer____LkAH input[type=date]::-webkit-calendar-picker-indicator{margin-right:45%}.HookAwareFormInput__labelContainer___lYcnT{align-items:center;display:flex;gap:8px}.HookAwareFormInput__labelContainer___lYcnT .HookAwareFormInput__labelError___yZ2V5{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/hookAwareFormInput/HookAwareFormInput.scss"],"names":[],"mappings":"AAAA,4CACE,kBAAA,CACA,YAAA,CACA,6BAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,WAAA,CAEA,kDACE,mCAAA,CACA,iBAAA,CACA,mCAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CACA,WAAA,CAEA,yKAGE,6BAAA,CAGF,yFACE,8BAAA,CACA,iBAAA,CAIJ,gGACE,gBAAA,CAIJ,4CACE,kBAAA,CACA,YAAA,CACA,OAAA,CAEA,oFACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldContainer": "HookAwareFormInput__fieldContainer___jMvHh",
	"inputContainer": "HookAwareFormInput__inputContainer____LkAH",
	"inputError": "HookAwareFormInput__inputError___v6vq7",
	"labelContainer": "HookAwareFormInput__labelContainer___lYcnT",
	"labelError": "HookAwareFormInput__labelError___yZ2V5"
};
export default ___CSS_LOADER_EXPORT___;
