import cx from 'classnames';
import type { FormEvent } from 'react';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Card } from '@components/card/Card';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { isValid } from '@utils/passwordValidator';
import typography from '~styles/typography.scss';
import { ROOT_PATH } from '../paths';
import { updateAdminPassword } from './apiHelpers';
import styles from './styles.scss';

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [result, setResult] = useState<{ success: boolean; error?: string }>();

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!token) return;

    try {
      await updateAdminPassword(password, token);
      setResult({ success: true });
    } catch (e) {
      if (e instanceof ApiError) {
        setResult({ success: false, error: e.message });
      } else {
        setResult({ success: false, error: 'Something went wrong' });
      }
      reportAppError(e);
    }
  };

  const arePasswordsFilledIn = password && confirmPassword;

  const isPasswordResetValid =
    arePasswordsFilledIn && password === confirmPassword && isValid(password);

  const passwordResetSuccess = result?.success ?? false;

  return (
    <main className={styles.pageContainer}>
      <Card className={styles.card}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <h1 className={cx(typography.h4, styles.formHeader)}>
            Reset Password
          </h1>
          <label
            htmlFor="password"
            className={cx(typography.label, styles.formLabel)}
          >
            Password
            <input
              className={cx(styles.input, typography.body)}
              id="password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              type="password"
            />
          </label>
          <label
            className={cx(typography.label, styles.formLabel)}
            htmlFor="confirmPassword"
          >
            Confirm Password
            <input
              className={cx(styles.input, typography.body)}
              id="confirmPassword"
              name="confirmPassword"
              onChange={(event) => setConfirmPassword(event.target.value)}
              type="password"
            />
          </label>
          <button
            className={cx(styles.submitButton, typography.body)}
            disabled={!isPasswordResetValid || passwordResetSuccess}
            type="submit"
          >
            Submit
          </button>
          {arePasswordsFilledIn && !isPasswordResetValid && (
            <p className={cx(typography.body, styles.invalidPasswordsMessage)}>
              Password and confirmation must match, and be at least 8 characters
              and include a number and a special character.
            </p>
          )}
          {passwordResetSuccess && (
            <p className={cx(typography.body, styles.successResponseMessage)}>
              Your password was successfully reset, please
              <Link className={styles.loginLink} to={ROOT_PATH}>
                Login
              </Link>
              again.
            </p>
          )}
          {result?.error && (
            <p className={cx(typography.body, styles.errorResponseMessage)}>
              {result.error}
            </p>
          )}
        </form>
      </Card>
    </main>
  );
};
