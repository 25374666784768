// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorPlanExportActions__container___ElacX{display:flex;flex-direction:column;gap:1rem;justify-content:center}.FloorPlanExportActions__container___ElacX textarea{border-radius:.25rem;font-size:smaller;height:13em;width:100%}.FloorPlanExportActions__container___ElacX>div{align-items:center;display:flex;flex-direction:column;gap:1rem}.FloorPlanExportActions__container___ElacX>div button{background-color:var(--gold);width:max-content}.FloorPlanExportActions__container___ElacX>div button svg{stroke:unset}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/support/FloorPlanExportActions.scss"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,sBAAA,CAEA,oDACE,oBAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CAGF,+CACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,sDACE,4BAAA,CACA,iBAAA,CAEA,0DACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FloorPlanExportActions__container___ElacX"
};
export default ___CSS_LOADER_EXPORT___;
