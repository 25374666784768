import { differenceBy } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { parseTimeValues } from '@components/formInputs/timeValuesUtils';
import { CENTS_IN_DOLLAR } from '@shared/utils/currency';
import type { Listing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import styles from '../listingsPage/CreateEditListingPageLayout.scss';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';
import type { ListingPricingFormData, PricePointFormData } from '../types';
import {
  listingPricingFormDataFromListing,
  listingReservableDaysFormDataFromListing,
} from '../utils/listingUtils';
import { EditListingAlertModalContainer } from './EditListingAlertModalContainer';
import { useEditListingContext } from './EditListingContext';
import { EditListingPageHeader } from './EditListingPageHeader';
import { EditListingPricingPageBody } from './EditListingPricingPageBody';

export const EditListingPricingForm = ({
  existingListing,
}: {
  existingListing: Listing;
}) => {
  const { subView } = useListingsContext();
  const { processEditSubmission } = useEditListingContext();

  const { control, getValues, handleSubmit, setValue } =
    useForm<ListingPricingFormData>({
      defaultValues: listingPricingFormDataFromListing(existingListing),
    });

  const submit =
    (ignoreWarnings: boolean) => (data: ListingPricingFormData) => {
      const newPricePoints = data.pricePoints
        .filter((pricePoint) => !pricePoint.id)
        .map((pricePoint) => ({
          activeDays: pricePoint.activeDays,
          endTime: parseTimeValues(pricePoint.endTime),
          price: Number(pricePoint.price) * CENTS_IN_DOLLAR,
          startTime: parseTimeValues(pricePoint.startTime),
        }));
      const editedPricePoints = data.pricePoints
        .filter(
          (price): price is PricePointFormData & { id: string } => !!price.id,
        )
        .map((pricePoint) => ({
          activeDays: pricePoint.activeDays,
          endTime: parseTimeValues(pricePoint.endTime),
          id: pricePoint.id,
          price: Number(pricePoint.price) * CENTS_IN_DOLLAR,
          startTime: parseTimeValues(pricePoint.startTime),
        }));

      const removedPricePoints = differenceBy(
        existingListing.pricePoints,
        editedPricePoints,
        'id',
      ).map((pricePoint) => ({ id: pricePoint.id }));
      void processEditSubmission(
        existingListing.highlightedFloorPlanTableIds,
        ignoreWarnings,
        {
          ...existingListing,
          price: Number(data.price) * CENTS_IN_DOLLAR,
        },
        {
          added: newPricePoints,
          removed: removedPricePoints,
          edited: editedPricePoints,
        },
      );
    };

  return (
    <form
      className={styles.pageWrapper}
      noValidate
      onSubmit={handleSubmit(submit(false))}
    >
      <EditListingPageHeader
        cancelPath={listingsPagePath({
          scope:
            existingListing.status === 'draft'
              ? ListingsPageScope.Draft
              : ListingsPageScope.Published,
          subView,
        })}
      />
      <EditListingPricingPageBody
        listingName={existingListing.name}
        pricingControl={control}
        pricingFormGetValues={getValues}
        pricingFormHandleSubmit={handleSubmit}
        pricingFormSetValue={setValue}
        reservableDaysFormValues={listingReservableDaysFormDataFromListing(
          existingListing,
        )}
      />
      <EditListingAlertModalContainer
        handleOnConfirmSubmit={handleSubmit(submit(true))}
      />
    </form>
  );
};
