import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { useError } from 'restaurantAdmin/errors/useError';
import { OPERATIONS_EVENTS_CREATE_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import type { RestaurantEvent } from './apiHelpers';
import { getRestaurantEvents } from './apiHelpers';
import { RestaurantEventsList } from './RestaurantEventsList';
import styles from './RestaurantEventsPage.scss';

export const RestaurantEventsPage = () => {
  const setError = useError();
  const [restaurantEvents, setRestaurantEvents] = useState<RestaurantEvent[]>(
    [],
  );

  const { id: restaurantId } = useRestaurant();

  const fetchRestaurantEvents = async () => {
    try {
      const response = await getRestaurantEvents(restaurantId);

      if (isErrorResponse(response)) {
        setError(response);
        return;
      }

      setRestaurantEvents(response);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    fetchRestaurantEvents();
  }, []);

  return (
    <>
      <PageHeader title="Events">
        <LinkStyledAsButton
          label="Add New Event"
          to={OPERATIONS_EVENTS_CREATE_PATH}
          variant={ButtonVariants.Secondary}
        />
      </PageHeader>
      <PageContent>
        <div className={styles.mainContent}>
          <p className={typography.c2}>
            Events will display on the discover page for your restaurant. Only
            one event is displayed at a time. If two or more events are
            scheduled to display at the same time, the least frequent event will
            be the one displayed on the discover page.
          </p>
          {restaurantEvents.length ? (
            <RestaurantEventsList
              restaurantEvents={restaurantEvents}
              refetchRestaurantEvents={fetchRestaurantEvents}
            />
          ) : (
            <p className={typography.c2}>No events found.</p>
          )}
          <Link
            to={OPERATIONS_EVENTS_CREATE_PATH}
            className={styles.newEventButton}
          >
            <span className={typography.h7}>Add new event</span>
            <Icon name="plus" />
          </Link>
        </div>
      </PageContent>
    </>
  );
};
