import { TZDateMini } from '@date-fns/tz';
import cx from 'classnames';
import { addDays, format, parseISO, startOfDay, startOfWeek } from 'date-fns';
import { toISODateFormat } from '@shared/utils/date';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import { useListingsContext } from '../ListingsContext';
import styles from './AdminCalendar.scss';

export const CalendarHeaderRow = () => {
  const { timezone } = useRestaurant();
  const { selectedDate } = useListingsContext();
  const weekStart = startOfWeek(parseISO(selectedDate));
  const today = startOfDay(TZDateMini.tz(timezone));
  const dates = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  return (
    <>
      <div className={styles.dateHeaderBg} />
      {dates.map((date, index) => {
        const isToday = date.getTime() === today.getTime();
        const headerClassNames = cx({
          [typography.t2]: true,
          [styles.dateHeader]: true,
          [styles.isToday]: isToday,
        });
        return (
          <div
            key={toISODateFormat(date)}
            data-testid={`date-heading_${toISODateFormat(date)}`}
            className={headerClassNames}
            style={{ gridArea: `1 / ${index + 2}` }}
          >
            <span className={cx(typography.t2, styles.dayOfWeek)}>
              {format(date, 'E')}
            </span>
            <span className={typography.t2}>{format(date, 'LLL d')}</span>
          </div>
        );
      })}
    </>
  );
};
