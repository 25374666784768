import cx from 'classnames';
import type {
  FieldErrors,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './HookAwareCheckboxInput.scss';

export interface HookAwareCheckboxInputProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  children?: React.ReactNode;
  className?: string;
  errors: FieldErrors;
  label: string;
  name: Name;
  register: UseFormRegister<T>;
  validations: RegisterOptions<T, Name>;
}

export const HookAwareCheckboxInput = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  children,
  className,
  errors,
  label,
  name,
  register,
  validations,
}: HookAwareCheckboxInputProps<T, Name>) => (
  <div className={cx(styles.container, className)}>
    <label className={typography.c3} htmlFor={name}>
      <span>{label}</span>
      <input type="checkbox" {...register(name, validations)} />
      {children}
    </label>
    <ValidationErrorMessage errors={errors} label={label} name={name} />
  </div>
);
