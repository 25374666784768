import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { getFullName } from '@shared/utils/formatName';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type { OccupantsWalkIn, WalkInStatus } from '../apiHelpers';
import styles from './WalkInSheet.scss';

export interface WalkInSheetProps {
  walkIn: OccupantsWalkIn;
}

const mapStatusToDisplayStatus = (status: WalkInStatus) => {
  const map: Record<WalkInStatus, string> = {
    active: 'seated',
    finished: 'finished',
    autoResolved: 'finished',
  };

  return map[status];
};

export const WalkInSheet = ({ walkIn }: WalkInSheetProps) => {
  const { seatedTableName } = walkIn;
  const walkInName =
    getFullName(walkIn.firstName, walkIn.lastName) || 'Walk-In';

  return (
    <header className={styles.container}>
      <div className={styles.icon}>
        <Icon name="walkInNoBackground" />
      </div>
      <div>
        <div className={styles.heading}>
          <h2 className={typography.h5}>{walkInName}</h2>
          <p className={(typography.t1, styles.status)}>
            {mapStatusToDisplayStatus(walkIn.status)}
          </p>
        </div>
        <div className={styles.subheading}>
          <span className={typography.h6}>
            {ISOTimeTo12HourTime(walkIn.time)}
          </span>
          <span className={typography.t1}>
            {appendGuestCountLabel(walkIn.guestCount)}
          </span>
        </div>
        <div className={cx(typography.t1, styles.seatedTableName)}>
          Table {seatedTableName}
        </div>
      </div>
    </header>
  );
};
