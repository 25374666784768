// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingCard__main___f92YN{background:var(--primary500);border-left:.5rem solid var(--secondary300);border-radius:.5rem;display:flex;flex-direction:column;flex:1;gap:2px;margin:.5rem 0;padding:.5rem .75rem}.ListingCard__row1___INmz5{align-items:start;display:flex}.ListingCard__row1___INmz5 .ListingCard__overlapIndicator___r79Pf{height:22px;margin-right:.5rem;stroke-width:1px;stroke:var(--pink);width:22px}.ListingCard__row1___INmz5 .ListingCard__heading___V4uEB{flex:1}@media only screen and (min-width: 834px){.ListingCard__row1___INmz5 .ListingCard__heading___V4uEB{align-items:baseline;display:flex}}.ListingCard__row2___Sx7dr{display:flex}.ListingCard__row2___Sx7dr>div:first-child{display:flex;flex-direction:column;flex:1;gap:.25rem}.ListingCard__name___o0IoT{flex:1}.ListingCard__daysAndPrice___XFPHu{display:flex;flex:1;gap:.5rem;justify-content:space-between;padding-right:.5rem}.ListingCard__timeRange___TStOq{color:var(--white70)}.ListingCard__timeRange___TStOq span{white-space:nowrap;font-size:inherit}.ListingCard__price___WNsFH{align-self:center;text-align:right}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/ListingCard.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,2BACE,4BAAA,CACA,2CAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,MAAA,CACA,OAAA,CACA,cAAA,CACA,oBAAA,CAGF,2BACE,iBAAA,CACA,YAAA,CAEA,kEACE,WAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,UAAA,CAGF,yDACE,MAAA,CCKF,0CDNA,yDAII,oBAAA,CACA,YAAA,CAAA,CAKN,2BACE,YAAA,CAEA,2CACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,UAAA,CAIJ,2BACE,MAAA,CAGF,mCACE,YAAA,CACA,MAAA,CACA,SAAA,CACA,6BAAA,CACA,mBAAA,CAGF,gCACE,oBAAA,CAEA,qCACE,kBAAA,CACA,iBAAA,CAIJ,4BACE,iBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ListingCard__main___f92YN",
	"row1": "ListingCard__row1___INmz5",
	"overlapIndicator": "ListingCard__overlapIndicator___r79Pf",
	"heading": "ListingCard__heading___V4uEB",
	"row2": "ListingCard__row2___Sx7dr",
	"name": "ListingCard__name___o0IoT",
	"daysAndPrice": "ListingCard__daysAndPrice___XFPHu",
	"timeRange": "ListingCard__timeRange___TStOq",
	"price": "ListingCard__price___WNsFH"
};
export default ___CSS_LOADER_EXPORT___;
