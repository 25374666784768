import { api } from '../../api';
import API from '../../api/apiClient';

// Cancellation policy ===================================================

export const createCancellationPolicy = async ({
  fee,
  hoursBeforeReservation,
  restaurantId,
  threshold,
}: {
  fee: number;
  hoursBeforeReservation: number;
  restaurantId: string;
  threshold: number;
}): Promise<void> => {
  await api.post(`/restaurants/${restaurantId}/cancellation-policy`, {
    fee,
    hoursBeforeReservation,
    threshold,
  });
};

export const deleteCancellationPolicy = async (
  restaurantId: string,
  cancellationPolicyId: string,
): Promise<void> => {
  await api.delete(
    `/restaurants/${restaurantId}/cancellation-policy/${cancellationPolicyId}`,
  );
};

export const editCancellationPolicy = ({
  fee,
  hoursBeforeReservation,
  id,
  threshold,
  restaurantId,
}: {
  fee: number;
  hoursBeforeReservation: number;
  id: string;
  threshold: number;
  restaurantId: string;
}): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/cancellation-policy`, {
    fee,
    hoursBeforeReservation,
    id,
    threshold,
  });

// Cover Limit ===================================================

export const deleteCoverLimit = async (
  restaurantId: string,
): Promise<Response> => API.delete(`/restaurants/${restaurantId}/cover-limit`);

export const updateCoverLimit = async (
  restaurantId: string,
  coverLimit: number,
): Promise<boolean> => {
  const response = await API.put(`/restaurants/${restaurantId}/cover-limit`, {
    coverLimit,
  });

  return response.ok;
};

// Pacing Controls ===================================================

export const deletePacingControls = async (
  restaurantId: string,
): Promise<Response> =>
  API.delete(`/restaurants/${restaurantId}/pacing-controls`);

export const updatePacingControls = async (
  restaurantId: string,
  pacingControls: { pacingLimit: number; pacingWindow: number },
): Promise<boolean> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/pacing-controls`,
    pacingControls,
  );

  return response.ok;
};

// Max guest count =============================================

export const updateMaxGuestCount = async (
  restaurantId: string,
  maxGuestCount: number,
): Promise<boolean> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/max-guest-count`,
    {
      maxGuestCount,
    },
  );

  return response.ok;
};

// Schedule ===================================================

export const updateSchedule = async (
  restaurantId: string,
  schedule: number,
): Promise<boolean> => {
  const response = await API.put(`/restaurants/${restaurantId}/schedule`, {
    schedule,
  });

  return response.ok;
};
