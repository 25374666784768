/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useOverlay } from '@shared/hooks/useOverlay';
import { CloseRestaurantModal } from 'restaurantAdmin/reservations/occupants/pageHeader/CloseRestaurantModal';
import { OpenRestaurantModal } from 'restaurantAdmin/reservations/occupants/pageHeader/OpenRestaurantModal';
import { WalkInSeatingConflictModal } from 'restaurantAdmin/reservations/service/sidePanel/WalkInSeatingConflictModal';
import { WalkInTurnTimeInformationModal } from 'restaurantAdmin/reservations/service/sidePanel/WalkInTurnTimeInformationModal';
import { SeatOutsideListingConfirmationModal } from '../reservations/service/SeatOutsideListingConfirmationModal';
import { RemoveFromWaitListConfirmationModal } from '../reservations/service/sidePanel/waitList/RemoveFromWaitListConfirmationModal';
import { DeactivateAdminConfirmationModal } from '../settings/team/DeactivateAdminConfirmationModal';

export interface ModalContext {
  currentModal: ModalType | null;
  closeModal: () => void;
  /** @deprecated render the `<Modal>` directly, if possible. See RefundButton for an example. */
  openModal: (modalType: ModalType, props?: object) => void;
}

export const ModalContext = createContext<ModalContext>({} as ModalContext);
ModalContext.displayName = 'Modal (Admin)';

/** @deprecated render the `<Modal>` directly, if possible. See RefundButton for an example. */
export const useModalContext = () => useContext(ModalContext);

export enum ModalType {
  CloseRestaurant = 'closeRestaurant',
  DeactivateAdminConfirmation = 'deactivateAdminConfirmation',
  OpenRestaurant = 'openRestaurant',
  RemoveFromWaitListConfirmation = 'removeFromWaitListConfirmation',
  SeatOutsideListingConfirmation = 'seatOutsideListingConfirmation',
  WalkInSeatingConflict = 'walkInSeatingConflict',
  WalkInTurnTimeInformation = 'walkInTurnTimeInformation',
}

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
  const [modalProps, setModalProps] = useState<any>({});

  useOverlay(!!currentModal);

  const closeModal = () => setCurrentModal(null);
  const openModal = (modalType: ModalType, props: object = {}) => {
    setModalProps(props);
    setCurrentModal(modalType);
  };

  const value = useMemo<ModalContext>(
    () => ({
      currentModal,
      closeModal,
      openModal,
    }),
    [currentModal],
  );

  if (!currentModal)
    return (
      <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    );

  return (
    <ModalContext.Provider value={value}>
      {children}
      <CloseRestaurantModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.CloseRestaurant}
        {...modalProps}
      />
      <OpenRestaurantModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.OpenRestaurant}
        {...modalProps}
      />
      <RemoveFromWaitListConfirmationModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.RemoveFromWaitListConfirmation}
        {...modalProps}
      />
      <SeatOutsideListingConfirmationModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.SeatOutsideListingConfirmation}
        {...modalProps}
      />
      <DeactivateAdminConfirmationModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.DeactivateAdminConfirmation}
        {...modalProps}
      />
      <WalkInSeatingConflictModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.WalkInSeatingConflict}
        {...modalProps}
      />
      <WalkInTurnTimeInformationModal
        closeModal={closeModal}
        isOpen={currentModal === ModalType.WalkInTurnTimeInformation}
        {...modalProps}
      />
    </ModalContext.Provider>
  );
};
