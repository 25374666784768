import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { centsToDollar } from '@shared/utils/currency';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { cancel } from 'restaurantAdmin/reservations/apiHelpers';
import { collectCancellationFee } from 'restaurantAdmin/reservations/occupants/apiHelpers';
import typography from '~styles/typography.scss';
import styles from './CancelConfirmationModal.scss';

export interface CancelAndCollectFeeConfirmationModalProps {
  cancellationPolicy: {
    fee: number;
  };
  closeModal: () => void;
  disablePortal?: boolean;
  isOpen: boolean;
  reservationId: string;
}

const CURRENT_PAGE = 0;

export const CancelAndCollectFeeConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  disablePortal,
  isOpen,
  reservationId,
}: CancelAndCollectFeeConfirmationModalProps) => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleCancelAndCollectFee = () => {
    setIsPending(true);

    void (async () => {
      const cancelResponse = await cancel(restaurant.id, reservationId);

      if (cancelResponse.ok) {
        successToast({
          message:
            "Success: The guest's reservation has been successfully canceled.",
        });

        const collectFeeResponse = await collectCancellationFee(
          restaurant.id,
          reservationId,
        );
        if (collectFeeResponse.ok) {
          successToast({
            message:
              "Success: The guest's reservation cancellation fee has been collected.",
          });
        } else {
          const errorMessage =
            await getErrorResponseMessage(collectFeeResponse);
          errorToast({ message: errorMessage });
        }

        refreshPage();
      } else {
        const errorMessage = await getErrorResponseMessage(cancelResponse);
        errorToast({ message: errorMessage });
        setIsPending(false);
      }
    })();
    closeModal();
  };

  return (
    <Modal
      ariaLabel="Cancel and Collect Fee Confirmation"
      disablePortal={disablePortal}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <p className={cx(typography.c2, styles.message)}>
        Are you sure you would like to cancel this reservation and collect the{' '}
        {centsToDollar(cancellationPolicy?.fee)} cancellation fee? Please
        reference the Peak
        <ExternalRefundLink className={styles.link} />
        for any concerns regarding cancellations.
      </p>
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isPending}
          label="Confirm"
          onClick={handleCancelAndCollectFee}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
