// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu__menuButton___nJMlq{background-color:rgba(0,0,0,0);border:none}.Menu__menuButton___nJMlq:focus,.Menu__menuButton___nJMlq:hover{background-color:var(--primary400)}.Menu__menu___DHcxS{background-color:var(--darkGrey300);border-radius:.25rem;box-shadow:0 .25rem .5rem #000;margin-top:.5rem;padding:.5rem 0;width:min-content;z-index:var(--z_toolTips)}.Menu__menuItem___xeseg{background-color:rgba(0,0,0,0);border:0;color:var(--white);cursor:pointer;padding:.875rem 1.5rem;text-align:left;user-select:none;white-space:nowrap;width:100%}.Menu__menuItem___xeseg:focus,.Menu__menuItem___xeseg:hover{background-color:var(--primary400)}.Menu__menuItem___xeseg:disabled{color:var(--darkGrey200);cursor:not-allowed}", "",{"version":3,"sources":["webpack://./src/shared/components/menu/Menu.scss"],"names":[],"mappings":"AAAA,0BACE,8BAAA,CACA,WAAA,CAEA,gEAEE,kCAAA,CAIJ,oBACE,mCAAA,CACA,oBAAA,CACA,8BAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,yBAAA,CAGF,wBACE,8BAAA,CACA,QAAA,CACA,kBAAA,CACA,cAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,UAAA,CAEA,4DAEE,kCAAA,CAGF,iCACE,wBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": "Menu__menuButton___nJMlq",
	"menu": "Menu__menu___DHcxS",
	"menuItem": "Menu__menuItem___xeseg"
};
export default ___CSS_LOADER_EXPORT___;
