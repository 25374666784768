import cx from 'classnames';
import { centsToDollar } from '@shared/utils/currency';
import typography from '~styles/typography.scss';
import styles from './ReservationGuestSheetReceipt.scss';

export interface ReservationGuestSheetReceiptProps {
  className?: string;
  fee: number;
  price: number;
  receiptNumber: string;
  refundAmount: number | null;
  tax: number;
}

export const ReservationGuestSheetReceipt = ({
  className,
  fee,
  price,
  receiptNumber,
  refundAmount,
  tax,
}: ReservationGuestSheetReceiptProps) => (
  <section className={cx(styles.section, className)}>
    <h3>
      <span className={typography.h7_20}>Receipt</span>
      <span className={typography.h7_20}>#{receiptNumber}</span>
    </h3>
    <dl>
      <div>
        <dt className={typography.h7}>Price</dt>
        <dd className={typography.h7}>{centsToDollar(price)}</dd>
      </div>
      {refundAmount && (
        <div>
          <dt className={typography.h7}>Refund Amount</dt>
          <dd className={typography.h7}>{centsToDollar(refundAmount)}</dd>
        </div>
      )}
      <div>
        <dt className={typography.h7}>Tax</dt>
        <dd className={typography.h7}>{centsToDollar(tax)}</dd>
      </div>
      <div>
        <dt className={typography.h7}>Processing</dt>
        <dd className={typography.h7}>{centsToDollar(fee)}</dd>
      </div>
      <div>
        <dt className={typography.h7_20}>
          <strong>Total</strong>
        </dt>
        <dd className={typography.h7_20}>
          <strong>{centsToDollar(price + fee + tax)}</strong>
        </dd>
      </div>
    </dl>
  </section>
);
