import { useFormState } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Icon } from '@components/icon/Icon';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import {
  OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH,
  OPERATIONS_LISTINGS_CREATE_PRICING_PATH,
  OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH,
} from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useCreateListingContext } from '../create/CreateListingContext';
import { FlowStep } from './FlowStep';
import styles from './FlowStepper.scss';
import { ListingFormSteps } from './ListingFormSteps';

export const FlowStepper = () => {
  const activePath = useLocation().pathname;
  const { floorPlanControl, pricingControl, reservableDaysControl } =
    useCreateListingContext();

  const {
    errors: floorPlanFormErrors,
    isValid: isFloorPlanFormValid,
    isSubmitted: isFloorPlanFormSubmitted,
  } = useFormState({
    control: floorPlanControl,
  });

  const hasFloorPlanFormErrors = !!Object.keys(floorPlanFormErrors).length;
  const isFloorPlanFormComplete =
    isFloorPlanFormSubmitted && isFloorPlanFormValid;

  const {
    errors: reservableDaysFormErrors,
    isValid: isReservableDaysFormValid,
    isSubmitted: isReservableDaysFormSubmitted,
  } = useFormState({
    control: reservableDaysControl,
  });

  const {
    errors: pricingFormErrors,
    isValid: isPricingFormValid,
    isSubmitted: isPricingFormSubmitted,
  } = useFormState({
    control: pricingControl,
  });

  const hasReservableDaysFormErrors = !!Object.keys(reservableDaysFormErrors)
    .length;
  const isReservableDaysFormComplete =
    isReservableDaysFormSubmitted && isReservableDaysFormValid;

  const hasPricingFormErrors = !!Object.keys(pricingFormErrors).length;
  const isPricingFormComplete = isPricingFormSubmitted && isPricingFormValid;

  return (
    <nav aria-label="listing create flow steps" className={styles.container}>
      <button
        aria-label={ListingFormSteps.PreviousStep}
        className={typography.t3}
        disabled={OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH === activePath}
        name={ListingFormSteps.PreviousStep}
        type="submit"
      >
        <Icon name="arrowRightCircle" />
      </button>
      <div>
        <FlowStep
          hasErrors={hasFloorPlanFormErrors}
          isComplete={isFloorPlanFormComplete}
          label={ListingFormSteps.FloorPlan}
          path={OPERATIONS_LISTINGS_CREATE_FLOOR_PLAN_PATH}
        />
        <FlowStep
          hasErrors={hasReservableDaysFormErrors}
          isComplete={isReservableDaysFormComplete}
          label={ListingFormSteps.ReservableDays}
          path={OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH}
        />
        <FeatureFlagOn<RestaurantAdminFeatureFlag>
          element={
            <FlowStep
              hasErrors={hasPricingFormErrors}
              isComplete={isPricingFormComplete}
              label={ListingFormSteps.Pricing}
              path={OPERATIONS_LISTINGS_CREATE_PRICING_PATH}
            />
          }
          fallback={null}
          name="pricingManagerFlag"
        />
      </div>
      <FeatureFlagOn<RestaurantAdminFeatureFlag>
        element={
          <button
            aria-label={ListingFormSteps.NextStep}
            className={typography.t3}
            disabled={OPERATIONS_LISTINGS_CREATE_PRICING_PATH === activePath}
            name={ListingFormSteps.NextStep}
            type="submit"
          >
            <Icon name="arrowRightCircle" />
          </button>
        }
        fallback={
          <button
            aria-label={ListingFormSteps.NextStep}
            className={styles.nextButton}
            disabled={
              OPERATIONS_LISTINGS_CREATE_RESERVABLE_DAYS_PATH === activePath
            }
            name={ListingFormSteps.NextStep}
            type="submit"
          >
            <Icon name="arrowRightCircle" />
          </button>
        }
        name="pricingManagerFlag"
      />
    </nav>
  );
};
