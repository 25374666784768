import { EditListingFloorPlanPageBody } from '../edit/EditListingFloorPlanPageBody';
import { useListingsContext } from '../ListingsContext';
import styles from '../listingsPage/CreateEditListingPageLayout.scss';
import { useCreateListingContext } from './CreateListingContext';
import { CreateListingFloorPlanPageHeader } from './CreateListingFloorPlanPageHeader';

export const CreateListingFloorPlanPage = () => {
  const { floorPlan, isLoading } = useListingsContext();
  const { floorPlanControl, handleOnSubmitFloorPlan } =
    useCreateListingContext();

  if (isLoading) return null;

  return (
    <form
      className={styles.pageWrapper}
      onSubmit={handleOnSubmitFloorPlan}
      noValidate
    >
      <CreateListingFloorPlanPageHeader />
      <EditListingFloorPlanPageBody
        control={floorPlanControl}
        isEditing={false}
        floorPlan={floorPlan}
      />
    </form>
  );
};
