import cx from 'classnames';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { HookAwareTextareaAutosize } from '@components/hookAwareTextareaAutosize/HookAwareTextareaAutosize';
import { errorToast } from '@components/toasts/Toasts';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { isValidEmail } from '@shared/utils/emailValidator';
import { PREVIOUS_PATH, SETTINGS_TEAM_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { inviteTeamMember } from './apiHelpers';
import styles from './InviteTeamMemberPage.scss';

interface InviteTeamMemberFormData {
  emails: string;
}

export const InviteTeamMemberPage = () => {
  const { id: restaurantId } = useRestaurant();
  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = useForm<InviteTeamMemberFormData>({});

  const navigate = useNavigate();

  const hasEmailError = !!errors?.emails;

  const onValidSubmit: SubmitHandler<InviteTeamMemberFormData> = async (
    data,
  ) => {
    const { emails } = data;

    const emailList = emails.split(',').map((email) => email.trim());

    const response = await inviteTeamMember(restaurantId, emailList);

    if (isErrorResponse(response)) {
      errorToast({ message: response.message });
    } else {
      navigate(SETTINGS_TEAM_PATH);
    }
  };

  return (
    <>
      <PageHeader title="Add Team Member" />
      <PageContent className={styles.container}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className={styles.form} onSubmit={handleSubmit(onValidSubmit)}>
          <fieldset>
            <h2
              className={cx(typography.h5, { [styles.error]: hasEmailError })}
            >
              Member Info
            </h2>
            <div
              className={cx(styles.fieldsContainer, {
                [styles.errorBorder]: hasEmailError,
              })}
            >
              <HookAwareTextareaAutosize
                control={control}
                errors={errors}
                isDisabled={false}
                label="Email"
                name="emails"
                placeholder="email, comma separated"
                validations={{
                  required: true,
                  validate: {
                    isValidEmail: (emails: string) => {
                      const isValid = emails
                        .split(',')
                        .every((email) => isValidEmail(email.trim()));
                      if (isValid) return true;
                      return 'Please enter valid email addresses';
                    },
                  },
                }}
              />
            </div>
          </fieldset>
          <div className={styles.formActions}>
            <Button
              label="Discard Team Member"
              onClick={() => navigate(PREVIOUS_PATH)}
              variant={ButtonVariants.Tertiary}
            />
            <Button
              isDisabled={!isDirty || isSubmitting}
              label="Invite Team Member"
              type="submit"
              variant={ButtonVariants.Primary}
            />
          </div>
        </form>
      </PageContent>
    </>
  );
};
