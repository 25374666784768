import { parseTimeValues } from '@components/formInputs/timeValuesUtils';
import { CENTS_IN_DOLLAR } from '@shared/utils/currency';
import type {
  CreateListingPayload,
  CreatePricePointPayload,
} from '../apiHelpers';
import type {
  ListingFloorPlanFormData,
  ListingPricingFormData,
  ListingReservableDaysFormData,
  PricePointFormData,
} from '../types';

export enum FormNames {
  FloorPlan = 'floor-plan',
  ReservableDays = 'reservable-days',
}

export const buildCreateListingPayload = ({
  reservableDaysFormValues,
  floorPlanFormValues,
  pricingFormValues,
  ignoreWarnings,
}: {
  reservableDaysFormValues: ListingReservableDaysFormData;
  floorPlanFormValues: ListingFloorPlanFormData;
  pricingFormValues?: ListingPricingFormData;
  ignoreWarnings: boolean;
}): CreateListingPayload => {
  const price = pricingFormValues?.price || reservableDaysFormValues.price;
  return {
    endDate: reservableDaysFormValues.endDate || null,
    endTime: parseTimeValues(reservableDaysFormValues.endTime),
    highlightedFloorPlanTableIds: floorPlanFormValues.highlightedTables.map(
      (table) => table.id,
    ),
    iconName: floorPlanFormValues.iconName,
    interval: floorPlanFormValues.interval,
    inventoryCount: Number(floorPlanFormValues.inventoryCount),
    isCommunal: floorPlanFormValues.isCommunal,
    maximumGuests: Number(floorPlanFormValues.maximumGuests),
    minimumGuests: Number(floorPlanFormValues.minimumGuests),
    name: floorPlanFormValues.name,
    price: Number(price) * CENTS_IN_DOLLAR,
    pricePoints:
      pricingFormValues?.pricePoints &&
      parsePricePoints(pricingFormValues.pricePoints),
    repeat: reservableDaysFormValues.repeat,
    startDate: reservableDaysFormValues.startDate,
    startTime: parseTimeValues(reservableDaysFormValues.startTime),
    publicName: floorPlanFormValues.publicName,
    turnTime: floorPlanFormValues.turnTime,
    ignoreWarnings,
  };
};

const parsePricePoints = (
  pricePoints: PricePointFormData[],
): CreatePricePointPayload[] =>
  pricePoints.map((pricePoint) => ({
    activeDays: pricePoint.activeDays,
    endTime: parseTimeValues(pricePoint.endTime),
    price: Number(pricePoint.price) * CENTS_IN_DOLLAR,
    startTime: parseTimeValues(pricePoint.startTime),
  }));
