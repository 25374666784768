import { OPERATIONS_LISTINGS_PATH } from '../../../paths';

export interface ListingsPageParams {
  scope: ListingsPageScope;
  subView: ListingsPageSubView;
}

export enum ListingsPageSubView {
  Calendar = 'calendar',
  FloorPlan = 'floor-plan',
}

export enum ListingsPageScope {
  Draft = 'draft',
  Inactive = 'inactive',
  Published = 'published',
}

export const defaultListingsPageParams: ListingsPageParams = {
  scope: ListingsPageScope.Published,
  subView: ListingsPageSubView.FloorPlan,
};
export const {
  scope: defaultListingsPageScope,
  subView: defaultListingsPageSubView,
} = defaultListingsPageParams;

export const listingsPagePath = ({ scope, subView }: ListingsPageParams) =>
  `${OPERATIONS_LISTINGS_PATH}/${subView}/${scope}`;

export const OPERATIONS_LISTINGS_DEFAULT_PATH = listingsPagePath(
  defaultListingsPageParams,
);
