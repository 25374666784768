import { createContext, type ReactNode, useMemo } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { Listing } from '../operations/listings/apiHelpers';
import * as listingApiHelpers from '../operations/listings/apiHelpers';
import { useRestaurant } from './useRestaurant';

interface PublishedListingsContextValue {
  listings: Listing[];
}

const PublishedListingsContext =
  createContext<PublishedListingsContextValue | null>(null);

export const usePublishedListings = () =>
  useDefinedContext(PublishedListingsContext);

export const PublishedListingsContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();

  const { data: listings = [] } = useAbortEffect(
    (signal) => listingApiHelpers.getListings(restaurantId, false, signal),
    [restaurantId],
  );

  const value = useMemo<PublishedListingsContextValue>(
    () => ({ listings }),
    [listings],
  );

  return (
    <PublishedListingsContext.Provider value={value}>
      {children}
    </PublishedListingsContext.Provider>
  );
};
