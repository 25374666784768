import cx from 'classnames';
import { forwardRef } from 'react';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import {
  calculateContainerStyle,
  calculateIconStyle,
} from '@components/floorPlan/utils';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { type AvailableWalkInTime } from '../apiHelpers';
import styles from './UnoccupiedFloorPlanTable.scss';
import 'react-tooltip/dist/react-tooltip.css';

export interface FloorPlanTableProps extends TableIconCommonProps {
  availableTime: AvailableWalkInTime;
  handleTableOnClick: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  isSelected: boolean;
}

export const UnoccupiedFloorPlanTable = forwardRef<
  HTMLDivElement,
  FloorPlanTableProps
>(
  (
    {
      availableTime,
      handleTableOnClick,
      iconName,
      iconWidthScale,
      isDisabled = false,
      isHighlighted,
      isSelected,
      left,
      name,
      orientation,
      tableIconScale,
      testId,
      top,
    },
    ref,
  ) => {
    const buttonLabel = isHighlighted ? 'Unselect table' : 'Select table';
    const iconSuffix = isHighlighted ? 'Selected' : '';
    const newIconName = `${iconName}${iconSuffix}` as AnyIcon;

    const pillClassNames = cx({
      [styles.timerPill]: true,
      [typography.t3]: true,
    });

    const timerPillClassNames = cx({
      [pillClassNames]: true,
      [styles.selectedTimerPill]: isSelected,
    });

    const naPillClassNames = cx({
      [pillClassNames]: true,
      [styles.selectedNAPill]: isSelected,
    });

    const renderPill = () => {
      if (availableTime === null) return null;
      if (availableTime === 'NA') {
        return (
          <span className={naPillClassNames}>
            <Icon name="alertOctagon" />
            {availableTime}
          </span>
        );
      }
      return <span className={timerPillClassNames}>{availableTime}m</span>;
    };

    return (
      <div
        className={styles.container}
        data-testid={testId}
        ref={ref}
        style={calculateContainerStyle({
          left,
          tableIconScale,
          top,
        })}
      >
        <button
          aria-label={buttonLabel}
          className={styles.button}
          data-tooltip-id={name}
          disabled={isDisabled}
          name={name}
          onClick={handleTableOnClick}
          type="button"
        >
          <Icon
            name={newIconName}
            style={{
              ...calculateIconStyle({
                iconWidthScale,
                orientation,
                tableIconScale,
              }),
            }}
            testId={`icon-${newIconName}`}
          />
          {renderPill()}
        </button>
      </div>
    );
  },
);

UnoccupiedFloorPlanTable.displayName = 'UnoccupiedFloorPlanTable';
