import {
  DndContext,
  type DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { Tab } from '@mui/base/Tab';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import cx from 'classnames';
import { type MouseEventHandler, useState } from 'react';
import { LoadingPage } from '@components/LoadingPage';
import { TabsList } from '@components/tabs/TabsList';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { convertToMergeAwareTables } from 'restaurantAdmin/floorPlans/utils';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { SplitMergeFloorPlan } from '../splitMergeTables/SplitMergeFloorPlan';
import { SplitMergeTablesButton } from '../splitMergeTables/SplitMergeTablesButton';
import { SplitMergeTablesSheet } from '../splitMergeTables/SplitMergeTablesSheet';
import { isReservation, isWalkIn, type ServiceReservation } from './apiHelpers';
import { DraggingAvatar } from './DraggingAvatar';
import { OccupantFloorPlan } from './floorPlan/OccupantFloorPlan';
import { ReservationSeatModeFloorPlan } from './floorPlan/ReservationSeatModeFloorPlan';
import { UpcomingTimerSwitch } from './floorPlan/UpcomingTimerSwitch';
import { WaitListSeatModeFloorPlan } from './floorPlan/WaitListSeatModeFloorPlan';
import { WalkInSeatModeFloorPlan } from './floorPlan/WalkInSeatModeFloorPlan';
import { GuestTagFilterSelect } from './GuestTagFilterSelect';
import { ServiceFloorPlanContainer } from './ServiceFloorPlanContainer';
import styles from './ServicePage.scss';
import { InfiniteOccupantList } from './sidePanel/InfiniteOccupantList';
import { OccupantSheetWrapper } from './sidePanel/OccupantSheetWrapper';
import { ReservationGuestSheet } from './sidePanel/ReservationGuestSheet';
import { SeatedWalkInSheet } from './sidePanel/SeatedWalkInSheet';
import { type WaitListEntry } from './sidePanel/waitList/apiHelpers';
import { useWaitListContext } from './sidePanel/waitList/state/WaitListContext';
import { WaitList } from './sidePanel/waitList/WaitList';
import { WaitListForm } from './sidePanel/waitList/WaitListForm';
import { WaitListSheet } from './sidePanel/waitList/WaitListSheet';
import { WalkInForm } from './sidePanel/WalkInForm';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from './state/ReservationServiceContext';

const OCCUPANTS_TAB_INDEX = 1;
const WAITLIST_TAB_INDEX = 2;

export const ServicePage = () => {
  // local state
  const [currentTabIndex, setCurrentTabIndex] = useState<
    number | string | null
  >(OCCUPANTS_TAB_INDEX);
  const [seatModeEnabled, setSeatModeEnabled] = useState(false);
  const [draggingReservation, setDraggingReservation] =
    useState<ServiceReservation>();
  const [draggingWaitListEntry, setDraggingWaitListEntry] =
    useState<WaitListEntry>();
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { delay: 140, tolerance: 5 },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  // context hooks
  const restaurant = useRestaurant();

  const {
    availableTimesByFloorPlanTableId,
    closeSidePanelSheet,
    floorPlan,
    guestTagFilters,
    restaurantTags,
    handleOnClickNextOccupant,
    handleOnClickPreviousOccupant,
    handleSeatedTableOnClick,
    handleSelectGuestTagFilters,
    isFloorPlanLoading,
    occupantPositionValues,
    refreshFloorPlan,
    refreshOccupants,
    selectedOccupant,
    setSidePanelSheet,
    shouldShowCarousel,
    shouldShowTimers,
    sidePanelSheet,
    toggleShouldShowTimers,
  } = useReservationServiceContext();
  const {
    selectedWaitListEntry,
    setSelectedWaitListEntryIndex,
    waitListEntries,
  } = useWaitListContext();

  // custom hooks
  const { ref: floorPlanRef } = useHandleClickOutside(() =>
    setSeatModeEnabled(false),
  );

  // tab navigation
  const navigateToOccupantsTab = () => {
    setCurrentTabIndex(OCCUPANTS_TAB_INDEX);
    closeSidePanelSheet();
  };

  const navigateToWaitListTab = () => {
    setCurrentTabIndex(WAITLIST_TAB_INDEX);
    closeSidePanelSheet();
  };

  // handlers
  const onSeatWalkIn = (floorPlanTable: HostFloorPlanTable) => {
    setSidePanelSheet({
      mode: SidePanelSheetMode.SeatWalkIn,
      state: floorPlanTable,
    });
  };

  const handleWalkInSeated = () => {
    navigateToOccupantsTab();
  };

  if (isFloorPlanLoading || !floorPlan) return <LoadingPage />;

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    setSeatModeEnabled(true);
    if (active.data.current?.reservation) {
      setDraggingReservation(active.data.current?.reservation);
    }
    if (active.data.current?.waitListEntry) {
      setDraggingWaitListEntry(active.data.current?.waitListEntry);
      setSelectedWaitListEntryIndex(active.data.current?.waitListEntryIndex);
    }
  };

  const resetDragAndDropState = () => {
    setSeatModeEnabled(false);
    setDraggingReservation(undefined);
    setDraggingWaitListEntry(undefined);
  };

  const handleTabClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if ((e.target as HTMLDivElement).textContent === 'Occupants') {
      refreshOccupants();
      const topOfList = document.querySelector(
        'div.infinite-scroll-component > li:first-child',
      );

      if (topOfList) {
        topOfList.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const getHighlightedListingId = () => {
    if (selectedOccupant && isReservation(selectedOccupant)) {
      return selectedOccupant.listingId;
    }

    if (draggingReservation) {
      return draggingReservation.listingId;
    }

    return undefined;
  };

  // Avoid destroying the Tab component to maintain scroll position
  const isTabsVisible = sidePanelSheet.mode === SidePanelSheetMode.None;

  const renderSidePanelSheet = () => {
    if (sidePanelSheet.mode === SidePanelSheetMode.SplitMerge) {
      return (
        <SplitMergeTablesSheet
          className={styles.sidePanelSheet}
          handleOnCloseSheet={() => {
            refreshFloorPlan();
            setSidePanelSheet({ mode: SidePanelSheetMode.None });
          }}
        />
      );
    }

    if (sidePanelSheet.mode === SidePanelSheetMode.SeatWalkIn) {
      // redirects to occupants list tab
      return (
        <WalkInForm
          className={styles.sidePanelSheet}
          selectedTable={sidePanelSheet.state}
          onClose={closeSidePanelSheet}
          handleWalkInSeated={handleWalkInSeated}
        />
      );
    }

    if (sidePanelSheet.mode === SidePanelSheetMode.OccupantDetails) {
      // redirects to occupants list tab
      return (
        <OccupantSheetWrapper
          className={styles.sidePanelSheet}
          onClickNextOccupant={handleOnClickNextOccupant}
          onClickPreviousOccupant={handleOnClickPreviousOccupant}
          onClose={navigateToOccupantsTab}
          occupantPositionValues={occupantPositionValues}
          shouldShowCarousel={shouldShowCarousel}
        >
          {isReservation(selectedOccupant) && (
            <ReservationGuestSheet
              guest={selectedOccupant.guest}
              onEnableSeatMode={() => setSeatModeEnabled(true)}
              reservation={selectedOccupant}
              seatModeEnabled={seatModeEnabled}
            />
          )}
          {isWalkIn(selectedOccupant) && (
            <SeatedWalkInSheet
              walkIn={selectedOccupant}
              onEnableSeatMode={() => setSeatModeEnabled(true)}
            />
          )}
        </OccupantSheetWrapper>
      );
    }

    if (sidePanelSheet.mode === SidePanelSheetMode.AddToWaitList) {
      return (
        <WaitListForm
          className={styles.sidePanelSheet}
          onClose={navigateToWaitListTab}
        />
      );
    }

    if (sidePanelSheet.mode === SidePanelSheetMode.WaitListDetails) {
      if (!selectedWaitListEntry) return null;
      return (
        <WaitListSheet
          className={styles.sidePanelSheet}
          onCanceled={navigateToWaitListTab}
          onClose={navigateToWaitListTab}
          waitListEntry={selectedWaitListEntry}
          onEnableSeatMode={() => setSeatModeEnabled(true)}
          seatModeEnabled={seatModeEnabled}
        />
      );
    }

    // ensure every mode has been handled
    sidePanelSheet.mode satisfies SidePanelSheetMode.None;

    return null;
  };

  const renderActions = () => (
    <>
      {sidePanelSheet.mode !== SidePanelSheetMode.SplitMerge && (
        <>
          <SplitMergeTablesButton
            onClick={() =>
              setSidePanelSheet({ mode: SidePanelSheetMode.SplitMerge })
            }
          />
          <div className={styles.divider} />
        </>
      )}
      <UpcomingTimerSwitch
        checked={shouldShowTimers}
        onChange={toggleShouldShowTimers}
      />
    </>
  );

  const refreshOccupantsAndFloorPlan = () => {
    refreshOccupants();
    refreshFloorPlan();
  };

  const exitSeatMode = () => setSeatModeEnabled(false);

  const renderFloorPlan = () => {
    const mergeAwareFloorPlanTables = convertToMergeAwareTables(
      floorPlan.floorPlanTables,
    );
    if (sidePanelSheet.mode === SidePanelSheetMode.SplitMerge)
      return (
        <ServiceFloorPlanContainer>
          <SplitMergeFloorPlan
            availableTimesByFloorPlanTableId={availableTimesByFloorPlanTableId}
            backgroundSrc={floorPlan.backgroundSrc}
            className={styles.floorPlan}
            tables={floorPlan.floorPlanTables}
          />
        </ServiceFloorPlanContainer>
      );

    if (!seatModeEnabled) {
      const selectedWalkInTableId =
        sidePanelSheet.mode === SidePanelSheetMode.SeatWalkIn
          ? sidePanelSheet.state.id
          : undefined;
      return (
        <ServiceFloorPlanContainer testId="seated-floor-plan">
          <OccupantFloorPlan
            backgroundSrc={floorPlan.backgroundSrc}
            className={styles.floorPlan}
            emptyTableOnClick={onSeatWalkIn}
            seatedTableOnClick={handleSeatedTableOnClick}
            selectedEmptyTableId={selectedWalkInTableId}
            availableTimesByFloorPlanTableId={availableTimesByFloorPlanTableId}
            tables={mergeAwareFloorPlanTables}
          />
        </ServiceFloorPlanContainer>
      );
    }

    // seat mode is enabled under this line
    const isWaitListTabActive = currentTabIndex === WAITLIST_TAB_INDEX;

    switch (true) {
      case !!draggingReservation:
        return (
          <ServiceFloorPlanContainer
            instructions="Drop on a highlighted table to seat them."
            ref={floorPlanRef}
            testId="seated-floor-plan"
          >
            <ReservationSeatModeFloorPlan
              backgroundSrc={floorPlan.backgroundSrc}
              className={cx(styles.floorPlan, styles.floorPlanSeatMode)}
              refreshOccupantsAndFloorPlan={refreshOccupantsAndFloorPlan}
              exitSeatMode={exitSeatMode}
              highlightedListingId={getHighlightedListingId()}
              availableTimesByFloorPlanTableId={
                availableTimesByFloorPlanTableId
              }
              tables={mergeAwareFloorPlanTables}
            />
          </ServiceFloorPlanContainer>
        );
      case isReservation(selectedOccupant):
        return (
          <ServiceFloorPlanContainer
            instructions="Click a highlighted table to seat them."
            ref={floorPlanRef}
            testId="seated-floor-plan"
          >
            <ReservationSeatModeFloorPlan
              backgroundSrc={floorPlan.backgroundSrc}
              className={cx(styles.floorPlan, styles.floorPlanSeatMode)}
              refreshOccupantsAndFloorPlan={refreshOccupantsAndFloorPlan}
              exitSeatMode={exitSeatMode}
              highlightedListingId={getHighlightedListingId()}
              availableTimesByFloorPlanTableId={
                availableTimesByFloorPlanTableId
              }
              tables={mergeAwareFloorPlanTables}
            />
          </ServiceFloorPlanContainer>
        );
      case isWalkIn(selectedOccupant):
        return (
          <ServiceFloorPlanContainer
            instructions="Click a new table to change their seat."
            ref={floorPlanRef}
            testId="seated-floor-plan"
          >
            <WalkInSeatModeFloorPlan
              backgroundSrc={floorPlan.backgroundSrc}
              className={cx(styles.floorPlan, styles.floorPlanSeatMode)}
              refreshOccupantsAndFloorPlan={refreshOccupantsAndFloorPlan}
              exitSeatMode={exitSeatMode}
              availableTimesByFloorPlanTableId={
                availableTimesByFloorPlanTableId
              }
              tables={mergeAwareFloorPlanTables}
            />
          </ServiceFloorPlanContainer>
        );
      case isWaitListTabActive && !!draggingWaitListEntry:
        return (
          <ServiceFloorPlanContainer
            instructions="Drop on an empty table to seat them."
            ref={floorPlanRef}
            testId="seated-floor-plan"
          >
            <WaitListSeatModeFloorPlan
              backgroundSrc={floorPlan.backgroundSrc}
              className={cx(styles.floorPlan, styles.floorPlanSeatMode)}
              refreshFloorPlan={refreshFloorPlan}
              exitSeatMode={exitSeatMode}
              availableTimesByFloorPlanTableId={
                availableTimesByFloorPlanTableId
              }
              tables={mergeAwareFloorPlanTables}
            />
          </ServiceFloorPlanContainer>
        );
      case isWaitListTabActive:
        return (
          <ServiceFloorPlanContainer
            instructions="Click a table to seat them"
            ref={floorPlanRef}
          >
            <WaitListSeatModeFloorPlan
              backgroundSrc={floorPlan.backgroundSrc}
              className={cx(styles.floorPlan, styles.floorPlanSeatMode)}
              refreshFloorPlan={refreshFloorPlan}
              exitSeatMode={exitSeatMode}
              availableTimesByFloorPlanTableId={
                availableTimesByFloorPlanTableId
              }
              tables={mergeAwareFloorPlanTables}
            />
          </ServiceFloorPlanContainer>
        );
      default:
        return null;
    }
  };

  const numberOfSeatedGuests = floorPlan.floorPlanTables.reduce(
    (total, table) => {
      if (!table.seatedOccupant) return total;

      return total + table.seatedOccupant.guestCount;
    },
    0,
  );

  return (
    <DndContext
      onDragCancel={resetDragAndDropState}
      onDragEnd={resetDragAndDropState}
      onDragStart={handleDragStart}
      sensors={sensors}
      modifiers={[snapCenterToCursor]}
    >
      <DraggingAvatar />
      <PageHeader category="Reservations" title="Service">
        {restaurant.pacingControls && (
          <div>
            <h2 className={typography.h8}>Pacing Controls</h2>
            <span>{`${restaurant.pacingControls.pacingLimit} cover${restaurant.pacingControls.pacingLimit > 1 ? 's' : ''} per ${restaurant.pacingControls.pacingWindow} mins`}</span>
          </div>
        )}
        {restaurant.coverLimit && (
          <div>
            <h2 className={typography.h8}>Cover Limit</h2>
            <span>{`${numberOfSeatedGuests}/${restaurant.coverLimit} seated`}</span>
          </div>
        )}
      </PageHeader>
      <PageContent className={styles.content}>
        <div className={styles.sidePanel} data-testid="side-panel">
          <Tabs
            className={styles.tabs}
            value={currentTabIndex}
            onClick={handleTabClick}
            onChange={(_event, newIndex) => {
              setCurrentTabIndex(newIndex);
            }}
            style={isTabsVisible ? {} : { display: 'none' }}
          >
            <TabsList>
              <Tab className={typography.t1} value={OCCUPANTS_TAB_INDEX}>
                Occupants
              </Tab>
              <Tab className={typography.t1} value={WAITLIST_TAB_INDEX}>
                Waitlist
              </Tab>
            </TabsList>
            <TabPanel className={styles.tabPanel} value={OCCUPANTS_TAB_INDEX}>
              <GuestTagFilterSelect
                restaurantTags={restaurantTags}
                guestTagFilters={guestTagFilters}
                handleSelectGuestTagFilters={handleSelectGuestTagFilters}
              />
              <InfiniteOccupantList />
            </TabPanel>
            <TabPanel className={styles.tabPanel} value={WAITLIST_TAB_INDEX}>
              <WaitList waitListEntries={waitListEntries} />
            </TabPanel>
          </Tabs>
          {renderSidePanelSheet()}
        </div>
        <div className={styles.seatingContent}>
          <div className={styles.actions}>{renderActions()}</div>
          {renderFloorPlan()}
        </div>
      </PageContent>
    </DndContext>
  );
};
