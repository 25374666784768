import { Spinner } from '@components/Spinner';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import type { RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import { useEditListingContext } from './EditListingContext';
import { EditListingPricingForm } from './EditListingPricingForm';

export const EditListingPricingPage = () => {
  const { existingListing, isListingLoading } = useEditListingContext();

  if (isListingLoading) {
    return <Spinner />;
  }
  if (!existingListing) {
    return null;
  }

  return (
    <FeatureFlagOn<RestaurantAdminFeatureFlag>
      element={<EditListingPricingForm existingListing={existingListing} />}
      fallback={null}
      name="pricingManagerFlag"
    />
  );
};
