import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { ContactInfo } from '../../../components/contactInfo/ContactInfo';
import { GuestNotes } from '../../../components/guestNotes/GuestNotes';
import { GuestTags } from '../../../components/guestTags/GuestTags';
import { RefundButton } from '../../../components/refundButton/RefundButton';
import { RemediationForm } from '../../../components/remediationForm/RemediationForm';
import { ReservationGuestSheetStatuses } from '../../../components/reservationGuestSheetStatuses/ReservationGuestSheetStatuses';
import {
  type ReservationsApiGuest,
  type ServiceReservation,
} from '../apiHelpers';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import styles from './ReservationGuestSheet.scss';
import { ReservationGuestSheetActions } from './ReservationGuestSheetActions';
import { ReservationGuestSheetHeader } from './ReservationGuestSheetHeader';
import { ReservationGuestSheetReceipt } from './ReservationGuestSheetReceipt';

export interface ReservationGuestSheetProps {
  guest: ReservationsApiGuest;
  onEnableSeatMode: () => void;
  reservation: ServiceReservation;
  seatModeEnabled: boolean;
}

export const ReservationGuestSheet = ({
  guest,
  onEnableSeatMode,
  reservation,
  seatModeEnabled,
}: ReservationGuestSheetProps) => {
  const { id: guestId, phone, email } = guest;
  const {
    id: reservationId,
    transactionDetails: { fee, price, tax, receiptNumber, refundAmount },
  } = reservation;

  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;
  const isRefunded = !!reservation.transactionDetails.refundAmount;

  const { refreshOccupants } = useReservationServiceContext();

  return (
    <div className={styles.container}>
      <ReservationGuestSheetHeader guest={guest} reservation={reservation} />
      <ReservationGuestSheetActions
        isSeated={Boolean(reservation.seatedTableName)}
        isStranded={reservation.isStranded}
        onEnableSeatMode={onEnableSeatMode}
        reservationId={reservationId}
        seatModeEnabled={seatModeEnabled}
      />
      <ReservationGuestSheetStatuses
        isHostBooked={isHostBooked}
        isNoShow={false}
        isStranded={reservation.isStranded}
        isCanceled={false}
      />
      <div className={styles.floorplan}>
        <SnapshotFloorPlan reservationId={reservationId} disableControls />
      </div>
      <ContactInfo phone={phone} email={email} />
      <GuestTags guestId={guestId} onUpdate={refreshOccupants} />
      <GuestNotes guestId={guestId} onUpdate={refreshOccupants} />
      <ReservationGuestSheetReceipt
        fee={fee}
        price={price}
        receiptNumber={receiptNumber}
        tax={tax}
        refundAmount={refundAmount}
      />
      <RemediationForm
        reservationId={reservation.id}
        reservationStatus={reservation.status}
      />
      <RefundButton
        reservationId={reservationId}
        reservationPrice={price}
        isRefunded={isRefunded}
      />
    </div>
  );
};
