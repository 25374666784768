import cx from 'classnames';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { get, type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { HookAwareCheckboxInput } from '@components/hookAwareCheckboxInput/HookAwareCheckboxInput';
import { HookAwareFormInput } from '@components/hookAwareFormInput/HookAwareFormInput';
import { ApiError } from '@shared/api/errors';
import { useError } from 'restaurantAdmin/errors/useError';
import { ROOT_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { registerAdmin } from './apiHelpers';
import styles from './CreateAccountPage.scss';

interface CreateAccountFormData {
  agreeToTerms: boolean;
  confirmPassword: string;
  email: string;
  fullName: string;
  password: string;
}

export const CreateAccountPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const setError = useError();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
  } = useForm<CreateAccountFormData>();
  const values = getValues();
  const watch = useWatch({ control });

  const password = get(watch, 'password') as string;

  const onValidSubmit: SubmitHandler<CreateAccountFormData> = (data): void => {
    if (!token) return;

    setIsLoading(true);
    setErrorMessage('');

    void (async () => {
      try {
        await registerAdmin({
          email: data.email,
          fullName: data.fullName,
          isTosAccepted: data.agreeToTerms,
          password: data.password,
          token,
        });
        navigate(ROOT_PATH, {
          state: {
            message: 'Account created successfully. Please log in to continue.',
          },
        });
      } catch (error) {
        if (error instanceof ApiError) {
          setErrorMessage(error.message);
        } else {
          setError(error);
        }
        setIsLoading(false);
      }
    })();
  };

  const someFieldsHaveErrors = !isEmpty(errors);
  const someFieldsAreEmpty = Object.keys(values).some(
    (key) => !!values[key as keyof CreateAccountFormData] === false,
  );

  return (
    <main className={styles.pageContainer}>
      <div className={styles.card}>
        <h1 className={typography.h4} id="form-title">
          Create Account
        </h1>
        <form
          aria-labelledby="form-title"
          onSubmit={handleSubmit(onValidSubmit)}
        >
          <HookAwareFormInput
            errors={errors}
            inputType="text"
            isDisabled={false}
            label="Full Name"
            name="fullName"
            register={register}
            validations={{
              required: true,
              maxLength: {
                message: 'Name must be 255 characters or less',
                value: 255,
              },
            }}
          />
          <HookAwareFormInput
            errors={errors}
            inputType="text"
            isDisabled={false}
            label="Email"
            name="email"
            register={register}
            validations={{
              pattern: {
                message:
                  'Please enter your email address in the format yourname@example.com',
                value: /\S+@\S+\.\S+/,
              },
              required: true,
            }}
          />
          <HookAwareFormInput
            errors={errors}
            inputType="password"
            isDisabled={false}
            label="Password"
            name="password"
            register={register}
            validations={{
              pattern: {
                // \u005C = reverse slash \
                // \u0027 = apostrophe '
                message:
                  'Password must have at least 8 characters, including one numeral and one special character from the following set: _ - , ; : ! ? . \u0027 " ( ) [ ] { } @ * / \u005C & # % ` ^ + < = > | ~ $',
                // same as pattern in api/src/_framework/schemas.ts > passwordSchema
                value: /(?=.*\d)(?=.*[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._])/,
              },
              required: true,
            }}
          />
          <HookAwareFormInput
            errors={errors}
            inputType="password"
            isDisabled={false}
            label="Confirm Password"
            name="confirmPassword"
            register={register}
            validations={{
              required: true,
              validate: (value) => value === password || 'Passwords must match',
            }}
          />
          <HookAwareCheckboxInput
            className={styles.tosCheckbox}
            errors={errors}
            label="Terms of service"
            name="agreeToTerms"
            register={register}
            validations={{
              required: {
                message: 'You must agree to the terms of service',
                value: true,
              },
            }}
          >
            <p className={typography.c2}>
              By checking this box, you are agreeing to Peak’s{' '}
              <Link
                rel="noopener noreferrer nofollow"
                target="_blank"
                to="https://www.peakreservations.com/terms-of-service"
              >
                Terms of&nbsp;Service
              </Link>
              .
            </p>
          </HookAwareCheckboxInput>
          <p className={cx(typography.c2, styles.error)}>{errorMessage}</p>
          <Button
            isDisabled={
              !isDirty ||
              someFieldsAreEmpty ||
              someFieldsHaveErrors ||
              isLoading
            }
            label="Create Account"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <p className={typography.c2}>
            Already have a Peak Admin account?{' '}
            <Link to={ROOT_PATH}>Sign in.</Link>
          </p>
        </form>
      </div>
    </main>
  );
};
