import { type Control } from 'react-hook-form';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import typography from '~styles/typography.scss';
import { type ListingFloorPlanFormData } from '../types';
import styles from './LabelsFieldSet.scss';

export interface LabelsFieldSetProps {
  control: Control<ListingFloorPlanFormData>;
}

export const LabelsFieldSet = ({ control }: LabelsFieldSetProps) => (
  <fieldset className={styles.fieldset} data-testid="labels-fieldset">
    <h3 className={typography.t3}>labels</h3>
    <ControlledFormInput
      control={control}
      type="text"
      label="Staff Name"
      name="name"
      placeholder="2 Top (Outside)"
      tooltipText="The internal name for the Listing. Seen only by Admins."
      rules={{
        required: true,
      }}
    />
    <ControlledFormInput
      control={control}
      type="text"
      label="Public Name"
      name="publicName"
      placeholder="VIP Booth (The Den)"
      rules={{
        maxLength: {
          message: 'Too many characters',
          value: 50,
        },
        required: true,
      }}
    />
  </fieldset>
);
