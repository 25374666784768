import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import {
  getWaitList,
  type WaitListEntry,
} from 'restaurantAdmin/reservations/service/sidePanel/waitList/apiHelpers';
import { useRestaurant } from '../../../../../context/useRestaurant';

export interface WaitListContextState {
  waitListEntries: WaitListEntry[];
  selectedWaitListEntry: WaitListEntry | undefined;
  fetchWaitListEntries: () => Promise<void>;
  setSelectedWaitListEntryIndex: (index: number) => void;
}

const WaitListContext = createContext<WaitListContextState>(
  {} as WaitListContextState,
);

export const useWaitListContext = (): WaitListContextState =>
  useContext(WaitListContext);

export const WaitListContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();

  const [waitListEntries, setWaitListEntries] = useState<WaitListEntry[]>([]);
  const [selectedWaitListEntryIndex, setSelectedWaitListEntryIndex] =
    useState<number>(-1);

  const fetchWaitListEntries = async (): Promise<void> => {
    const response = await getWaitList({ restaurantId });

    if (isSuccessResponse(response)) {
      setWaitListEntries(response);
    }
  };

  useEffect(() => {
    void fetchWaitListEntries();
  }, [restaurantId]);

  const selectedWaitListEntry = waitListEntries[selectedWaitListEntryIndex];

  const value = useMemo(
    () => ({
      waitListEntries,
      setSelectedWaitListEntryIndex,
      fetchWaitListEntries,
      selectedWaitListEntry,
    }),
    [waitListEntries, selectedWaitListEntryIndex],
  );

  return (
    <WaitListContext.Provider value={value}>
      {children}
    </WaitListContext.Provider>
  );
};
