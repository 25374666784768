import { minBy, sortBy } from 'lodash-es';
import { ISOTimeToMinuteOfDay } from '@utils/time';

interface ServiceWindow {
  name: string;
  startTime: string;
}

const MINUTES_PER_DAY = 1440;

const SERVICE_WINDOWS: ServiceWindow[] = [
  {
    name: 'Breakfast',
    startTime: '04:00:00',
  },
  {
    name: 'Lunch',
    startTime: '11:30:00',
  },
  {
    name: 'Dinner',
    startTime: '16:00:00',
  },
] as const;

export const groupTimesByServiceWindow = (
  times: string[],
): [string, string[]][] => {
  const map = mapGroupBy(times, serviceWindowForTime);
  const data = sortBy(
    Array.from(map),
    ([serviceWindow]) => serviceWindow.startTime,
  );
  return data.map(([serviceWindow, windowTimes]) => {
    const sortedTimes = sortBy(windowTimes, (time) =>
      wrappingDiffInMinutes(serviceWindow.startTime, time),
    );
    return [serviceWindow.name, sortedTimes];
  });
};

const serviceWindowForTime = (time: string): ServiceWindow =>
  minBy(SERVICE_WINDOWS, (cat) => wrappingDiffInMinutes(cat.startTime, time))!;

/**
 * @example
 * // returns 8 hours in minutes
 * wrappingDiffInMinutes('22:00:00', '06:00:00');
 */
const wrappingDiffInMinutes = (a: string, b: string): number =>
  mod(ISOTimeToMinuteOfDay(b) - ISOTimeToMinuteOfDay(a), MINUTES_PER_DAY);

// can eventually be replaced with Map.groupBy
const mapGroupBy = <T, K>(
  values: T[],
  getKey: (value: T) => K,
): Map<K, T[]> => {
  const map = new Map<K, T[]>();
  values.forEach((val) => {
    const key = getKey(val);
    let vals: T[];
    if (map.has(key)) {
      vals = map.get(key)!;
    } else {
      vals = [];
      map.set(key, vals);
    }
    vals.push(val);
  });
  return map;
};

// modulo operation
// https://stackoverflow.com/a/4467559
const mod = (a: number, n: number) => ((a % n) + n) % n;
