import { Input } from '@mui/base/Input';
import { Option } from '@mui/base/Option';
import { Select } from '@mui/base/Select';
import { type AnyIcon, Icon } from '@components/icon/Icon';
import { ICON_NAMES } from '@shared/types/floorPlans';
import styles from './FloorPlanEditor.scss';
import { useFloorPlanEditorContext } from './FloorPlanEditorContext';

export const SelectedFloorPlanTable = () => {
  const { selectedFloorPlanTable, handleOnChange, handleOnChangeIconName } =
    useFloorPlanEditorContext();

  return (
    <div className={styles.selectedFloorPlanTable}>
      <div>id</div>
      <div>{selectedFloorPlanTable?.id}</div>
      <label htmlFor="name-input">name</label>
      <Input
        autoComplete="off"
        disabled={!selectedFloorPlanTable}
        id="name-input"
        name="name"
        onChange={handleOnChange}
        value={selectedFloorPlanTable?.name || ''}
      />
      <label htmlFor="iconName-input">iconName</label>
      <Select
        aria-label="Icon Name"
        className={styles.iconName}
        disabled={!selectedFloorPlanTable}
        id="iconName-input"
        onChange={handleOnChangeIconName}
        slotProps={{
          listbox: {
            style: {
              maxHeight: '60vh',
              overflowY: 'scroll',
            },
          },
        }}
        value={selectedFloorPlanTable?.iconName || ''}
      >
        {ICON_NAMES.map((iconName) => (
          <Option key={iconName} className={styles.option} value={iconName}>
            <Icon name={iconName as AnyIcon} /> {iconName}
          </Option>
        ))}
      </Select>
      <label htmlFor="orientation-input">orientation</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="orientation-input"
        name="orientation"
        onChange={handleOnChange}
        value={selectedFloorPlanTable?.orientation || ''}
      />
      <label htmlFor="top-input">top</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="top-input"
        name="top"
        onChange={handleOnChange}
        value={selectedFloorPlanTable?.top || ''}
      />
      <label htmlFor="left-input">left</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="left-input"
        name="left"
        onChange={handleOnChange}
        value={selectedFloorPlanTable?.left || ''}
      />
      <label htmlFor="iconWidthScale-input">iconWidthScale</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="iconWidthScale-input"
        name="iconWidthScale"
        onChange={handleOnChange}
        value={selectedFloorPlanTable?.iconWidthScale || ''}
      />
      <label htmlFor="minimumGuests-input">Minimum guests</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="minimumGuests-input"
        name="minimumGuests"
        onChange={handleOnChange}
        type="number"
        value={selectedFloorPlanTable?.minimumGuests || ''}
      />
      <label htmlFor="maximumGuests-input">Maximum guests</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="maximumGuests-input"
        name="maximumGuests"
        onChange={handleOnChange}
        type="number"
        value={selectedFloorPlanTable?.maximumGuests || ''}
      />
      <label htmlFor="turnTime-input">Turn Time</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="turnTime-input"
        name="turnTime"
        onChange={handleOnChange}
        type="number"
        value={selectedFloorPlanTable?.turnTime || ''}
      />
      <label htmlFor="interval-input">Interval</label>
      <Input
        disabled={!selectedFloorPlanTable}
        id="interval-input"
        name="interval"
        onChange={handleOnChange}
        type="number"
        value={selectedFloorPlanTable?.interval || ''}
      />
    </div>
  );
};
