import { useForm } from 'react-hook-form';
import { parseTimeValues } from '@components/formInputs/timeValuesUtils';
import { type Listing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import styles from '../listingsPage/CreateEditListingPageLayout.scss';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';
import {
  type ListingFloorPlanFormData,
  type ListingReservableDaysFormData,
} from '../types';
import { listingReservableDaysFormDataFromListing } from '../utils/listingUtils';
import { EditListingAlertModalContainer } from './EditListingAlertModalContainer';
import { useEditListingContext } from './EditListingContext';
import { EditListingPageHeader } from './EditListingPageHeader';
import { EditListingReservableDaysPageBody } from './EditListingReservableDaysPageBody';

export const EditListingReservableDaysForm = ({
  existingListing,
}: {
  existingListing: Listing;
}) => {
  const { floorPlan, subView } = useListingsContext();
  const { processEditSubmission } = useEditListingContext();

  const generateHighlightedTableIds = () => {
    const highlightedTables = floorPlan?.floorPlanTables.filter(
      (floorPlanTable) =>
        floorPlanTable.listings.some(
          (floorPlanListing) => floorPlanListing.id === existingListing.id,
        ),
    );
    return highlightedTables?.map((table) => table.id) || [];
  };

  const { control: detailsControl } = useForm<ListingFloorPlanFormData>({
    defaultValues: {
      turnTime: existingListing.turnTime,
    },
  });

  const {
    control: reservableDaysControl,
    handleSubmit,
    getValues,
  } = useForm<ListingReservableDaysFormData>({
    defaultValues: listingReservableDaysFormDataFromListing(existingListing),
  });

  const processReservableDaysListingEdits = (): Listing => {
    const listingReservableDaysFormData = getValues();
    return {
      ...existingListing,
      ...listingReservableDaysFormData,
      endTime: parseTimeValues(listingReservableDaysFormData.endTime),
      price: parseFloat(listingReservableDaysFormData.price) * 100,
      startTime: parseTimeValues(listingReservableDaysFormData.startTime),
    };
  };

  const submit = (ignoreWarnings: boolean) => {
    void processEditSubmission(
      generateHighlightedTableIds(),
      ignoreWarnings,
      processReservableDaysListingEdits(),
    );
  };
  const handleOnSubmit = () => submit(false);
  const handleOnConfirmSubmit = () => submit(true);

  return (
    <>
      <form
        className={styles.pageWrapper}
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
      >
        <EditListingPageHeader
          cancelPath={listingsPagePath({
            scope:
              existingListing.status === 'draft'
                ? ListingsPageScope.Draft
                : ListingsPageScope.Published,
            subView,
          })}
        />
        <EditListingReservableDaysPageBody
          floorPlanControl={detailsControl}
          listingName={existingListing.name}
          reservableDaysControl={reservableDaysControl}
        />
      </form>
      <EditListingAlertModalContainer
        handleOnConfirmSubmit={handleOnConfirmSubmit}
      />
    </>
  );
};
