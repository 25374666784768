import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { CancellationPolicyNotice } from '@components/cancellationPolicyNotice/CancelationPolicyNotice';
import { CardElement } from '@components/cardElement/CardElement';
import { Error } from '@components/error/Error';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormPhoneInput } from '@components/formInputs/ControlledFormPhoneInput';
import { isWholeNumberRule } from '@components/formInputs/rules';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { centsToDollar, wholeDollarsToCents } from '@utils/currency';
import { BasicRadioButton } from 'restaurantAdmin/components/basicRadioButton/BasicRadioButton';
import { ControlledGuestAutocomplete } from 'restaurantAdmin/components/guestAutocomplete/ControlledGuestAutocomplete';
import typography from '~styles/typography.scss';
import styles from './ConciergeDrawer.scss';
import { ConciergeReceipt } from './ConciergeReceipt';
import { useAvailabilityDrawerContext } from './state/AvailabilityDrawerContext';
import { CheckoutTypes, useAdminCheckout } from './useAdminCheckout';

export const ConciergeDrawer = () => {
  const { closeDrawer, availabilityData, isOpen } =
    useAvailabilityDrawerContext();

  const { ref } = useHandleClickOutside(closeDrawer);
  const {
    addGuest,
    cancellationPolicy,
    control,
    customPriceInDollars,
    handleBookReservationClick,
    handleCardElementOnChange,
    handleCheckoutTypeOnChange,
    isBookButtonDisabled,
    isGuestFieldsDisabled,
    pricingInfo,
    removeGuest,
    responseError,
    selectedCheckoutType,
  } = useAdminCheckout();

  const containerClassNames = cx({
    [styles.container]: true,
    [styles.isOpen]: isOpen,
    [styles.isClosed]: !isOpen,
  });

  const drawerClassNames = cx({
    [styles.drawer]: true,
    [styles.drawerOpen]: isOpen,
    [styles.drawerClosed]: !isOpen,
  });

  if (!availabilityData) {
    // No availability selected
    return null;
  }
  const customPriceInCents = Number(customPriceInDollars) * 100;

  const showReceipt =
    pricingInfo &&
    (selectedCheckoutType === CheckoutTypes.FullPrice ||
      selectedCheckoutType === CheckoutTypes.CustomPrice);

  const showFullPriceOption = availabilityData.listingPrice > 0;

  const receiptPrice =
    selectedCheckoutType === CheckoutTypes.FullPrice
      ? availabilityData.listingPrice
      : wholeDollarsToCents(parseInt(customPriceInDollars, 10));

  const isCustomPriceSelected =
    selectedCheckoutType === CheckoutTypes.CustomPrice;

  return (
    <div
      aria-hidden={!isOpen}
      aria-labelledby="book_reservation_drawer_label"
      aria-modal="true"
      className={containerClassNames}
      role="dialog"
    >
      <div className={styles.curtain} />
      <div className={drawerClassNames} ref={ref}>
        <button
          aria-label="close"
          className={styles.closeButton}
          onClick={closeDrawer}
        >
          <Icon name="close" />
        </button>
        <h2 className={styles.drawerLabel} id="book_reservation_drawer_label">
          Book Reservation
        </h2>
        <p className={cx(typography.c2, styles.subtitle)}>
          Host-booked reservations are free of charge by default.
        </p>
        <form
          className={styles.drawerForm}
          onSubmit={handleBookReservationClick}
        >
          <div
            role="group"
            className={styles.checkoutType}
            aria-labelledby="checkout-legend"
          >
            <div id="checkout-legend">Checkout type</div>
            <div className={styles.radioGroup} role="radiogroup">
              <BasicRadioButton
                label="Free reservation"
                checked={selectedCheckoutType === CheckoutTypes.NoPrice}
                name="checkoutType"
                onChange={handleCheckoutTypeOnChange}
                value={CheckoutTypes.NoPrice}
              />
              {showFullPriceOption && (
                <BasicRadioButton
                  label="Full price"
                  checked={selectedCheckoutType === CheckoutTypes.FullPrice}
                  name="checkoutType"
                  onChange={handleCheckoutTypeOnChange}
                  value={CheckoutTypes.FullPrice}
                />
              )}
              <BasicRadioButton
                label="Custom price"
                checked={isCustomPriceSelected}
                name="checkoutType"
                onChange={handleCheckoutTypeOnChange}
                value={CheckoutTypes.CustomPrice}
              />
            </div>
          </div>
          {isCustomPriceSelected && (
            <>
              <ControlledFormCurrencyInput
                control={control}
                label="Custom Price"
                name="customPriceInDollars"
                rules={{
                  min: {
                    message: 'Price must be greater than 0',
                    value: 1,
                  },
                  validate: {
                    isWholeNumber: isWholeNumberRule('Price per table'),
                  },
                  required: true,
                }}
              />
              <div className={styles.originalPrice}>
                <div className={typography.label}>Original price</div>
                <div className={typography.label}>
                  {centsToDollar(availabilityData.listingPrice)}
                </div>
              </div>
            </>
          )}
          {showReceipt && (
            <>
              <ConciergeReceipt
                fee={pricingInfo.fee}
                price={receiptPrice}
                priceLabel={
                  isCustomPriceSelected ? 'Custom price' : 'Purchase price'
                }
                tax={pricingInfo.tax}
                total={pricingInfo.total}
              />
              {cancellationPolicy && (
                <CancellationPolicyNotice
                  availabilityTime={availabilityData.time}
                  cancellationFee={cancellationPolicy.fee}
                  date={availabilityData.date}
                  hoursBeforeReservation={
                    cancellationPolicy.hoursBeforeReservation
                  }
                  purchasePrice={
                    isCustomPriceSelected
                      ? customPriceInCents
                      : availabilityData.listingPrice
                  }
                  threshold={cancellationPolicy.threshold}
                />
              )}
              <CardElement onChange={handleCardElementOnChange} />
            </>
          )}
          <ControlledGuestAutocomplete
            control={control}
            name="guestId"
            onSelect={addGuest}
            onClear={removeGuest}
          />
          <div className={styles.guestFields}>
            <ControlledFormInput
              control={control}
              label="First Name"
              name="firstName"
              type="text"
              disabled={isGuestFieldsDisabled}
              rules={{
                required: true,
                validate: (name: string) =>
                  name.trim().length < 1 ? name : 'Must be a valid name',
              }}
            />
            <ControlledFormInput
              control={control}
              label="Last Name"
              name="lastName"
              disabled={isGuestFieldsDisabled}
              type="text"
              rules={{
                required: true,
                validate: (name: string) =>
                  name.trim().length < 1 ? name : 'Must be a valid name',
              }}
            />
          </div>
          <ControlledFormPhoneInput
            control={control}
            label="Guest Phone (optional)"
            disabled={isGuestFieldsDisabled}
            name="phone"
          />
          <Error className={styles.error} message={responseError} />
          <div className={styles.formActions}>
            <Button
              label="Cancel"
              onClick={closeDrawer}
              variant={ButtonVariants.Tertiary}
            />
            <Button
              isDisabled={isBookButtonDisabled}
              label="Book Reservation"
              type="submit"
              variant={ButtonVariants.Primary}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
