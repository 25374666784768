import { TZDateMini } from '@date-fns/tz';
import { addDays, subDays } from 'date-fns';
import { createAction } from '@shared/context/reducerUtils';
import type { OccupantsOccupant } from 'restaurantAdmin/reservations/occupants/apiHelpers';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';

enum OccupantActionType {
  ResetView = 'ResetView',
  SelectDate = 'SelectDate',
  SelectNextDay = 'SelectNextDay',
  SelectNextOccupant = 'SelectNextOccupant',
  SelectOccupant = 'SelectOccupant',
  SelectPreviousDay = 'SelectPreviousDay',
  SelectPreviousOccupant = 'SelectPreviousOccupant',
  SelectToday = 'SelectToday',
  UpdateGuestTagFilters = 'UpdateGuestTagFilters',
  UpdateOccupants = 'UpdateOccupants',
  UpdateRestaurantTags = 'UpdateRestaurantTags',
}

export const resetView = () => createAction(OccupantActionType.ResetView, null);
export const selectNextOccupant = () =>
  createAction(OccupantActionType.SelectNextOccupant, null);
export const selectPreviousOccupant = () =>
  createAction(OccupantActionType.SelectPreviousOccupant, null);
export const selectOccupant = (occupantId: string) =>
  createAction(OccupantActionType.SelectOccupant, { occupantId });
export const selectToday = (timezone: string) =>
  createAction(OccupantActionType.SelectToday, { timezone });
export const selectPreviousDay = () =>
  createAction(OccupantActionType.SelectPreviousDay, null);
export const selectNextDay = () =>
  createAction(OccupantActionType.SelectNextDay, null);
export const selectDate = (date: Date) =>
  createAction(OccupantActionType.SelectDate, { date });
export const updateOccupants = (occupants: OccupantsOccupant[]) =>
  createAction(OccupantActionType.UpdateOccupants, { occupants });
export const updateGuestTagFilters = (guestTagFilters: GuestTag[]) =>
  createAction(OccupantActionType.UpdateGuestTagFilters, { guestTagFilters });
export const updateRestaurantTags = (restaurantTags: GuestTag[]) =>
  createAction(OccupantActionType.UpdateRestaurantTags, {
    restaurantTags,
  });

export interface OccupantState {
  reservationDate: Date;
  selectedIndex: number;
  occupants: OccupantsOccupant[];
  restaurantTags: GuestTag[];
  guestTagFilters: GuestTag[];
}

export const occupantReducer = (
  state: OccupantState,
  action:
    | ReturnType<typeof resetView>
    | ReturnType<typeof selectDate>
    | ReturnType<typeof selectNextDay>
    | ReturnType<typeof selectNextOccupant>
    | ReturnType<typeof selectOccupant>
    | ReturnType<typeof selectPreviousDay>
    | ReturnType<typeof selectPreviousOccupant>
    | ReturnType<typeof selectToday>
    | ReturnType<typeof updateGuestTagFilters>
    | ReturnType<typeof updateOccupants>
    | ReturnType<typeof updateRestaurantTags>,
): OccupantState => {
  switch (action.type) {
    case OccupantActionType.ResetView:
      return {
        ...state,
        selectedIndex: -1,
      };
    case OccupantActionType.SelectNextOccupant: {
      const lastOccupant = state.occupants.length <= state.selectedIndex + 1;
      return lastOccupant
        ? state
        : {
            ...state,
            selectedIndex: state.selectedIndex + 1,
          };
    }
    case OccupantActionType.SelectPreviousOccupant: {
      const firstOccupant = state.selectedIndex === 0;
      return firstOccupant
        ? state
        : {
            ...state,
            selectedIndex: state.selectedIndex - 1,
          };
    }
    case OccupantActionType.SelectOccupant: {
      const indexOfOccupant = state.occupants.findIndex(
        (occupant) => occupant.id === action.payload.occupantId,
      );
      return {
        ...state,
        selectedIndex: indexOfOccupant,
      };
    }
    case OccupantActionType.SelectToday:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: TZDateMini.tz(action.payload.timezone),
      };
    case OccupantActionType.SelectPreviousDay:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: subDays(state.reservationDate, 1),
      };
    case OccupantActionType.SelectNextDay:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: addDays(state.reservationDate, 1),
      };
    case OccupantActionType.SelectDate:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: action.payload.date,
      };
    case OccupantActionType.UpdateOccupants:
      return {
        ...state,
        occupants: action.payload.occupants,
      };
    case OccupantActionType.UpdateGuestTagFilters:
      return {
        ...state,
        guestTagFilters: action.payload.guestTagFilters,
      };
    case OccupantActionType.UpdateRestaurantTags:
      return {
        ...state,
        restaurantTags: action.payload.restaurantTags,
      };
    default:
      return state;
  }
};
