// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingMenu__options___Hrduf{background-color:rgba(0,0,0,0);border:1px solid var(--white);font-size:.75rem;padding:.25rem 1rem;border-radius:6px}.ListingMenu__options___Hrduf:focus,.ListingMenu__options___Hrduf:hover{background-color:var(--white80);border-color:var(--white80);color:var(--black)}.ListingMenu__deleteListing___ktimD{color:var(--pink);padding-top:0}.ListingMenu__deleteListing___ktimD::before{content:\"\";background-color:var(--darkGrey200);border-radius:1rem;display:block;height:.065rem;margin-top:.5rem;margin-bottom:1rem;width:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/menu/ListingMenu.scss"],"names":[],"mappings":"AAAA,8BACE,8BAAA,CACA,6BAAA,CACA,gBAAA,CACA,mBAAA,CACA,iBAAA,CAEA,wEAEE,+BAAA,CACA,2BAAA,CACA,kBAAA,CAIJ,oCACE,iBAAA,CACA,aAAA,CAEA,4CACE,UAAA,CACA,mCAAA,CACA,kBAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": "ListingMenu__options___Hrduf",
	"deleteListing": "ListingMenu__deleteListing___ktimD"
};
export default ___CSS_LOADER_EXPORT___;
