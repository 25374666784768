import { useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from '../context/useRestaurant';
import type { GuestReservation } from './apiHelpers';
import { getReservationsForGuest } from './apiHelpers';
import { GuestReservationItem } from './GuestReservationItem';
import styles from './GuestReservations.scss';

interface GuestReservationsProps {
  guestId: string;
  handleGuestReservationClick: (reservation: GuestReservation) => void;
  selectedGuestReservationId?: string;
}

export const GuestReservations = ({
  guestId,
  handleGuestReservationClick,
  selectedGuestReservationId,
}: GuestReservationsProps) => {
  const { id: restaurantId } = useRestaurant();
  const [reservations, setReservations] = useState<GuestReservation[]>([]);

  useEffect(() => {
    const fetchReservations = async () => {
      const reservationsForGuest = await getReservationsForGuest(
        restaurantId,
        guestId,
      );

      if (reservationsForGuest && isSuccessResponse(reservationsForGuest)) {
        setReservations(reservationsForGuest);
      }
    };
    void fetchReservations();
  }, [guestId]);

  if (!reservations.length) {
    return null;
  }

  return (
    <ul aria-label="Reservations" className={styles.container}>
      {reservations.map((reservation) => (
        <GuestReservationItem
          handleGuestReservationClick={handleGuestReservationClick}
          key={reservation.id}
          reservation={reservation}
          selectedGuestReservationId={selectedGuestReservationId}
        />
      ))}
    </ul>
  );
};
