import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { eligibleForCancellationFee } from './apiHelpers';
import styles from './CancelButton.scss';
import { CancelConfirmationModal } from './CancelConfirmationModal';

interface CancelButtonProps {
  disableConfirmationModalPortal?: boolean;
  reservationId: string;
  reservationStatus?: string;
}

interface ModalState {
  isOpen: boolean;
  isEligibleForCancellationFeeUponCancellation: boolean;
  eligibleForCancellationFeeUntil: string;
  cancellationPolicy: {
    fee: number;
  } | null;
}
const DEFAULT_STATUS = 'active';
const DEFAULT_CLOSED_MODAL_STATE: ModalState = {
  isOpen: false,
  isEligibleForCancellationFeeUponCancellation: false,
  eligibleForCancellationFeeUntil: '',
  cancellationPolicy: null,
};

export const CancelButton = ({
  disableConfirmationModalPortal,
  reservationId,
  reservationStatus = DEFAULT_STATUS,
}: CancelButtonProps) => {
  const [modalState, setModalState] = useState<ModalState>(
    DEFAULT_CLOSED_MODAL_STATE,
  );
  const restaurant = useRestaurant();
  const {
    isOpen,
    isEligibleForCancellationFeeUponCancellation,
    eligibleForCancellationFeeUntil,
    cancellationPolicy,
  } = modalState;

  if (reservationStatus !== 'active') return null;

  const handleCancelClick = async () => {
    const response = await eligibleForCancellationFee(
      restaurant.id,
      reservationId,
    );
    if (isSuccessResponse(response)) {
      setModalState({
        isOpen: true,
        isEligibleForCancellationFeeUponCancellation:
          response.isEligibleForCancellationFeeUponCancellation,
        eligibleForCancellationFeeUntil:
          response.eligibleForCancellationFeeUntil,
        cancellationPolicy: response.cancellationPolicy,
      });
    }
  };

  const handleCloseModal = () => {
    setModalState(DEFAULT_CLOSED_MODAL_STATE);
  };

  return (
    <>
      <Button
        className={styles.button}
        onClick={handleCancelClick}
        label="Cancel"
        variant={ButtonVariants.Error}
      />
      <CancelConfirmationModal
        cancellationPolicy={cancellationPolicy}
        closeModal={handleCloseModal}
        disablePortal={disableConfirmationModalPortal}
        eligibleForCancellationFeeUntil={eligibleForCancellationFeeUntil}
        isEligibleForCancellationFeeUponCancellation={
          isEligibleForCancellationFeeUponCancellation
        }
        isOpen={isOpen}
        reservationId={reservationId}
      />
    </>
  );
};
