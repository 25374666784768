import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Icon } from '@components/icon/Icon';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import { getAvailableWalkInTime } from '../../apiHelpers';
import type { AvailableWalkInTime } from '../apiHelpers';
import styles from './WalkInSheetWarning.scss';

interface WalkInSheetWarningProps {
  floorPlanTableId: string;
}

export const WalkInSheetWarning = ({
  floorPlanTableId,
}: WalkInSheetWarningProps) => {
  const { id: restaurantId } = useRestaurant();
  const [availableTimeInMinutes, setAvailableTimeInMinutes] =
    useState<AvailableWalkInTime>(null);

  const fetchAvailableWalkInTime = async () => {
    const response = await getAvailableWalkInTime({
      floorPlanTableId,
      restaurantId,
    });

    if (isSuccessResponse(response)) {
      setAvailableTimeInMinutes(response.availableTime);
    }
  };

  useEffect(() => {
    void fetchAvailableWalkInTime();
  }, []);

  if (availableTimeInMinutes === null) return null;

  if (availableTimeInMinutes === 'NA') {
    return (
      <div className={cx(typography.t1, styles.upcomingReservationBanner)}>
        <p>
          <Icon className={styles.hourGlass} name="alertTriangle" /> Overlapping
          Table
        </p>
        <p>
          We could not show the upcoming timer for this table because it is in
          two or more overlapping listings. We advise not seating walk-in guests
          here to avoid overbooking your restaurant.
        </p>
      </div>
    );
  }

  return (
    <div className={cx(typography.t1, styles.upcomingReservationBanner)}>
      <p>
        <Icon className={styles.hourGlass} name="hourglass" /> Upcoming
      </p>
      <p>Reservation coming in {availableTimeInMinutes} minutes</p>
    </div>
  );
};
