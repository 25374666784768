import { tz } from '@date-fns/tz';
import cx from 'classnames';
import { addMinutes, format } from 'date-fns';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import {
  ModalType,
  useModalContext,
} from 'restaurantAdmin/context/ModalContext';
import typography from '~styles/typography.scss';
import { ContactInfo } from '../../../../components/contactInfo/ContactInfo';
import { useRestaurant } from '../../../../context/useRestaurant';
import type { WaitListEntry } from './apiHelpers';
import { sendTableReadyMessage } from './apiHelpers';
import { useWaitListContext } from './state/WaitListContext';
import styles from './WaitListSheet.scss';
import { WaitListTimer } from './WaitListTimer';

interface WaitListSheetProps {
  className?: string;
  onCanceled: () => void;
  onClose: () => void;
  onEnableSeatMode: () => void;
  waitListEntry: WaitListEntry;
  seatModeEnabled: boolean;
}

const ONE_SECOND_IN_MS = 1000;

export const WaitListSheet = ({
  className,
  onCanceled,
  onClose,
  onEnableSeatMode,
  waitListEntry,
  seatModeEnabled,
}: WaitListSheetProps) => {
  const { timezone } = useRestaurant();

  const waitEndDate = addMinutes(
    new Date(waitListEntry.createdAt),
    waitListEntry.estimatedWait,
  );
  const waitEndDisplay = format(waitEndDate, 'p', { in: tz(timezone) });

  const hasMoreThanOneSendTableReadyMessageEvent =
    waitListEntry.events.filter(
      (event) => event.type === 'sendTableReadyMessage',
    ).length > 1;
  const { fetchWaitListEntries } = useWaitListContext();
  const { openModal } = useModalContext();

  const handleClickSendTableReadyMessage = useCallback(
    debounce(async () => {
      const response = await sendTableReadyMessage(
        waitListEntry.restaurantId,
        waitListEntry.id,
      );

      if (isErrorResponse(response)) {
        errorToast({
          message: response.message,
        });
      } else {
        await fetchWaitListEntries();
        successToast({
          message: 'SMS message sent',
        });
      }
    }, ONE_SECOND_IN_MS),
    [waitListEntry],
  );

  const onClickRemoveFromWaitList = () => {
    openModal(ModalType.RemoveFromWaitListConfirmation, {
      onCanceled: async () => {
        await fetchWaitListEntries();
        onCanceled();
      },
      waitListEntry,
    });
  };

  return (
    <div
      aria-label="Wait List Sheet"
      className={cx(styles.container, className)}
    >
      <section className={styles.controls}>
        <div />
        <IconButton
          ariaLabel="close sheet"
          onClick={onClose}
          iconName="close"
        />
      </section>
      <section className={styles.essentialInfo}>
        <div>
          <WaitListTimer waitListEntry={waitListEntry} />
          <p className={cx(typography.h5, styles.name)}>
            {waitListEntry.firstName} {waitListEntry.lastName}
          </p>
        </div>
        <div>
          <p className={cx(typography.h6, styles.waitTime)}>
            Estimate - {waitEndDisplay}
          </p>
          <p className={cx(typography.h7, styles.guestCount)}>
            {appendGuestCountLabel(waitListEntry.guestCount)}
          </p>
        </div>
      </section>
      {!seatModeEnabled && (
        <Button
          label="Seat Guest"
          variant={ButtonVariants.Primary}
          onClick={onEnableSeatMode}
        />
      )}
      {!seatModeEnabled && (
        <Button
          isDisabled={hasMoreThanOneSendTableReadyMessageEvent}
          label="Send Table Ready Message"
          variant={ButtonVariants.Secondary}
          onClick={handleClickSendTableReadyMessage}
        />
      )}
      <ContactInfo phone={waitListEntry.phone} />
      {!seatModeEnabled && (
        <Button
          className={typography.h7}
          label="Remove From Waitlist"
          variant={ButtonVariants.Error}
          onClick={onClickRemoveFromWaitList}
        />
      )}
    </div>
  );
};
