/* eslint-disable import/order */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { EditPricePointsPayload, Listing } from '../apiHelpers';
import { editListing, getListing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';

interface ModalState {
  currentModal: 'error' | 'warning' | null;
  isSavingDraft?: boolean;
  message?: string;
}

export interface EditListingContextState {
  clearModalState: () => void;
  existingListing: Listing | null;
  isListingLoading: boolean;
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
  processEditSubmission: (
    highlightedFloorPlanTableIds: string[],
    ignoreWarnings: boolean,
    updatedListing: Listing,
    pricePointsPayload?: EditPricePointsPayload,
  ) => Promise<void>;
}

export const EditListingContext = createContext<EditListingContextState>(
  {} as EditListingContextState,
);
EditListingContext.displayName = 'EditListingContext';

export const useEditListingContext = (): EditListingContextState =>
  useContext(EditListingContext);

export const EditListingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();
  const { refreshFloorPlan, refreshListings, setSelectedListingId, subView } =
    useListingsContext();
  const [isListingLoading, setIsListingLoading] = useState(true);
  const [existingListing, setExistingListing] = useState<Listing | null>(null);
  const { listingId } = useParams();
  const [modalState, setModalState] = useState<ModalState>({} as ModalState);
  const navigate = useNavigate();
  if (!listingId) {
    throw new Error('Listing id not provided-context');
  }

  useAbortEffect(
    async (signal) => {
      setIsListingLoading(true);
      const response = await getListing(listingId, restaurantId, signal);
      setIsListingLoading(false);
      if (isSuccessResponse(response)) {
        setExistingListing(response);
      }
    },
    [restaurantId, listingId],
  );

  const clearModalState = () => {
    setModalState({ currentModal: null });
  };

  const processEditSubmission = async (
    highlightedFloorPlanTableIds: string[],
    ignoreWarnings: boolean,
    updatedListing: Listing,
    pricePointsPayload?: EditPricePointsPayload,
  ): Promise<void> => {
    const response = await editListing(
      updatedListing.restaurantId,
      updatedListing.id,
      {
        endDate: updatedListing.endDate || undefined,
        endTime: updatedListing.endTime,
        highlightedFloorPlanTableIds,
        iconName: updatedListing.iconName,
        ignoreWarnings,
        interval: updatedListing.interval,
        inventoryCount: Number(updatedListing.inventoryCount),
        maximumGuests: Number(updatedListing.maximumGuests),
        minimumGuests: Number(updatedListing.minimumGuests),
        name: updatedListing.name,
        price: updatedListing.price,
        pricePoints: pricePointsPayload,
        publicName: updatedListing.publicName,
        repeat: updatedListing.repeat,
        startDate: updatedListing.startDate,
        startTime: updatedListing.startTime,
      },
    );

    const isDraft = updatedListing.status === 'draft';

    if (isSuccessResponse(response)) {
      if (response.warnings.length > 0) {
        setModalState({
          currentModal: 'warning',
          isSavingDraft: isDraft,
          message: response.warnings[0],
        });
      } else {
        refreshListings();
        refreshFloorPlan();
        successToast({
          message: 'Listing successfully updated',
        });
        setSelectedListingId(updatedListing.id);
        navigate(
          listingsPagePath({
            scope: isDraft
              ? ListingsPageScope.Draft
              : ListingsPageScope.Published,
            subView,
          }),
        );
      }
    } else {
      setModalState({
        currentModal: 'error',
        isSavingDraft: isDraft,
        message: response.message,
      });
    }
  };

  const value = useMemo<EditListingContextState>(
    () => ({
      clearModalState,
      existingListing,
      isListingLoading,
      listingId,
      modalState,
      processEditSubmission,
      setModalState,
    }),
    [modalState, listingId, isListingLoading],
  );

  return (
    <EditListingContext.Provider value={value}>
      {children}
    </EditListingContext.Provider>
  );
};
