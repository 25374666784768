import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { todayInTimezone } from '@shared/utils/date';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlan } from '../../hooks/useAdminFloorPlan';
import type { Listing } from './apiHelpers';
import {
  defaultListingsPageParams,
  type ListingsPageParams,
  ListingsPageScope,
  ListingsPageSubView,
} from './listingsPage/listingsPagePath';
import { useListings } from './useListings';
import { getListingsForWeekByDate } from './utils/listingUtils';

export interface ListingsContextState {
  scope: ListingsPageScope;
  subView: ListingsPageSubView;

  clearSelectedListing: () => void;
  floorPlan: FloorPlanData;
  isLoading: boolean;
  /** All listings in the selected scope (Published, Draft, Inactive) */
  scopedListings: Listing[];
  /** Listings in scope for the entire page - filtered by date/time selectors */
  pageListings: Listing[];
  /** Listings in scope for the list view - filtered by selection on the calendar or the floor plan */
  listListings: Listing[];
  refreshFloorPlan: () => void;
  refreshListings: () => void;
  selectedDate: string;
  selectedFloorPlanTableListingIds: string[];
  selectedListing: Listing | undefined;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanTableListingIds: Dispatch<SetStateAction<string[]>>;
  setSelectedListingId: (listingId: string) => void;
  selectedCalendarCellListingIds: string[];
  setSelectedCalendarCellListingIds: Dispatch<SetStateAction<string[]>>;
}

export const ListingsContext = createContext<ListingsContextState | null>(null);
ListingsContext.displayName = 'ListingsContext';

export const useListingsContext = (): ListingsContextState =>
  useDefinedContext(ListingsContext);

export const ListingsContextProvider = ({
  children,
  scope: scopeParam,
  subView: subViewParam,
}: PropsWithChildren<Partial<ListingsPageParams>>) => {
  const { timezone } = useRestaurant();
  const { scope, subView } = useListingsPageParams({
    scope: scopeParam,
    subView: subViewParam,
  });

  const {
    isLoading: isListingsLoading,
    listings: fetchedListings,
    refreshListings,
  } = useListings(scope === ListingsPageScope.Draft);

  const scopedListings = useMemo(() => {
    const today = todayInTimezone(timezone);
    switch (scope) {
      case ListingsPageScope.Draft:
        return fetchedListings;
      case ListingsPageScope.Inactive:
        return fetchedListings.filter(
          (l) => l.endDate != null && l.endDate < today,
        );
      case ListingsPageScope.Published:
        return fetchedListings.filter(
          (l) => l.endDate == null || l.endDate >= today,
        );
      default:
        throw new Error(`unexpected scope: ${scope satisfies never as string}`);
    }
  }, [fetchedListings, scope]);

  const {
    isLoading: isFloorPlanLoading,
    floorPlan,
    fetchFloorPlan,
  } = useAdminFloorPlan(false);

  const [selectedDate, setSelectedDate] = useState(() =>
    todayInTimezone(timezone),
  );
  const [
    selectedFloorPlanTableListingIds,
    setSelectedFloorPlanTableListingIds,
  ] = useState<string[]>([]);
  const [selectedCalendarCellListingIds, setSelectedCalendarCellListingIds] =
    useState<string[]>([]);
  const [selectedListingId, setSelectedListingId] = useState<string>();

  const selectedListing = scopedListings.find(
    (listing) => listing.id === selectedListingId,
  );
  const clearSelectedListing = () => setSelectedListingId(undefined);

  const pageListings =
    subView === ListingsPageSubView.FloorPlan
      ? scopedListings
      : getListingsForWeekByDate(scopedListings, selectedDate);

  const getListListings = () => {
    if (subView === ListingsPageSubView.FloorPlan) {
      if (selectedFloorPlanTableListingIds.length) {
        return pageListings.filter((listing) =>
          selectedFloorPlanTableListingIds.includes(listing.id),
        );
      }
    } else if (selectedCalendarCellListingIds.length) {
      return pageListings.filter((listing) =>
        selectedCalendarCellListingIds.includes(listing.id),
      );
    }
    return pageListings;
  };
  const listListings = getListListings();

  const handleSetSelectedDate = (date: string) => {
    setSelectedFloorPlanTableListingIds([]);
    setSelectedDate(date);
  };

  const value = useMemo(
    (): ListingsContextState => ({
      scope,
      subView,
      clearSelectedListing,
      floorPlan: floorPlan!,
      isLoading: isListingsLoading || isFloorPlanLoading,
      scopedListings,
      pageListings,
      listListings,
      refreshFloorPlan: fetchFloorPlan,
      refreshListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedListing,
      setSelectedDate: handleSetSelectedDate,
      setSelectedFloorPlanTableListingIds,
      setSelectedListingId,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    }),
    [
      scope,
      subView,
      floorPlan,
      isFloorPlanLoading,
      isListingsLoading,
      scopedListings,
      pageListings,
      listListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedListing,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    ],
  );

  return (
    <ListingsContext.Provider value={value}>
      {children}
    </ListingsContext.Provider>
  );
};

/**
 * When params are not provided as arguments (are undefined), this will remember
 * and return previously set values. This is useful for remembering the params
 * when the user navigates to another listings page like /create.
 */
const useListingsPageParams = ({
  scope: scopeParam,
  subView: subViewParam,
}: Partial<ListingsPageParams>): ListingsPageParams => {
  const ref = useRef<ListingsPageParams>();
  ref.current ??= { ...defaultListingsPageParams };
  const params = ref.current;
  if (scopeParam) params.scope = scopeParam;
  if (subViewParam) params.subView = subViewParam;
  return params;
};
