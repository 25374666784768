import cx from 'classnames';
import { type Control } from 'react-hook-form';
import { Card } from '@components/card/Card';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import { DisplayIconFieldSet } from '../form/DisplayIconFieldSet';
import { FloorPlanFieldset } from '../form/FloorPlanFieldset';
import { LabelsFieldSet } from '../form/LabelsFieldSet';
import { SeatingTypeFieldSet } from '../form/SeatingTypeFieldSet';
import pageLayoutStyles from '../listingsPage/CreateEditListingPageLayout.scss';
import { type ListingFloorPlanFormData } from '../types';
import styles from './EditListingFloorPlanPageBody.scss';

interface EditListingFloorPlanPageBodyProps {
  control: Control<ListingFloorPlanFormData>;
  isEditing: boolean;
  floorPlan: FloorPlanData;
}

export const EditListingFloorPlanPageBody = ({
  control,
  isEditing,
  floorPlan,
}: EditListingFloorPlanPageBodyProps) => (
  <PageContent className={pageLayoutStyles.main}>
    <Card className={cx(pageLayoutStyles.displayPanel, styles.displayPanel)}>
      <FloorPlanFieldset control={control} floorPlan={floorPlan} />
    </Card>
    <div className={pageLayoutStyles.sidePanelContainer}>
      <Card bordered className={pageLayoutStyles.sidePanel}>
        <LabelsFieldSet control={control} />
        <SeatingTypeFieldSet control={control} isDisabledForEdit={isEditing} />
        <DisplayIconFieldSet control={control} />
      </Card>
    </div>
  </PageContent>
);
